import React from 'react';
import '../css/Spinner.scss';
import { Typography } from '@mui/material';

const SpinnerFull = (props) => (
    <div className='full'>
        <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <Typography component="h3">{props.message}</Typography>
    </div>
);

export default SpinnerFull;