import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form as FormFormik } from "formik";
import * as Yup from "yup";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import FundSearchFields from "./FundSearchFields";
import { FundsList } from "./FundsList";
import { Typography } from "@mui/material";

/**
 *  <p>Container where options and results from each Tab are shown</p>
 * @param {Object} props
 * @param {string} props.idName - component name defined in the Modal component
 * @param {Function} props.handleSubmit - generates a request to search for companies based on form values
 * @param {Array} props.hierarchies - list of names of hierarchies to which a fund can belong
 * @param {Array} props.types - list of types to which a fund can belong
 * @param {Object} props.currentTypeSearched - useRef variable to store the fund type currently selected
 * @param {Object} props.innerRef - useRef variable to get form current values
 * @param {Array} props.fundsCopy - the resultant funds of the search
 * @param {Function} props.handleCheck - function to display the selected fund as checked
 * @param {Boolean} props.editView - indicates whether the modal was shown on EditView
 * @param {Boolean} props.statsView - indicates whether the modal was shown on StatsView
 * @param {Boolean} props.notFoundFundsMessage - indicates if a not found message should be rendered
 * @param {Function} props.setNotFoundFundsMessage - function to determine whether or not to display a not found message
 * @param {Array} props.selectedFunds - list storing the selected funds
 * @param {string} props.keyWord - component key name given by the set of Tabs
 * @returns rendered view of the component
 * @author milenexeleva
 */
const TabResultListFunds = ({
  idName,
  handleSubmit,
  hierarchies,
  types,
  currentTypeSearched,
  innerRef,
  fundsCopy,
  handleCheck,
  editView,
  statsView,
  notFoundFundsMessage,
  setNotFoundFundsMessage,
  selectedFunds,
  keyWord,
}) => {
  const initialValues = innerRef.current?.initialValues
    ? innerRef.current?.initialValues
    : {
        name: "",
        hierarchy: "",
        type: "",
        country: "",
      };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        innerRef={innerRef}
        enableReinitialize={true}
        validationSchema={
          keyWord === "searchName"
            ? Yup.object({
                name: Yup.string().required("Required field"),
              })
            : Yup.object({})
        }
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
          handleSubmit(values);
        }}
      >
        {({ resetForm, dirty, isSubmitting, values }) => (
          <FormFormik id="namesForm">
            <FundSearchFields
              {...{
                isSubmitting,
                dirty,
                resetForm,
                keyWord,
                hierarchies,
                types,
                setNotFoundFundsMessage,
                currentTypeSearched,
                statsView,
              }}
            />
          </FormFormik>
        )}
      </Formik>
      {keyWord === idName &&
        (() => {
          switch (currentTypeSearched.current) {
            case "cik":
              return (
                <FundsList
                  funds={fundsCopy}
                  handleCheck={handleCheck}
                  selectedFunds={selectedFunds}
                  editView={editView}
                  typeFund={"cik"}
                />
              );
            case "plan":
              return (
                <FundsList
                  funds={fundsCopy}
                  handleCheck={handleCheck}
                  selectedFunds={selectedFunds}
                  editView={editView}
                  typeFund={"plan"}
                />
              );
            case "not selected":
              return (
                <FundsList
                  funds={fundsCopy}
                  handleCheck={handleCheck}
                  selectedFunds={selectedFunds}
                  editView={editView}
                  typeFund={"not selected"}
                />
              );
            default:
              return (
                <FundsList
                  funds={fundsCopy}
                  handleCheck={handleCheck}
                  selectedFunds={selectedFunds}
                  editView={editView}
                  typeFund={"manager"}
                  statsView={statsView}
                />
              );
          }
        })()}
      {notFoundFundsMessage && (
        <div className="modal-not-companies-found-container d-flex d-flex flex-column justify-content-center align-items-center">
          <FontAwesomeIcon
            icon={faFolderOpen}
            className="icon-not-companies-found"
          />
          <Typography variant="body1" component="p" sx={{
            fontSize: "20px",
            marginTop: "5px"}}>
            No investors were found using the selected criteria 
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TabResultListFunds;
