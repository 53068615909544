import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button } from "@mui/material";
import { getFundsBy, getFundsbyId, getFoundLogs } from "../utils/reqs";
import * as Yup from "yup";
import LogsForm from "./Fund/LogsForm";
import SearchForm from "./Fund/SearchForm";
import Side from "./Layout/Side";
import { MainContent } from "./Layout/MainContent";
import SideForm from "./Layout/SideForm";
import Page from "./Layout/Page";
import FundsTable from "./Fund/FundsTable";

/**
 * View component for funds edition. It gets grouped funds, classifies them by hierarchy,
 * and displays them on a data table. Sidebar has fund searcher form, an input for each
 * field. In handleSubmit, it takes the sidebar form values and sends them as parameters
 * to api function getFundsBy.
 * @see main req[1]-[1]
 * @author sebasRM
 */
const EditFund = () => {
  const [showLogsTable, setShowLogsTable] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [buttonMessage, setButtonMessage] = useState("Switch to logs view");

  const [rowsFunds, setRowsFunds] = useState([]);
  const [rowCountFunds, setRowCountFunds] = useState(0);
  const [rowCountLogs, setRowCountLogs] = useState(0);
  const [rowsLogs, setRowsLogs] = useState([]);
  const formikFundLogsRef = useRef();
  const formikMainSearch = useRef();
  const resultsRef = useRef(null);

  const searchFundsBy = useRef(null); // tell us if the search was made for modal or by lateral formik
  const allModalFundsFound = useRef([]);

  const initValues = {
    _id: "",
    name: "",
    country: "",
    acronym: "",
    type: "",
    hierarchy: "",
  };

  const initValuesLogs = {
    operation: "",
    modifiedBy: "",
    modifiedDateStart: "",
    modifiedDateEnd: "",
    name: "",
    isPrev: "",
  };

  const yupSchema = Yup.object({
    name: Yup.string()
      .required("Required")
      .max(100, "Must be 100 characters or less"),
  });

  useEffect(() => {
    if (window.screen.width < 768) {
      showLogsTable
        ? setButtonMessage("Go back")
        : setButtonMessage("Logs View");
    } else {
      showLogsTable
        ? setButtonMessage("Go back to investor editor")
        : setButtonMessage("Switch to Logs View ");
    }
  }, [showLogsTable]);

  /**
   * <p>Get the list of funds selected in the modal by their id</p>
   * @param {Object} selecteds - list of funds model mongoose
   * @returns the list of funds found
   * @author  Medina192
   */
  const searchFundsById = (selecteds = []) => {
    const arrayOfIds = [];
    for (const fund of selecteds) {
      arrayOfIds.push(fund.id);
    }

    handleLoadingTable(true);
    getFundsbyId(arrayOfIds)
      .then((res) => {
        allModalFundsFound.current = res.funds;
        searchFundsBy.current = "modal";
        setRowsFunds(res.funds);
        setRowCountFunds(res.funds.length);
      })
      .catch((error) => {
        console.log("error promise all", error);
      })
      .finally(() => handleLoadingTable(false));
  };

  /**
   * Get the funds given the parameters of the search form
   * @param {Object} values - object with data from the search form
   * @param {string} [values.fundname] - Fund's name
   * @param {string} [values.acronym] - Fund's acronym
   * @param {string} [values.type] - Fund's type (asset owner or asset manager)
   * @param {string} [values.hierarchy] - Fund's hierarchy level (parent fund, fund family, fund)
   * @param {string} [values.country] - Fund's country
   * @author ValeriaG
   */
  const getFunds = async (values, page = 1) => {
    searchFundsBy.current = "lateralFormik";
    setLoadingTable(true);
    formikMainSearch.current = values;
    const { funds, totalDocs } = await getFundsBy(values, 100, page);
    await setRowsFunds(funds);
    await setRowCountFunds(totalDocs);
    setLoadingTable(false);
  };

  const getFundLogs = async (filters, page = 1) => {
    filters.modifiedStart = document.getElementById(
      "input-modified-from-fundLogs"
    ).value;
    filters.modifiedEnd = document.getElementById("input-to-fundLogs").value;
    formikFundLogsRef.current = filters;

    filters.page = page;
    if (!filters.limit) filters.limit = 100;
    setLoadingTable(true);
    getFoundLogs(filters)
      .then((res) => {
        const { fundLogs, totalDocs } = res.data;
        setRowsLogs(fundLogs);
        setRowCountLogs(totalDocs);
      })
      .catch((error) => {
        const { response } = error;
        alert(!response?.data ? error.message : response.data.message);
      })
      .finally((_) => setLoadingTable(false));
  };

  const handleLoadingTable = useCallback((val) => {
    setLoadingTable(val);
  }, []);

  const handleshowLogsTable = () => {
    setShowLogsTable(!showLogsTable);
  };

  const extraProps = {
    searchFundsById,
  };

  const formsProps = {
    extraProps,
    searchFunction: showLogsTable ? getFundLogs : getFunds,
    searchForm: SearchForm,
    logsForm: LogsForm,
    yupSchema,
    showLogsTable,
    initValues,
    initValuesLogs,
  };

  function handleMerge() {
    resultsRef.current.openMergeModal();
  }

  return (
    <Page>
      <Side>
        <SideForm {...formsProps}></SideForm>
      </Side>
      <MainContent
        title={showLogsTable ? "Investor Editor Logs" : "Investor Editor"}
        colorTitle={"red.dark"}
      >
        <MainContent.Actions>
        {!showLogsTable && <Button onClick={handleMerge}>Merge Investors</Button>}
          <Button onClick={handleshowLogsTable}>
            {buttonMessage}
          </Button>
        </MainContent.Actions>
        <MainContent.Body>
          <FundsTable ref={resultsRef}
            {...{
              rows: showLogsTable ? rowsLogs : rowsFunds,
              setRows: showLogsTable ? setRowsLogs : setRowsFunds,
              rowsCount: showLogsTable ? rowCountLogs : rowCountFunds,
              setRowsCount: showLogsTable ? setRowCountLogs : setRowCountFunds,
              showLogsTable,
              setShowLogsTable,
              loadingTable,
              buttonMessage,
              setLoadingTable,
              searchFunction: showLogsTable ? getFundLogs : getFunds,
              formikMainSearch: showLogsTable
                ? formikFundLogsRef
                : formikMainSearch,
              searchFundsBy,
            }}
          />
        </MainContent.Body>
      </MainContent>
    </Page>
  );
};

export default EditFund;
