import { Children, useState, cloneElement, useEffect } from "react";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";

const drawerWidth = 300;
const drawerWidthMobile = "100vw";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    padding: "0",
    width: "100%",
    margin: "0",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  height: "70px",
}));

/**
 * Template for the content after the top navigation.
 * @param {Object} props
 * @param {node} props.children - Children elements to be rendered on the main page.
 * @param {boolean} props.single - If the page is a single page or not.
 * @returns the rendered component
 * @author valeriaxeleva
 */
const HomePage = ({ children, single }) => {
  single = true;
  const isMobile = useMediaQuery("(max-width:912px)");
  const [open, setOpen] = useState(true);
  let mainIndex = single ? 0 : 1;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setOpen(isMobile ? false : true);
  }, [isMobile]);

  return (
    <Box className="grid-container-home">
      <CssBaseline />
      {!single && (
        <Drawer
          sx={{
            width: { xs: drawerWidthMobile, md: drawerWidth },
            position: "relative",
            // overflow: "hidden",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: { xs: drawerWidthMobile, md: drawerWidth },
              boxSizing: "border-box",
            },
          }}
          variant={isMobile ? "temporary" : "persistent"}
          anchor="left"
          open={open}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerHeader>OxProx</DrawerHeader>
          <Divider />

          {Children.toArray(children)[0]}
        </Drawer>
      )}
      <Main open={open}>
        {cloneElement(Children.toArray(children)[mainIndex], {
          open: open,
          handleDrawerOpen: handleDrawerOpen,
        })}
      </Main>
    </Box>
  );
};

export default HomePage;
