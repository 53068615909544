import React, { useEffect, useState } from "react";
import "./css/Stats.scss";
import { getESGFundStats, getESGInfo } from "../utils/reqs.js";
import { a11yProps } from "../utils/utils.js";
import { MainContent } from "./Layout/MainContent";
import { Side } from "./Layout/Side";
import { Page } from "./Layout/Page";
import SearchFundsForm from "./Statistics/SearchFundsForm";
import ESGgraph from "./Statistics/ESGgraph";
import VotesView from "./Statistics/VotesView";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "./utils/TabPanel";
import Box from "@mui/material/Box";
import SpinnerFull from "./utils/SpinnerFull";
import { useLocation, useHistory } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Paper from "@mui/material/Paper";


/**
 * Component to handle the navigation tabs of the Statistics view.
 * @param {Object} props
 * @param {Number} props.activeGraph - the index of the active tab
 * @param {Function} props.handleChangeView - function to change the active tab
 * @param {Array} props.allfunds - array of all the funds selected by the user
 * @returns rendered component
 * @author valeriaxeleva
 */
const NaviTabs = ({ activeGraph, handleChangeView, allfunds, user }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        sx={{ width: "100%" }}
        value={activeGraph}
        onChange={handleChangeView}
        aria-label="basic tabs example"
      >
        <Tab label="ESG Graph" {...a11yProps(0, "tab-header")} />
        {
          user && 
          <Tab
          label="Company Graph"
          {...a11yProps(1, "tab-header")}
          {
            ...{
              /*disabled={allfunds.length === 0}*/
            }
          }
        />
        }

        {/*
        <Tab
          label="Keywords Graph"
          {...a11yProps(2, "tab-header")}
          {
            ...{
            }
          }
        />*/}
      </Tabs>
    </Box>
  );
};

/**
 * Component to render the interface of the Statistics view. This component is the main container of the view.
 * @returns the rendered component
 * @author valeriaxeleva
 */
const StatsView = () => {
  const [allfunds, setAllFunds] = useState([]);
  const [ESGstats, setESGstats] = useState([]);
  const [gLoading, setGLoading] = useState(false);
  const [graphLines, setGraphLines] = useState([]);
  const [activeGraph, setActiveGraph] = useState(0);
  const [currentSubject, setCurrentSubject] = useState("ESG");
  const [csvVotesParams, setCsvVotesParams] = useState({});
  const [totalVotesFund, setTotalVotesFund] = useState([]);
  const [loadingChart, setLoadingChart] = useState(false);
  const [voteProportionAllLoading, setVoteProportionAllLoading] =
    useState(false);
  const [proposalsVoteAll, setProposalsVoteAll] = useState([]);
  const [formOptions, setFormOptions] = useState({
    checkedSH: false,
    checkedFM: false,
    startDate: null,
    endDate: null,
  });

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const searchTerm = searchParams.get("search");
  const history = useHistory();
  const location = useLocation();

  const [user] = useAuthState(auth);

  useEffect(() => {
    const execRequest = async () => {
      const subjectInfo = await getESGInfo(searchTerm[searchTerm.length - 1]);
      if (subjectInfo && allfunds?.length === 0) {
        setCurrentSubject(searchTerm[searchTerm.length - 1]);
        setAllFunds((f) => [...subjectInfo.topInvestors]);
        getESGforAll({ funds: subjectInfo.topInvestors });
      }
    };
    if (searchTerm) {
      const allowedTerms = ["investors-E", "investors-S", "investors-G"];
      if (allowedTerms.includes(searchTerm)) {
        execRequest();
      } else if (searchTerm !== "") {
        history.push("/notfound");
      }
    }
  }, [searchTerm]);

  const formatResultsFromData = (elem, index) => {
    let forTotalE =
      elem.E_totals.forTotalE === undefined
        ? Math.round(
            elem.E_totals.totalVotes * (elem.E_totals.forPercentage / 100)
          )
        : elem.E_totals.forTotalE;
    let forTotalS =
      elem.S_totals.forTotalS === undefined
        ? Math.round(
            elem.S_totals.totalVotes * (elem.S_totals.forPercentage / 100)
          )
        : elem.S_totals.forTotalS;
    let forTotalG =
      elem.G_totals.forTotalG === undefined
        ? Math.round(
            elem.G_totals.totalVotes * (elem.G_totals.forPercentage / 100)
          )
        : elem.G_totals.forTotalG;

    let aux = {
      year: elem.year,
      [`E_totals_${index}`]: elem.E_totals.totalVotes,
      [`E_percentage_${index}`]: elem.E_totals.forPercentage,
      [`forTotalE_${index}`]: forTotalE,
      [`G_totals_${index}`]: elem.G_totals.totalVotes,
      [`G_percentage_${index}`]: elem.G_totals.forPercentage,
      [`forTotalG_${index}`]: forTotalG,
      [`S_totals_${index}`]: elem.S_totals.totalVotes,
      [`S_percentage_${index}`]: elem.S_totals.forPercentage,
      [`forTotalS_${index}`]: forTotalS,
      [`ESG_totals_${index}`]:
        elem.E_totals.totalVotes +
        elem.S_totals.totalVotes +
        elem.G_totals.totalVotes,
      [`forTotalESG_${index}`]: forTotalE + forTotalS + forTotalG,
    };

    let result =
      aux[`ESG_totals_${index}`] !== 0
        ? parseFloat(
            (
              (aux[`forTotalESG_${index}`] * 100) /
              aux[`ESG_totals_${index}`]
            ).toFixed(2)
          )
        : 0;
    aux[`ESG_percentage_${index}`] = result;
    return aux;
  };

  const getESGforAll = (values) => {
    const { funds, startDate, endDate } = values;
    setGLoading(true);
    setActiveGraph(0);
    const promises = funds.map((fund) => {
      const body = { fund: fund.id };
      return getESGFundStats(body);
    });
    Promise.all(promises)
      .then((results) => {
        let extraOG = [];
        let extraSH = [];
        let extraFM = [];
        let extraFMSH = [];
        const allstats = results.map((result, i) => {
          const stats = result.stats;
          const index = stats.fund_id;
          if (Object.keys(stats).length !== 0) {
            const formatedDataOG = stats.results_OG.map((elem) =>
              formatResultsFromData(elem, index)
            );
            const formatedDataFM = stats.results_FM.map((elem) =>
              formatResultsFromData(elem, index)
            );
            const formatedDataSH = stats.results_SH.map((elem) =>
              formatResultsFromData(elem, index)
            );
            const formatedDataFMSH = stats.results_FM_SH.map((elem) =>
              formatResultsFromData(elem, index)
            );

            extraOG = [...extraOG, ...formatedDataOG];
            extraSH = [...extraSH, ...formatedDataSH];
            extraFM = [...extraFM, ...formatedDataFM];
            extraFMSH = [...extraFMSH, ...formatedDataFMSH];
          }
          return {
            key: funds[i]._id,
            name: funds[i].name,
            lastUpdated: new Date(stats?.updatedAt),
            website: funds[i].website_url,
          };
        }); // end first map

        const allFormated = {
          OG: formatGraphData(extraOG, startDate, endDate),
          SH: formatGraphData(extraSH, startDate, endDate),
          FM: formatGraphData(extraFM, startDate, endDate),
          FMSH: formatGraphData(extraFMSH, startDate, endDate),
        };

        setGraphLines(allstats);
        setESGstats(allFormated);
      })
      .finally(() => {
        setGLoading(false);
      });
  };

  //function to group funds stats by year
  const formatGraphData = (array, startDate, endDate) => {
    let groupedData = array.reduce((acc, curr) => {
      if (
        (startDate && curr.year < startDate.$y) ||
        (endDate && curr.year > endDate.getFullYear())
      ) {
        return acc;
      }
      if (!acc[curr.year]) {
        acc[curr.year] = [];
      }
      acc[curr.year].push(curr);
      return acc;
    }, {});

    let filteredData = Object.keys(groupedData).map((item) => {
      return Object.assign(...groupedData[item]);
    });
    return filteredData;
  };

  const reAsignIds = (array) => {
    const newArray = array.map((proposal, index) => {
      const { id, ...otherData } = proposal;
      otherData.id = index;
      return otherData;
    });
    return newArray;
  };

  const handleChangeView = (event, newValue) => {
    setActiveGraph(newValue);
  };

  return (
    <Page>
      <Side location={location.pathname} activeGraph={activeGraph}>
        <SearchFundsForm
          {...{
            activeGraph,
            setAllFunds,
            getESGforAll,
            setCsvVotesParams,
            setTotalVotesFund,
            setLoadingChart,
            setVoteProportionAllLoading,
            reAsignIds,
            setProposalsVoteAll,
            selectedFunds: allfunds,
            setFormOptions,
            user,
          }}
        />
      </Side>
      <MainContent title={"Investors' Voting Records, Visualized"} colorTitle={"red.dark"}>
        <MainContent.Actions>
          <NaviTabs {...{ activeGraph, handleChangeView, allfunds, user }} />
        </MainContent.Actions>
        <MainContent.Body>
          {gLoading && <SpinnerFull message="Retrieving Information" />}
          <Box sx={{ width: "100%" }}>
            <TabPanel value={activeGraph} index={0}>
              <Box sx={{ mb: 2 }}>
                <Paper variant="elevation" sx={{ p: 2, bgcolor: "gray.extraLight" }}>
                  This tool enables you to see how your investment fund has
                  voted on environmental, social, and governance (ESG) proposals
                  at an aggregate level over time. Use the filters to perform
                  your search and compare your fund to others.
                </Paper>
              </Box>
              <ESGgraph
                {...{
                  ESGstats,
                  graphLines,
                  currentSubject,
                  setCurrentSubject,
                  formOptions,
                }}
              />
            </TabPanel>
              <TabPanel value={activeGraph} index={1}>
              <Box sx={{ mb: 2 }}>
                <Paper variant="elevation" sx={{ p: 2, bgcolor: "gray.extraLight" }}>
                  This tool enables you to see how your investment fund has
                  voted on environmental, social, and governance (ESG) proposals
                  at specific companies. Use the filters to perform your search
                  and compare your fund to others.
                </Paper>
              </Box>
              <VotesView
                {...{
                  esgcheck: ESGstats,
                  csvVotesParams,
                  totalVotesFund,
                  setTotalVotesFund,
                  loadingChart,
                  setLoadingChart,
                  voteProportionAllLoading,
                  setVoteProportionAllLoading,
                  reAsignIds,
                  proposalsVoteAll,
                  setProposalsVoteAll,
                  user,
                }}
              />
            </TabPanel>
            <TabPanel value={activeGraph} index={2}>
              <VotesView
                {...{
                  esgcheck: ESGstats,
                  csvVotesParams,
                  totalVotesFund,
                  setTotalVotesFund,
                  loadingChart,
                  setLoadingChart,
                  voteProportionAllLoading,
                  setVoteProportionAllLoading,
                  reAsignIds,
                  proposalsVoteAll,
                  setProposalsVoteAll,
                  user,
                }}
              />
            </TabPanel>
          </Box>
        </MainContent.Body>
      </MainContent>
    </Page>
  );
};
export default StatsView;
