import renderCellExpand from "../utils/renderCellExpand";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

/**
 * <p>Data grid displaying filtered proposals</p>
 * @param {Object} props
 * @param {Array} props.proposals - proposals of the table
 * @param {Array} props.pagination - pagination data from the proposals
 * @param {Array} props.selectedProposals - selected proposals in the data grid
 * @param {function} props.setSelectedProposals - state hook for selectedProposals
 * @param {boolean} props.editorMode - true if the view is /edit false if the view is home page
 * @param {boolean} props.resultsLoading - true if searchProposals is called and awaiting result, false i.o.c
 * @param {Object} props.formikRef - reference to form in side menu
 * @param {function} props.searchProposals - searches for paginated proposals
 * @param {boolean} props.isAdvancedSearch - true if side menu displays all form fields, false i.o.c
 * @returns rendered view of Results data grid
 * @author rafaelg5
 */
export const ResultsLogs = ({
  logs,
  paginationLogs,
  resultsLoading,
  searchLogs,
  formikLogsRef,
}) => {
  const columns = [
    {
      field: "proposal",
      headerName: "Document",
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "operation",
      headerName: "Operation",
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "createdAt",
      headerName: "Modified",
      flex: 3,
      renderCell: ({ value, colDef }) =>
        renderCellExpand({ value: value, colDef }),
    },
    {
      field: "prevData",
      headerName: "Prev Data",
      flex: 4,
      renderCell: ({ value, colDef }) => {
        let result = [];
        Object.entries(value).forEach(([key, values]) => {
          if (key === "subjects") {
            result.push("subject:" + values.subject);
          } else if (key === "votes") {
            result.push("votes total :" + values.length);
          } else if (key === "proposals") {
            values.forEach((item) => {
              Object.entries(item).forEach(([key2, values2]) => {
                if (key2 === "votes") {
                  result.push("votes total :" + values2.length);
                } else result.push(key2 + " : " + values2);
              });
              result.push("\n-----");
            });
          } else result.push(key + " : " + values);
        });
        return renderCellExpand({ value: result.join(" , "), colDef });
      },
    },
    {
      field: "newData",
      headerName: "New Data",
      flex: 4,
      renderCell: ({ value, colDef }) => {
        let result = [];
        Object.entries(value).forEach(([key, values]) => {
          if (key === "subjects") {
            result.push("subject :" + values.subject);
          } else if (key === "votes") {
            result.push("votes total :" + values.length);
          } else result.push(key + " : " + values);
        });
        return renderCellExpand({ value: result.join(" , "), colDef });
      },
    },
  ];

  /*ANCHOR PAGE CHANGE LOGS FUNCTION*/
  const handlePageChangeLogs = async (newPage) => {
    const values = formikLogsRef.current.values;
    const nonFalsyValues = Object.keys(values).filter((key) => {
      const cond1 = typeof values[key] === "string" && values[key] !== "";
      const cond2 = typeof values[key] === "object" && !!values[key].length;
      return cond1 || cond2;
    });

    const body = {};
    nonFalsyValues.forEach((key) => (body[key] = values[key]));
    body.page = newPage + 1;
    await searchLogs(body);
  };

  return (
    <>
      <Box sx={{ height: "70vh" }}>
        <DataGrid
          rowCount={paginationLogs.totalDocs}
          pagination
          rowsPerPageOptions={[100]}
          onPageChange={(p) => handlePageChangeLogs(p)}
          loading={resultsLoading}
          paginationMode="server"
          getRowId={(row) => row._id}
          rows={logs}
          columns={columns}
        />
      </Box>
    </>
  );
};

export default ResultsLogs;
