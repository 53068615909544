import { useState, useRef} from 'react';
import { Button, Row, Form, Col } from 'react-bootstrap'

import { Box } from '@mui/material';
import { Formik } from 'formik';
import {
    FormikTextInput as TextField,
    FormikSelect as Select
} from '../utils/forms';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { BaseModal } from "../Layout/BaseModal";
import { createUser } from '../../utils/reqs';

/**
 * Modal component to edit or add an user.
 * @param {Object} props
 * @param {Boolean} props.show - if the modal is shown or not
 * @param {Function} props.handleClose - function to close the modal
 * @param {Function} props.action - function to execute when the user confirms the action
 * @param {String} props.title - title of the modal
 * @param {Object} props.user - user to edit
 * @param {Function} props.setRefreshUsers - function to refresh the users list
 * @returns the rendered component
 * @author Raferu
 * @author valeriaxeleva
 */
export const EditionMode = ({ show, handleClose, action, title, user, setRefreshUsers }) => {

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false)
    const formRef = useRef();
    const user_type_validation = !user?.user_type ? "na" : user?.user_type;

    const handleAddUser = (body) => {
        setLoading(true);
        createUser(body)
            .then((res) => {
                alert(res.message);
                setRefreshUsers(c => !c)
                handleClose(false);
            })
            .catch((error) => {
                console.log("error", error)
                alert(error.message)
            })
            .finally(() => {
                setLoading(false)
            })

    }

    const handleUpdateUser = (body) => {
        const { user_type, ...rest } = body;
        if (user_type === "na") {
            action(user._id, rest);
        } else {
            action(user._id, body);
        }
        handleClose(false);
    }

    const handleSubmit = () => {
        if (formRef.current) {
          formRef.current.handleSubmit();
        }
      };

    const validationSchema = !user ?
        Yup.object({
            email: Yup.string().required("Required"),
            password: Yup.string().required("Required")
        }) :
        Yup.object({
            email: Yup.string().required("Required")
        })

    return (
        <>
            <BaseModal showModal={show} setShowModal={handleClose} title={title}>
                <BaseModal.Body>
                    <Box>
                        <Formik
                        innerRef={formRef}
                            initialValues={{
                                email: !user ? '' : user.email,
                                name: !user ? '' : user.name,
                                roles: !user ? ['View'] : user.roles,
                                user_type: !user ? 'Normal' : user_type_validation,
                                password: ''
                            }}
                            validationSchema={validationSchema}
                            enableReinitialize
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                if (!user) {
                                    handleAddUser(values);
                                } else {
                                    handleUpdateUser(values);
                                }
                                setSubmitting(false);
                            }}
                        >
                            {({ handleSubmit, dirty }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Form.Group as={Col} controlId="formEmail">
                                            <TextField
                                                name="email"
                                                type="text"
                                                label="Email"
                                                disabled={user}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formName">
                                            <TextField
                                                name="name"
                                                type="text"
                                                label="Name"
                                            />
                                        </Form.Group>
                                    </Row>
                                    {!user &&
                                        <Row>
                                            <Form.Group as={Col} xs={6} controlId="formPassword">
                                                <TextField
                                                    name="password"
                                                    type={showPassword ? "text" : "password"}
                                                    label="Password"
                                                />
                                            </Form.Group>
                                            <FontAwesomeIcon
                                                style={{ cursor: "pointer" }}
                                                className="align-self-center mt-2"
                                                onClick={() => setShowPassword(!showPassword)}
                                                size="lg"
                                                icon={showPassword ? faEyeSlash : faEye}
                                                title={showPassword ? "Hide password" : "Show password"}
                                            />
                                        </Row>
                                    }
                                    <Row>
                                        <Form.Group as={Col} controlId="formUserType">
                                            <Select label="User Type" name="user_type">
                                                <option value="na">Not assigned</option>
                                                <option value="Asset Manager">Asset Manager</option>
                                                <option value="Normal">Normal</option>
                                                <option value="Pension">Pension</option>
                                            </Select>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formRoles">
                                            <Select label="Roles" name="roles" multiple>
                                                <option value="View">Viewer</option>
                                                <option value="Admin">Administrator</option>
                                                <option value="Upload">Uploader</option>
                                                <option value="EditProposals">Proposal Editor</option>
                                                <option value="EditCompanies">Company Editor</option>
                                                <option value="EditInvestors">Investor Editor</option>
                                            </Select>
                                        </Form.Group>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Button variant="secondary" onClick={()=>handleClose(false)} disabled={loading}>
                        Cancel
                    </Button>
                    <Button type="submit" onClick={handleSubmit} variant="success" disabled={loading}>
                        {!loading ? "Save Changes" : "Loading..."}
                    </Button>
                </BaseModal.Footer>
            </BaseModal>
        </>
    )
}
