import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Button, Dropdown } from "react-bootstrap";
import renderCellExpand from "../utils/renderCellExpand";
import { EditFundForm } from "./EditFundForm";
import { EditTable } from "../Layout/EditTable";
import { updateFund, mergeFunds } from "../../utils/reqs";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { BaseModal } from "../Layout/BaseModal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import SpinnerFull from "../utils/SpinnerFull";
import { StatusBar } from "../Layout/GeneralElements";

/**
 * Table to display fetched data using MUI components with server pagination, based on the Editor + Logs OxProx pattern.
 * @param {Object} props
 * @param {Object[]} props.rows - The rows of the data to be displayed
 * @param {number} props.rowsCount - The total number of rows.
 * @param {function} props.setRows - Function to change the state of the rows hook.
 * @param {function} props.setRowsCount - Function to change the state of the total number of rows hook.
 * @param {boolean} props.showLogsTable - True if the visible view is the Logs one, false if is the Editor view. Note the columns, rows and searchFunction will change according of this value.
 * @param {boolean} props.loadingTable - True if the data is still fetching, false i.o.c.
 * @param {function} props.setLoadingTable -  Function to change the state of the loading hook.
 * @param {function} props.searchFunction - The function that will search the data if the user changes the page.
 * @param {Object} props.formikMainSearch - The body query that will be passed to the searchFunction
 * @param {string} props.searchFundsBy - String to specify if the search was made for modal or by lateral formik
 * @returns the rendered component
 * @author milenexeleva
 */
const FundsTable = (
  {
    rows,
    rowsCount,
    setRows,
    setRowsCount,
    showLogsTable,
    loadingTable,
    setLoadingTable,
    searchFunction,
    formikMainSearch,
    searchFundsBy,
  },
  ref
) => {
  const [user] = useAuthState(auth);
  const username = user?.email;
  const [showModal, setShowModal] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [aliasChips, setAliasChips] = useState([]);
  const [remainOptions, setRemainOptions] = useState([]);
  const [currentFundEdit, setCurrentFundEdit] = useState(null);
  const [remainFund, setRemainFund] = useState(undefined);
  const [selectionModel, setSelectionModel] = useState([]); // it will save the id selected when the user selects a checkbox
  const [loading, setLoading] = useState(false);

  const cellHierarchy = {
    manager: { color: "#94AE89", name: "Parent Fund" },
    cik: { color: "#c0da74", name: "Fund Family" },
    plan: { color: "#D5FFD9", name: "Fund" },
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 5,

      renderCell: ({ row, colDef }) => {
        return renderCellExpand({ value: row?.name, colDef });
      },
    },
    {
      field: "acronym",
      headerName: "Acronym",
      flex: 3,

      renderCell: ({ row, colDef }) => {
        return renderCellExpand({ value: row?.acronym, colDef });
      },
    },
    {
      field: "type",
      headerName: "Investor Type",
      flex: 3,

      renderCell: ({ row, colDef }) => {
        return renderCellExpand({
          value: row.type === "investment" ? "Asset Manager" : "Asset Owner",
          colDef,
        });
      },
      valueGetter: ({ row }) =>
        row.type === "investment" ? "Asset Manager" : "Asset Owner",
      valueSetter: ({ row }) => ({
        ...row,
        type: row.type === "investment" ? "Asset Manager" : "Asset Owner",
      }),
    },
    {
      field: "hierarchy",
      headerName: "Hierarchy",
      flex: 3,

      renderCell: ({ row }) => (
        <Box
          sx={{
            width: "100%",
            backgroundColor: cellHierarchy[row.hierarchy].color,
            textalign: "center",
          }}
        >
          {cellHierarchy[row.hierarchy].name}
        </Box>
      ),
      valueGetter: ({ row }) => cellHierarchy[row.hierarchy].name,
      valueSetter: ({ row }) => ({
        ...row,
        hierarchy: cellHierarchy[row.hierarchy].name,
      }),
    },
    {
      field: "parent",
      headerName: "Parent",
      flex: 4,

      renderCell: ({ row, colDef }) => {
        return renderCellExpand({
          value: row.parentId ? row.parentId.name : "",
          colDef,
        });
      },
      valueGetter: ({ row }) => row.parentId?.name || "",
      valueSetter: ({ row }) => ({
        ...row,
        parent: row.parentId?.name || "",
      }),
    },
    {
      field: "country",
      headerName: "Country",
      flex: 3,

      renderCell: ({ row, colDef }) => {
        return renderCellExpand({ value: row?.country, colDef });
      },
    },
    {
      field: "website_url",
      headerName: "Website",
      flex: 5,
      renderCell: ({ row, colDef }) => {
        return renderCellExpand({ value: row?.website_url, colDef });
      },
    },
    {
      field: "_id",
      headerName: "Action",
      flex: 2,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              openEditModal(params.row);
            }}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const columnsLogs = [
    {
      field: "fund",
      headerName: "Document",
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "operation",
      headerName: "Operation",
      flex: 2,
      renderCell: renderCellExpand,
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "createdAt",
      headerName: "Modified",
      flex: 3,
      renderCell: ({ value, colDef }) =>
        renderCellExpand({ value: value, colDef }),
    },
    {
      field: "progress",
      headerName: "Progress",
      flex: 3,
      renderCell: StatusBar,
    },
    {
      field: "prevData",
      headerName: "Prev Data",
      flex: 4,
      renderCell: ({ value, colDef }) => {
        if(!value) return null
        let result = [];
        let count = 0;
        Object.entries(value).forEach(([key, values]) => {
          const num = Number(key);
          if (Number.isInteger(num) && value.length !== undefined) {
            /*
                                    without condition, the companies will repeat many times
                                    */
            if (!count) {
              for (const comp of value) {
                result.push(" name: " + comp?.name);
                let aliases = "";
                if (comp?.aliases !== undefined) {
                  for (const alias of comp?.aliases) {
                    aliases += alias;
                  }
                }
                result.push(
                  " aliases: " +
                    (aliases.length > 0 && aliases !== undefined
                      ? aliases
                      : "none")
                );
              }
            }
            count++;
            if (count === value.length) count = 0;
          } else result.push(key + " : " + values);
        });
        return renderCellExpand({ value: result.join(" , "), colDef });
      },
    },
    {
      field: "newData",
      headerName: "New Data",
      flex: 4,
      renderCell: ({ value, colDef }) => {
        if(!value) return null
        let result = [];
        Object.entries(value).forEach(([key, values]) => {
          result.push(key + " : " + values);
        });
        return renderCellExpand({ value: result.join(" , "), colDef });
      },
    },
  ];

  /**
   * Verify defualt fields and does the request to edit the fund
   * @param {Object} values - object with data from the search form
   * @param {string} [values.fundname] - Fund's name
   * @param {string} [values.acronym] - Fund's acronym
   * @param {string} [values.country] - Fund's country
   * @author ValeriaG
   * @author Medina192
   */
  const editFund = async (values) => {
    values.username = username;
    values.alias = aliasChips;
    setLoading(true);
    const funds = await updateFund(values);

    const fundUpdated = { ...currentFundEdit };
    fundUpdated.name = values.name;
    fundUpdated.alias = aliasChips;
    fundUpdated.acronym = values.acronym;
    fundUpdated.country = values.country;

    if (funds.message === "Success") {
      alert("Investor updated succesfully!");
      setShowModal(false);
      await setLoadingTable(true);
      setRows(
        rows.map((fun) => {
          if (fun._id === fundUpdated._id) return fundUpdated;
          else return fun;
        })
      );
      setLoadingTable(false);
      setLoading(false);
    } else {
      alert(funds.message);
    }
  };

  /**
   * Open the edit modal, verify and set the initial values of the fund to edit, also sets the aliases in chips in case there are any.
   * @param {Object} fund - object with data from the search form
   * @author Medina192
   * @author ValeriaG
   */
  const openEditModal = (fund) => {
    setCurrentFundEdit(fund);
    let alias = fund.alias ? fund.alias : [];
    setAliasChips(alias);
    setShowModal(true);
  };

  const handleCloseEdit = async () => {
    setShowModal(false);
    if (!searchFundsBy.current === "modal") {
      await setLoadingTable(true);
      await searchFunction(formikMainSearch.current)
        .then((response) => {
          setCurrentFundEdit(null);
          setRows(response.funds);
          setRowsCount(response.totalDocs);
          setLoadingTable(false);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const openMergeModal = () => {
    let filtered = rows.filter((obj) => {
      for (const selection of selectionModel) {
        if (selection === obj._id) return true;
      }
      return false;
    });
    setRemainFund(undefined);
    setRemainOptions(filtered);
    setShowMergeModal(true);
  };

  const handleClose = () => {
    setShowMergeModal(false);
    if (remainOptions.length === 2) {
      setRemainOptions([]);
      setRemainFund(undefined);
    }
  };

  const formatFundIdsForMerge = (fund) => {
    fund.id = {$oid:fund._id}
    if(fund.parentId) {
      if(typeof(fund.parentId)!="string"){
      fund.parentId = {$oid:fund.parentId._id}
      } else {
        fund.parentId = {$oid:fund.parentId}
      }
    } else {
      fund.parentId = {$oid: "000000000000000000000000"}
    }
  }

  const handleRemainFundChange = (fund) => {
    setRemainFund(fund);
  };

  const handleMergeFund = () => {
    if(remainOptions.length>2){
      alert("Only two funds can be merged at a time");
      return;
    }
    if (!remainFund) {
      alert("You must select the fund that will stay!");
      return;
    }

    const fundsToMerge = remainOptions.filter((fund) => {
      return fund._id !== remainFund._id;
    });

    handleClose();
    setLoading(true);
    const fundToMerge = fundsToMerge[0]
    formatFundIdsForMerge(fundToMerge)
    formatFundIdsForMerge(remainFund)
    mergeFunds({
      selectedFund: remainFund,
      fundToMerge,
      username,
    })
      .then((res) => {
        // remove the merged funds manually, withou do another request
        let filtered = rows.filter((obj) => {
          if (remainFund._id.toString() === obj._id.toString()) {
            obj.alias = remainFund.alias;
            return true;
          }
            if (fundToMerge._id.toString() === obj._id.toString()) return false;
          return true;
        });
        setRows(filtered);
        setRemainOptions([]);
        setRemainFund(undefined);
        setSelectionModel([]);
        alert("Merging process started successfully. Switch to Logs View to see the progress");
      })
      .catch((error) => {
        console.log("error", error);
        const { response } = error;
        alert(!response?.data ? error.message : response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateTable = () => {
    let aux = rows.map((com) => {
      if (com._id === currentFundEdit.current._id) {
        return currentFundEdit.current; // i don´t return the company data inside the response because it returns the company before the updating
      } else return com;
    });
    setRows(aux);
  };

  const tableProps = {
    rows,
    columns: showLogsTable ? columnsLogs : columns,
    showLogsTable,
    setRows,
    rowsCount,
    loadingTable,
    searchFunction,
    formikMainSearch,
    selectionModel,
    setSelectionModel,
  };

  useImperativeHandle(ref, () => ({
    openMergeModal,
  }));

  return (
    <>
      {loading && <SpinnerFull message={"Saving changes"} />}
      <EditTable {...tableProps} />
      <EditFundForm
        {...{
          editFund,
          aliasChips,
          setAliasChips,
          updateTable,
          showModal,
          setShowModal,
          handleCloseEdit,
          currentFundEdit,
        }}
      />
      <BaseModal
        showModal={showMergeModal}
        setShowModal={setShowMergeModal}
        title="Merge Funds"
      >
        <BaseModal.Body>
          <Box>
            {remainOptions.length !== 2 ? (
              remainOptions.length < 2 ? (
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Please select at least two funds to merge.
                </Typography>
              ) : (
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  You can only select two investors at a time.
                </Typography>
              )
            ) : remainOptions.some(
                (fund) => fund.hierarchy !== remainOptions[0].hierarchy
              ) ? (
              <Typography id="modal-modal-title" variant="h6" component="h2">
                The funds must be of the same hierarchy
              </Typography>
            ) : (
              <>
                <div>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Are you sure you want to merge these investors?
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Warning: This operation is irreversible and may take several minutes.
                  </Typography>
                  <Dropdown>
                    <Dropdown.Toggle variant="info" id="dropdown-basic">
                      {remainFund
                        ? remainFund.name + " from " + remainFund.country
                        : "None"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {remainOptions.map((option) => {
                        return (
                          <Dropdown.Item
                            key={option._id}
                            onClick={() => handleRemainFundChange(option)}
                          >
                            {option.name} from {option.country}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <FormHelperText>
                    Select the investor that will stay
                  </FormHelperText>
                </div>
              </>
            )}
          </Box>
        </BaseModal.Body>
        <BaseModal.Footer>
          <Button
            disabled={showMergeModal === undefined}
            className="m-2 button-continue"
            onClick={handleMergeFund}
          >
            Continue
          </Button>
          <Button className="m-2 button-cancel" onClick={handleClose}>
            Cancel
          </Button>
        </BaseModal.Footer>
      </BaseModal>
    </>
  );
};

export default forwardRef(FundsTable);
