import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";

/**
 * The function `changeColumns` counts the number of direct child `li` elements within an element with
 * the id "fundList" and returns whether the count is greater than 14.
 * @returns The function `changeColumns` is returning a boolean value based on whether the count of
 * `li` elements inside the `fundList` container is greater than 14.
 */
const changeColumns = () => {
  let count = 0;
  const fundListContainer = document.getElementById("fundList")
  const lisDirectos = fundListContainer.querySelectorAll('li');
  count += lisDirectos.length;
  return count > 14
};

/**
 * <p>Return the list html element of plan level</p>
 * @param {Object} props.fund - An object with fund data, level plan
 * @param {Object} props.manager - An object with manager data
 * @param {Object} props.cik - An object with cik data
 * @returns the component of the plan level
 * @author Medina192
 */
const PlanLevel = (props) => {
  const { fund, manager, cik, ...otherProps } = props;
  const { handleCheck, selectedFunds, editView } = otherProps;

  let managerFund = manager;
  let cikFund = cik;
  if (props.editView) {
    managerFund = null;
    cikFund = null;
  }

  return (
    <li className="align-items-plan">
      <div className="flex-row">
        <input
          type="checkbox"
          name="fundName"
          value={fund.name}
          id={fund._id}
          checked={
            selectedFunds.find(
              (f) =>
                f.name === fund.name &&
                f.hierarchy === "plan" &&
                fund._id === f.id
            ) !== undefined
          }
          onChange={(event) =>
            handleCheck(
              event.target.checked,
              fund,
              fund.hierarchy,
              editView,
              managerFund,
              cikFund
            )
          }
        />
        <label className="ml-2" htmlFor={fund._id}>
          {fund.name}
        </label>
      </div>
    </li>
  );
};

/**
/**
 * <p>Return the list html element of cik level</p>
 * @param {Object} props.fund - An object with fund data, level cik
 * @param {Object} props.manager - An object with manager data
 * @returns the component of the cik level 
 * @author Medina192
 */
const CikLevel = (props) => {
  const { fund, manager, setChangeColumns, ...otherProps } = props;
  const { handleCheck, selectedFunds, editView } = otherProps;
  const [showChildren, toggleChildren] = useState(false);

  let managerFund = manager;
  if (props.editView) managerFund = null;

  return (
    <li className="align-items-cik">
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <input
          type="checkbox"
          name="fundName"
          value={fund.name}
          id={fund._id}
          checked={
            selectedFunds.find(
              (f) =>
                f.name === fund.name &&
                f.hierarchy === "cik" &&
                fund._id === f.id
            ) !== undefined
          }
          onChange={(event) =>
            handleCheck(
              event.target.checked,
              fund,
              fund.hierarchy,
              editView,
              managerFund
            )
          }
        />
        <label style={{ margin: "0 5px" }} htmlFor={fund._id}>
          {fund.name}
        </label>
        {
          // sublist plan level
          fund.subs?.length > 0 && (
            <button
              type="button"
              className="btn btn-light dropdown-btn-subfunds"
              onClick={() => {
                setTimeout(() => {setChangeColumns(changeColumns())}, 500);
                toggleChildren(!showChildren);
              }}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <FontAwesomeIcon icon={showChildren ? faCaretUp : faCaretDown} />
            </button>
          )
        }
      </div>
      {
        <div className="w-100">
          {showChildren && (
            <ul className="sub_funds">
              {fund.subs.map((plan, indexMap) => (
                <PlanLevel
                  key={`p-${plan.name}-${plan._id}`}
                  fund={plan}
                  cik={fund}
                  manager={manager}
                  {...otherProps}
                />
              ))}
            </ul>
          )}
        </div>
      }
    </li>
  );
};

/**
/**
 * <p>Return the list html element of manager level</p>
 * @param {Object} props.fund - An object with fund data, level manager
 * @returns the component of the manager level 
 * @author Medina192
 */
const ManagerLevel = (props) => {
  const { fund, setChangeColumns, ...otherProps } = props;
  const { handleCheck, selectedFunds, editView } = otherProps;
  const [showChildren, toggleChildren] = useState(false);

  return (
    <li className="align-items-manager">
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <input
          type={"checkbox"}
          name="fundName"
          value={fund.name}
          id={fund._id}
          checked={
            selectedFunds.find(
              (f) =>
                f.name === fund.name &&
                f.hierarchy === "manager" &&
                fund._id === f.id
            ) !== undefined
          }
          onChange={(event) =>
            handleCheck(event.target.checked, fund, fund.hierarchy, editView)
          }
        />
        <label style={{ margin: "0 5px" }} htmlFor={fund._id}>
          {fund.name}
        </label>
        {
          // dropdown button
          fund.subs?.length > 0 && !otherProps.statsView && (
            <button
              type="button"
              className="btn btn-light dropdown-btn-subfunds"
              onClick={() => {
                setTimeout(() => {setChangeColumns(changeColumns())}, 500);
                toggleChildren(!showChildren);
              }}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <FontAwesomeIcon icon={showChildren ? faCaretUp : faCaretDown} />
            </button>
          )
        }
      </div>
      {
        // sublist level cik
        <div>
          {showChildren && !otherProps.statsView && (
            <ul className="sub_funds">
              {fund.subs.map((cik) => (
                <CikLevel
                  key={`c-${cik.name}-${cik._id}`}
                  fund={cik}
                  manager={fund}
                  setChangeColumns={setChangeColumns}
                  {...otherProps}
                />
              ))}
            </ul>
          )}
        </div>
      }
    </li>
  );
};

/**
 * <p>Separate the funds of the corresponding hierarchy in two columns </p>
 * @param {Object} props.funds - the resultant funds of the search
 * @returns the component of the manager level list object
 * @author Medina192
 */
const FundLevels = (props) => {
  const { funds, ...otherProps } = props;
  const [changeColumns, setChangeColumns] = useState(false);
  const fundsColumn = funds.map((fund, index) => {
    switch (
      otherProps.typeFund === "not selected"
        ? fund.hierarchy
        : otherProps.typeFund
    ) {
      case "cik":
        return (
          <CikLevel
            key={`m-${fund.name}-${fund._id}`}
            fund={fund}
            setChangeColumns={setChangeColumns}
            {...otherProps}
          />
        );
      case "plan":
        return (
          <PlanLevel
            key={`m-${fund.name}-${fund._id}`}
            fund={fund}
            setChangeColumns={setChangeColumns}
            {...otherProps}
          />
        );
      default:
        return (
          <ManagerLevel
            key={`m-${fund.name}-${fund._id}`}
            fund={fund}
            setChangeColumns={setChangeColumns}
            {...otherProps}
          />
        );
    }
  });

  return (
    <ul
      id="fundList"
      className={changeColumns ? "funds-list-2" : "funds-list-1"}
    >
      {fundsColumn}
    </ul>
  );
};
/**
 * Component that renders the list of funds, this is the one that is exported to the principal Modal component.
 * @returns the component of the manager level list object
 */
export const FundsList = (props) => {
  return (
    <Box
      component="div"
      sx={{ width: "100%", height: "100%" }}
      id="contentList"
    >
      <FundLevels {...props} />
    </Box>
  );
};
