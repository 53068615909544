import Button from "react-bootstrap/Button";
import { BaseModal } from "../Layout/BaseModal";
/**
 * <p>Modal for confirming proposals' subjects update</p>
 * @param {Object} props
 * @param {Array} props.selectedProposals - selected proposals in the data grid
 * @param {?Object} props.selectedSubject - selected subject from Accordion
 * @param {function} props.updateProposalSubjects - updates proposals with new subjects
 * @param {function} props.handleClose - closes modal
 * @returns rendered view of the modal
 * @author rafaelg5
 */

export const SubjectUpdateConfirmation = ({ selectedProposals, selectedSubject, updateProposalSubjects, allProposalsSelected, resetSelectedCompaniesinModal, setUpdateType, showModal, setShowModal }) => {

    let lent = allProposalsSelected.length;
    const values = allProposalsSelected.map(item => item.index);
    selectedProposals.forEach(item => {
        if (!values.includes(item)) {
            lent++;
        }
    })
    const selectedProposalsLength = lent;

    const handleConfirm = async () => {
        await updateProposalSubjects();
    };

    const handleCancel = () => {
        setShowModal(false);
        setUpdateType(null);
    }

    return (
        <BaseModal showModal={showModal} setShowModal={setShowModal} title="Update Proposals">
            <BaseModal.Body>
                {selectedProposalsLength === 0 ?
                    <>
                        Please select at least one proposal from the table.
                    </> :
                    <>
                        {` Are you sure you want to change the subject of the selected 
                        proposal(s) to ` }
                        <b>{!selectedSubject ? 'No subject' : selectedSubject.subject}</b>
                        {!selectedSubject ? '?' : ` (from ${selectedSubject.parent})?`}
                    </>
                }
            </BaseModal.Body>
            {selectedProposalsLength > 0 &&
                <BaseModal.Footer>
                    <Button
                        block
                        className="my-2 mr-1"
                        variant="success"
                        onClick={handleConfirm}
                    >
                        Confirm
                    </Button>
                    <Button
                        block
                        className="my-2 mr-1"
                        variant="danger"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </BaseModal.Footer>
            }
        </BaseModal>
    )
}

export default SubjectUpdateConfirmation;