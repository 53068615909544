import { Button, Typography } from "@mui/material";
import {
  FormikTextInput as TextField,
  FormikSelect as Select,
} from "../utils/forms";
import countryList from "react-select-country-list";
import { getCompanyIndexList } from "../../utils/utils";

/**
 *  <p>Component containing the inputs for company search in modals</p>
 * @param {Object} props
 * @param {Function} props.resetForm - function to reset variables from the forms
 * @param {Boolean} props.dirty - indicates if form was filled or not
 * @param {Boolean} props.isSubmitting - indicates if onSubmit function is executing
 * @param {Array} props.industries - list of names of industries to which a company can belong
 * @param {Object} props.auxResetForm - useRef variable to store current status of resetForm
 * @param {Function} props.setNotFoundCompaniesMessage - function to determine whether or not to display a not found message
 * @param {string} props.keyWord - component key name given by the set of Tabs
 * @param {string} props.idName - component name defined in the Modal component
 * @returns rendered view of the component
 * @author milenexeleva
 */
const CompanySearchFields = ({
  resetForm,
  dirty,
  isSubmitting,
  industries,
  auxResetForm,
  setNotFoundCompaniesMessage,
  keyWord,
  idName,
  isFiltersClean,
  setIsFiltersClean,
  clearValues,
  values,
  isPublicSearch
}) => {
  const modalCompaniesFormStyles = {
    labelStyle: "d-none",
    inputSelectStyle: "modal-company-width-auto",
  };
  const countries = countryList().getData();
  const companyIndexes = getCompanyIndexList();

  const selectedsFields = (
    <>
      {isPublicSearch?
      null
      :<Select
        customStyle={{
          ...modalCompaniesFormStyles,
          inputSelectStyle: "modal-company-width-small",
        }}
        name="index"
      >
        <option value="default" key="default">
          --Index
        </option>
        {companyIndexes.map((indexName) => (
          <option value={indexName} key={indexName}>
            {indexName}
          </option>
        ))}
      </Select>}
      <Select customStyle={modalCompaniesFormStyles} name="industry">
        <option value="default" key="default">
          --Industry
        </option>
        {industries.map((category, index) => {
          if (category.slice(0, 2) !== "--")
            return (
              <option value={category} key={category}>
                {category}
              </option>
            );
          else
            return (
              <option disabled value={category} key={category}>
                {category}
              </option>
            );
        })}
      </Select>

      <Select customStyle={modalCompaniesFormStyles} name="country">
        <option value="default" key="default">
          --Country
        </option>
        {countries.map((category, index) => (
          <option value={category.label} key={category.label}>
            {category.label}
          </option>
        ))}
      </Select>
    </>
  )

  let selectedFieldContent;
  if (idName === "namesIsin") {
    selectedFieldContent = <TextField name="ISINs" type="text" placeholder="ISIN code" />;
  } else if (idName === "namesForm") {
    selectedFieldContent = (
      <>
        <TextField name="name" type="text" placeholder="Name" />
        {selectedsFields}
      </>
    );
  } else {
    selectedFieldContent = (
      <>
        {selectedsFields}
      </>
    );
  }

const isDisable = () =>{
  if (!isFiltersClean) return false
  return !dirty || isSubmitting || keyWord === "none"
}

  return (
    <div className="companyFilters_form">
      <Typography component="p" variant="body1">Filter by:</Typography>
      {selectedFieldContent}
      <div className="form-buttons-box">
        <Button
          type="submit"
          sx={{ width: "fit-content" }}
          onClick={() => {
            setIsFiltersClean(false)
            setTimeout(() => {
              auxResetForm.current.resetFunction = resetForm;
              auxResetForm.current.isThereAFormikSelected = true;
            }, 300); // by some unknown reason, it is necessary to wait this time for the auxResetForm variable takes the correct reference to the reset function
          }}
          disabled={!dirty || isSubmitting}
        >
          Search
        </Button>
        <Button
          variant="light"
          sx={{ width: "fit-content" }}
          type="submit"
          onClick={() => {
            clearValues(values)
            resetForm()
            setNotFoundCompaniesMessage(false);
          }}
          disabled={isDisable()}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

export default CompanySearchFields;
