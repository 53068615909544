import { Children } from "react";
import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";

const Body = () => null;

/**
 * Template for the main content of the Home Page OxProx pattern.
 * @param {Object} props
 * @param {string} props.title - Title of the page
 * @param {node} props.children - Children elements to be rendered on the main page.
 * @returns the rendered component
 * @author valeriaxeleva
 */
export const HomeMainContent = ({ title, children }) => {
  children = Children.toArray(children);
  const body = children.find((el) => el.type === Body);
  return (
    <Box sx={{ height: "100%" }}>
      <Helmet>
        <title>OxProx | {title}</title>
      </Helmet>
      <Box sx={{ height: "100%", width: "100%" }}>
        {body ? body.props.children : null}
      </Box>
    </Box>
  );
};

HomeMainContent.Body = Body;
