import React, {  useContext, useState, useEffect, useCallback, useMemo } from 'react';
import {
    getSASBLists,
} from "../utils/reqs";

const SubjectContext = React.createContext([]);

export function useSASBLists(){
    return useContext(SubjectContext);
}
/**
 * Fetches the SASB lists from the backend and stores them in the context
 * @param {Object} children - The children of the component
 * @returns the context provider
 * @author valeriaxeleva
 */
export function SubjectProvider({children}){

    const [SASBProposalsList, setSASBProposalsList] = useState([]);
    const [SASBCompaniesList, setSASBCompaniesList] = useState([]);


    const loadSubjects = useCallback(async () => {
        try {
            const sasbProposals = await getSASBLists("proposals");
            setSASBProposalsList(sasbProposals);

            const sasbCompanies = await getSASBLists("companies");
            setSASBCompaniesList(sasbCompanies);
        } catch (error) {
            console.log("error loading in subjectContext")
        }
    }, []);

    useEffect(() => {
        loadSubjects();
    }, [loadSubjects]);

    const value = useMemo(() => ({ SASBProposalsList, SASBCompaniesList }), [SASBProposalsList, SASBCompaniesList]); 

    return (
        <SubjectContext.Provider value={value}>
            {children}
         </SubjectContext.Provider>
    )
}                                       
