import React, { useState, useRef, useEffect } from "react";
import "../css/modal.scss";
import { Tab, Tabs, Button } from "react-bootstrap";
import { getGroupedFunds } from "../../utils/reqs";
import Spinner from "../utils/Spinner";
import { BaseModal } from "../Layout/BaseModal";
import TabResultListFunds from "./TabResultListFunds";

/**
 * <p>Component to perform the advanced search of investors, presented in a modal.</p>
 * @param {Object} props - props of the component
 * @param {function} props.handleClose - function to close the modal
 * @param {boolean} props.show - boolean to show or not the modal
 * @param {function} props.catchFunds - function to catch the selected funds
 * @param {function} props.resetSelectedFundsModal - function to reset the selected funds
 * @param {boolean} props.statsView - boolean to know if the modal is opened from the stats view
 * @param {Array} props.preSelected - array of preselected funds to mark as selected in the modal when opened from stats view
 * @param {boolean} props.editView - boolean to know if the modal is opened from the edit view
 * @returns rendered view of the component
 * @author valeriaxeleva
 */
const ModalFund = ({
  handleClose,
  show,
  catchFunds,
  resetSelectedFundsModal,
  statsView,
  preSelected,
  editView,
}) => {
  const letters = [
    "#",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const [fundsAlphabetical, setFundsAlphabetical] = useState([]);
  const [fundsHierarchical, setFundsHierarchical] = useState([]);
  const [keyLetter, setKeyLetter] = useState("none");

  const types = ["Asset Manager", "Asset Owner"]; // ['investment', 'pension']
  const hierarchies = ["Manager", "Fund Family", "Fund"]; // ['manager', 'cik', 'plan]

  const [notFoundFundsMessage, setNotFoundFundsMessage] = useState(false);
  const formikFilterRef = useRef(null);
  const formikFilterHierarchicalRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [selectedFunds, setSelectedFunds] = useState([]);
  const [fundsCopy, setFundsCopy] = useState([]);
  const [fundsCopyHierarchical, setFundsCopyHierarchical] = useState([]);
  const currentTypeSearched = useRef("manager");

  useEffect(() => {
    if (statsView && preSelected) {
      setSelectedFunds(preSelected);
    }
  }, [preSelected, statsView]);

  // get the funds searching just by fundStarting, type or country
  const handleSubmit = (data, eventKey) => {
    const values = { ...data }; // make a copy, not reference with a pointer
    if (values.hierarchy === "" || values.hierarchy === "default")
      delete values.hierarchy;
    else {
      if (values.hierarchy === "Manager") values.hierarchy = "manager";
      else if (values.hierarchy === "Fund Family") values.hierarchy = "cik";
      else values.hierarchy = "plan";
    }

    if (values.type === "" || values.type === "default") delete values.type;
    else {
      if (values.type === "Asset Manager") values.type = "investment";
      else if (values.type === "Asset Owner") values.type = "pension";
    }

    // for hierarchical
    if (eventKey === "searchName") {
      switch (values.hierarchy) {
        case "cik":
          currentTypeSearched.current = "cik";
          break;
        case "plan":
          currentTypeSearched.current = "plan";
          break;
        case "":
        case "default":
        case undefined:
          currentTypeSearched.current = "not selected";
          break;
        default:
          currentTypeSearched.current = "manager";
          break;
      }
    } else {
      switch (values.hierarchy) {
        case "cik":
          currentTypeSearched.current = "cik";
          break;
        case "plan":
          currentTypeSearched.current = "plan";
          break;
        default:
          currentTypeSearched.current = "manager";
          break;
      }
    }


    setLoading(true);
    getGroupedFunds(values)
      .then((res) => {
        if (res.length > 0) {
          if (statsView) {
            for (const fundWithChildren of res) {
              delete fundWithChildren.subs;
            }
          }

          if (values.fundStarting) setFundsAlphabetical(res);
          else {
            setFundsHierarchical(res);
          }
        } else {
          setNotFoundFundsMessage(true);
        }
      })
      .catch((error) => {
        const { response } = error;
        alert(!response?.data ? error.message : response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitAlphabetical = (values) => {
    setNotFoundFundsMessage(false);
    setFundsAlphabetical([]);
    formikFilterRef.current.initialValues = {
      name: "",
      country: values.country,
      hierarchy: values.hierarchy,
      type: values.type,
    };
    if (values.type === "" || values.type === "default") delete values.type;
    if (values.country === "" || values.country === "default")
      delete values.country;

    values.fundStarting = keyLetter;
    handleSubmit(values);
  };

  const handleSubmitHierarchical = (values) => {
    setNotFoundFundsMessage(false);
    setFundsHierarchical([]);
    delete values.fundStarting;

    if (values.type === "" || values.type === "default") delete values.type;
    if (values.country === "" || values.country === "default")
      delete values.country;

    handleSubmit(values, "searchName");
  };

  // get the funds searching just by fundStarting
  function handleSelect(key) {
    setKeyLetter(key);
    setNotFoundFundsMessage(false);
    //formikFilterRef?.current?.resetForm();
    //formikFilterHierarchicalRef?.current?.resetForm();

    if (key === "searchName") return;
    else setFundsAlphabetical([]);

    let values = {
      fundStarting: key,
    };
    let formValues = {};
    if (key === "searchName") {
      formValues = formikFilterHierarchicalRef.current.values;
    } else {
      formValues = formikFilterRef.current.values;
    }
    if (formValues.country !== "" && formValues.country !== "default") {
      values = { ...values, country: formValues.country };
    }
    if (formValues.hierarchy !== "" && formValues.hierarchy !== "default") {
      values = { ...values, hierarchy: formValues.hierarchy };
    }
    if (formValues.type !== "" && formValues.type !== "default") {
      values = { ...values, type: formValues.type };
    }
    setLoading(true);
    getGroupedFunds(values)
      .then((res) => {
        if (res.length > 0) {
          if (statsView) {
            for (const fundWithChildren of res) {
              delete fundWithChildren.subs;
            }
          }
          setFundsAlphabetical(res);
        } else {
          setNotFoundFundsMessage(true);
        }
      })
      .catch((error) => {
        const { response } = error;
        alert(!response?.data ? error.message : response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setFundsCopy(fundsAlphabetical);
  }, [fundsAlphabetical]);

  useEffect(() => {
    setFundsCopyHierarchical(fundsHierarchical);
  }, [fundsHierarchical]);

  const removeDuplicates = (arr) =>
    arr.filter((elem, index) => {
      const _elem = JSON.stringify(elem);
      return (
        index ===
        arr.findIndex((obj) => {
          return JSON.stringify(obj) === _elem; // returns true until the element has been found, and stop the callback
        })
      );
    });

  const handleCheck = (
    checked,
    fund,
    hierarchy,
    editView,
    manager = null,
    cik = null
  ) => {
    if (statsView) {
      let allFunds = removeDuplicates([...selectedFunds, ...preSelected]);
      if (checked) {
        setSelectedFunds([
          ...allFunds,
          {
            ...fund,
            hierarchy: hierarchy,
            id: fund._id,
          },
        ]);
        return;
      } else {
        allFunds = allFunds.filter((f) => f._id !== fund._id);
        setSelectedFunds([...allFunds]);
        return;
      }
    }

    switch (hierarchy) {
      case "manager":
        if (checked) {
          // add the fund checked
          let fundArray = [
            {
              name: fund.name,
              acronym: fund.acronym,
              hierarchy: hierarchy,
              id: fund._id,
            },
          ];

          // add all the subs(cik) like objects, and the subs of subs(plan) like arrays
          if (!editView) {
            fund?.subs?.forEach((cik) => {
              fundArray.push({
                name: cik.name,
                acronym: cik.acronym,
                hierarchy: "cik",
                id: cik._id,
              });
              cik?.subs?.forEach((plan) =>
                fundArray.push({
                  name: plan.name,
                  acronym: plan.acronym,
                  hierarchy: "plan",
                  id: plan._id,
                })
              );
            });
          }
          fundArray = removeDuplicates([...selectedFunds, ...fundArray]);
          setSelectedFunds(fundArray);
        } else {
          const cikFunds = !fund.subs ? [] : fund.subs;

          if (!editView) {
            // case parent and children are dependent when one is checked
            let copySelectedFunds = selectedFunds.slice();
            // remove the  fund families and funds
            for (let cikFund of cikFunds) {
              copySelectedFunds = copySelectedFunds.filter(
                (c) => c.id !== cikFund._id
              );

              const planFunds = !cikFund.subs ? [] : cikFund.subs;
              for (let planFund of planFunds) {
                copySelectedFunds = copySelectedFunds.filter(
                  (c) => c.id !== planFund._id
                );
              }
            }
            // remove the parent
            setSelectedFunds(
              copySelectedFunds.filter((c) => c.id !== fund._id)
            );
          } else {
            setSelectedFunds(selectedFunds.filter((c) => c.id !== fund._id));
          }
        }
        break;
      case "cik":
        if (checked) {
          let fundArray = [];
          if (manager) {
            // case tab alphabetical add the parent just when it is sent (case editView False the parents and children are added) )
            fundArray.push({
              name: manager.name,
              acronym: manager.acronym,
              hierarchy: "manager",
              id: manager._id,
            }); // add parent fund (manager)
            fundArray.push({
              name: fund.name,
              acronym: fund.acronym,
              hierarchy: hierarchy,
              id: fund._id,
            });
          } else {
            // case tab hierarchical search by type = cik
            fundArray = [
              {
                name: fund.name,
                acronym: fund.acronym,
                hierarchy: hierarchy,
                id: fund._id,
              },
            ];
          }
          if (!editView) {
            fund?.subs?.forEach((plan) => {
              fundArray.push({
                name: plan.name,
                acronym: plan.acronym,
                hierarchy: "plan",
                id: plan._id,
              });
            });
          }
          fundArray = removeDuplicates([...selectedFunds, ...fundArray]);
          setSelectedFunds(fundArray);
        } else {
          const planFunds = !fund.subs ? [] : fund.subs;

          if (!editView) {
            // case parent and children are dependent when one is checked
            let copySelectedFunds = selectedFunds.slice();
            // remove the  fund families and funds
            for (let planFund of planFunds) {
              copySelectedFunds = copySelectedFunds.filter(
                (c) => c.id !== planFund._id
              );
            }
            // remove the parent
            setSelectedFunds(
              copySelectedFunds.filter((c) => c.id !== fund._id)
            );
          } else {
            setSelectedFunds(selectedFunds.filter((c) => c.id !== fund._id));
          }
        }
        break;
      case "plan":
        if (checked) {
          let fundArray = [];
          if (manager)
            fundArray.push({
              name: manager.name,
              acronym: manager.acronym,
              hierarchy: "manager",
              id: manager._id,
            });
          if (cik) {
            // case tab alphabetical
            fundArray.push({
              name: cik.name,
              acronym: cik.acronym,
              hierarchy: "cik",
              id: cik._id,
            });
            fundArray.push({
              name: fund.name,
              acronym: fund.acronym,
              hierarchy: hierarchy,
              id: fund._id,
            });
          } else {
            // case tab hierarchical search by type = plan
            fundArray = [
              {
                name: fund.name,
                acronym: fund.acronym,
                hierarchy: hierarchy,
                id: fund._id,
              },
            ];
          }
          fundArray = removeDuplicates([...selectedFunds, ...fundArray]);
          setSelectedFunds(fundArray);
        } else {
          setSelectedFunds(
            selectedFunds.filter((selectedFund) => {
              return (
                selectedFund.name !== fund.name ||
                selectedFund.hierarchy !== hierarchy
              );
            })
          );
        }
        break;
      default:
        break;
    }
  };

  // clear the box with the funds in the home screen
  const handleClearAll = () => {
    setSelectedFunds([]);
    catchFunds([]);
  };

  resetSelectedFundsModal.current = () => {
    setKeyLetter("none");
    setSelectedFunds([]);
    catchFunds([]);
  };

  return (
    <BaseModal
      showModal={show}
      setShowModal={handleClose}
      title={"List of Investors"}
    >
      <BaseModal.Body>
        {loading && <Spinner />}
        <section className="modal-main-companies">
          <Tabs
            transition={false}
            activeKey={keyLetter}
            id="uncontrolled-tab-example"
            onSelect={handleSelect}
          >
            {letters.map((letter) => (
              <Tab key={letter} eventKey={letter} title={letter}>
                <TabResultListFunds
                  {...{
                    handleSubmit: handleSubmitAlphabetical,
                    innerRef: formikFilterRef,
                    idName: letter,
                    fundsCopy,
                    selectedFunds,
                    handleCheck,
                    editView,
                    statsView,
                    keyWord: keyLetter,
                    hierarchies,
                    types,
                    setNotFoundFundsMessage,
                    notFoundFundsMessage,
                    currentTypeSearched,
                  }}
                />
              </Tab>
            ))}
            <Tab eventKey="searchName" title="Search by name">
              <TabResultListFunds
                {...{
                  handleSubmit: handleSubmitHierarchical,
                  innerRef: formikFilterHierarchicalRef,
                  idName: "searchName",
                  fundsCopy: fundsCopyHierarchical,
                  selectedFunds,
                  handleCheck,
                  editView,
                  statsView,
                  keyWord: keyLetter,
                  hierarchies,
                  types,
                  setNotFoundFundsMessage,
                  notFoundFundsMessage,
                  currentTypeSearched,
                }}
              />
            </Tab>
          </Tabs>
        </section>
      </BaseModal.Body>
      <BaseModal.Footer>
        <div className="form-buttons-box">
          <Button
            variant="primary"
            className="flex-fill"
            onClick={() => {
              catchFunds(selectedFunds);
              handleClose();
            }}
          >
            {statsView && "Select Investors"}
            {!statsView && (editView ? "Search Investors" : "Save")}
          </Button>
          <Button
            variant="light"
            className="flex-fill"
            onClick={() => {
              handleClearAll();
            }}
            disabled={selectedFunds.length === 0 || keyLetter === "none"}
          >
            Clear
          </Button>
        </div>
      </BaseModal.Footer>
    </BaseModal>
  );
};

export default ModalFund;
