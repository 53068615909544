import {
  useState,
  useEffect,
  useRef,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import Button from "react-bootstrap/Button";
import { formatDate } from "../../utils/utils";
import renderCellExpand from "../utils/renderCellExpand";
import FileSaver from "file-saver";
import { generateCsv } from "../../utils/reqs";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LoadingContext from "../../contexts/LoadingContext";
import ModalMergeProposals from "./ModalMergeProposals";
import ModalEditProposals from "./ModalEditProposals";
import { BaseModal } from "../Layout/BaseModal";

/**
 * <p>Data grid displaying filtered proposals</p>
 * @param {Object} props
 * @param {Object} props.user - information of the logged user
 * @param {Array} props.proposals - proposals of the table
 * @param {function} props.setProposals - proposals of the table
 * @param {Array} props.pagination - pagination data from the proposals
 * @param {Array} props.selectedProposals - selected proposals in the data grid
 * @param {function} props.setSelectedProposals - state hook for selectedProposals
 * @param {boolean} props.editorMode - true if the view is /edit false if the view is home page
 * @param {boolean} props.resultsLoading - true if searchProposals is called and awaiting result, false i.o.c
 * @param {Object} props.formikRef - reference to form in side menu
 * @param {function} props.searchProposals - searches for paginated proposals
 * @param {boolean} props.isAdvancedSearch - true if side menu displays all form fields, false i.o.c
 * @param {function} props.setLoadingMessage - sets the message of the spinner
 * @param {Array} props.selectedProposalsPersistent - proposals selected aux
 * @returns rendered view of Results data grid
 * @author rafaelg5
 * @author valeriaxeleva
 * @author Medina192
 */
const Results = (
  {
    user,
    proposals,
    setProposals,
    pagination,
    selectedProposals,
    setSelectedProposals,
    editorMode,
    resultsLoading,
    formikRef,
    bodyQueryRef,
    searchProposals,
    isAdvancedSearch,
    setLoadingMessage,
    selectedProposalsPersistent,
  },
  ref
) => {
  const [showModalMergeProposal, setShowModalMergeProposal] = useState(false);
  const [proposalsToMerge, setProposalsToMerge] = useState([]);
  const [showModalEditProposal, setShowModalEditProposal] = useState(false);
  const [proposalToEdit, setProposalToEdit] = useState({
    description: "",
    subject: "",
    management: "",
    proponent: "",
  });
  const [errorsInProposalsToMerge, setErrorsInProposalsToMerge] = useState([]);

  const extractFinanciallyMaterialValue = (row) => {
    if (row.financiallyMaterial) {
      return "Yes";
    }
    return "No"
  };

  const columns = editorMode
    ? [
        {
          field: "company",
          headerName: "Company",
          sortable: false,
          flex: 1,
          minWidth: 250,
          renderCell: renderCellExpand,
        },
        {
          field: "industry",
          headerName: "Industry",
          width: 110,
          sortable: false,
          renderCell: ({ row, colDef }) =>
            renderCellExpand({ value: row?.industry, colDef }),
        },
        {
          field: "item",
          headerName: "Item",
          flex: 2,
          minWidth: 60,
          sortable: false,
          renderCell: renderCellExpand,
        },
        {
          field: "description",
          headerName: "Proposal",
          flex: 3,
          minWidth: 250,
          sortable: false,
          renderCell: renderCellExpand,
        },
        {
          field: "meetingDate",
          headerName: "Date",
          width: 100,
          sortable: false,
          renderCell: ({ value, colDef }) =>
            renderCellExpand({ value: formatDate(value), colDef }),
        },
        {
          field: "meetingType",
          headerName: "Type",
          sortable: false,
          width: 100,
          renderCell: renderCellExpand,
        },
        {
          field: "subjects",
          headerName: "Subject",
          width: 100,
          sortable: false,
          renderCell: ({ row, colDef }) =>
            renderCellExpand({
              value: row?.subjects?.subject,
              colDef,
            }),
          valueGetter: ({ row }) => row.subjects?.subject || "",
          valueSetter: ({ row }) => ({
            ...row,
            subjects: row.subjects?.subject || "",
          }),
        },
        {
          field: "priorityInvestor",
          headerName: "Highest Hierarchy Voter",
          minWidth: 250,
          sortable: false,
          renderCell: renderCellExpand,
        },
        {
          field: "managementRecommendation",
          headerName: "Management Recommendation",
          width: 150,
          sortable: false,
          renderCell: renderCellExpand,
        },
        {
          field: "proponent",
          headerName: "Proponent",
          width: 150,
          sortable: false,
          renderCell: renderCellExpand,
        },
        {
          field: "financiallyMaterial",
          headerName: "Financially Material",
          renderCell: ({ row, colDef }) =>
            renderCellExpand({
              value: extractFinanciallyMaterialValue(row),
              colDef,
            }),
          valueGetter: ({ row }) => extractFinanciallyMaterialValue(row),
          valueSetter: ({ row }) => ({
            ...row,
            financiallyMaterial: extractFinanciallyMaterialValue(row),
          }),
          width: 150,
          sortable: false,
        },
        {
          field: "_id",
          headerName: "Action",
          sortable: false,
          width: 100,
          renderCell: (params) => {
            const proposalMongo = params.row;
            return (
              <Button
                onClick={() => {
                  const templateProposalToEdit = {
                    user: user.email,
                    meetingId: proposalMongo._id,
                    description: proposalMongo.description,
                    itemNumber: proposalMongo.item,
                    management: proposalMongo?.managementRecommendation
                      ? proposalMongo.managementRecommendation
                      : "",
                    proponent: proposalMongo?.proponent
                      ? proposalMongo.proponent
                      : "",
                    index: proposalMongo.proposalIndex,
                  };
                  if (proposalMongo?.subjects) {
                    templateProposalToEdit.subject = proposalMongo.subjects;
                  }

                  setProposalToEdit(templateProposalToEdit);
                  setShowModalEditProposal(true);
                }}
              >
                Edit
              </Button>
            );
          },
        },
      ]
    : user ? [
      {
        field: "company",
        headerName: "Company",
        sortable: false,
        flex: 1,
        minWidth: 200,
        renderCell: renderCellExpand,
      },
      {
        field: "industry",
        headerName: "Industry",
        width: 110,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          renderCellExpand({ value: row?.industry, colDef }),
      },
      {
        field: "description",
        headerName: "Proposal",
        flex: 2,
        minWidth: 250,
        sortable: false,
        renderCell: renderCellExpand,
      },
      {
        field: "meetingDate",
        headerName: "Date",
        sortable: false,
        width: 100,
        renderCell: ({ value, colDef }) =>
          renderCellExpand({ value: formatDate(value), colDef }),
      },
      {
        field: "meetingType",
        headerName: "Type",
        sortable: false,
        width: 100,
        renderCell: renderCellExpand,
      },
      {
        field: "subjects",
        headerName: "Subject",
        width: 100,
        sortable: false,
        renderCell: ({ row, colDef }) =>
          renderCellExpand({
            value: row?.subjects?.subject,
            colDef,
          }),
        valueGetter: ({ row }) => row.subjects?.subject || "",
        valueSetter: ({ row }) => ({
          ...row,
          subjects: row.subjects?.subject || "",
        }),
      },
      {
        field: "managementRecommendation",
        headerName: "Management Recommendation",
        sortable: false,
        width: 100,
        renderCell: renderCellExpand,
      },
      {
        field: "proponent",
        headerName: "Proponent",
        width: 120,
        sortable: false,
        renderCell: renderCellExpand,
      },
      {
        field: "fund",
        headerName: "Investor",
        width: 200,
        sortable: false,
        renderCell: renderCellExpand,
      },
      {
        field: "voteDecision",
        headerName: "Vote Cast",
        width: 120,
        sortable: false,
        renderCell: renderCellExpand,
      },
      {
        field: "rationale",
        headerName: "Rationale",
        width: 100,
        sortable: false,
        renderCell: renderCellExpand,
      },
      {
        field: "financiallyMaterial",
        width: 100,
        headerName: "Financially Material",
        renderCell: ({ row, colDef }) =>
          renderCellExpand({
            value: extractFinanciallyMaterialValue(row),
            colDef,
          }),
        valueGetter: ({ row }) => extractFinanciallyMaterialValue(row),
        valueSetter: ({ row }) => ({
          ...row,
          financiallyMaterial: extractFinanciallyMaterialValue(row),
        }),
        sortable: false,
        style: { color: "red" },
      },
    ]:[
        {
          field: "company",
          headerName: "Company",
          sortable: false,
          flex: 1,
          minWidth: 200,
          renderCell: renderCellExpand,
        },
        {
          field: "industry",
          headerName: "Industry",
          width: 110,
          sortable: false,
          renderCell: ({ row, colDef }) =>
            renderCellExpand({ value: row?.industry, colDef }),
        },
        {
          field: "description",
          headerName: "Proposal",
          flex: 2,
          minWidth: 250,
          sortable: false,
          renderCell: renderCellExpand,
        },
        {
          field: "meetingDate",
          headerName: "Date",
          sortable: false,
          width: 100,
          renderCell: ({ value, colDef }) =>
            renderCellExpand({ value: formatDate(value), colDef }),
        },
        {
          field: "meetingType",
          headerName: "Type",
          sortable: false,
          width: 100,
          renderCell: renderCellExpand,
        },
        {
          field: "subjects",
          headerName: "Subject",
          width: 100,
          sortable: false,
          renderCell: ({ row, colDef }) =>
            renderCellExpand({
              value: row?.subjects?.subject,
              colDef,
            }),
          valueGetter: ({ row }) => row.subjects?.subject || "",
          valueSetter: ({ row }) => ({
            ...row,
            subjects: row.subjects?.subject || "",
          }),
        },
        {
          field: "fund",
          headerName: "Investor",
          width: 200,
          sortable: false,
          renderCell: renderCellExpand,
        },
        {
          field: "voteDecision",
          headerName: "Vote Cast",
          width: 120,
          sortable: false,
          renderCell: renderCellExpand,
        },
        {
          field: "rationale",
          headerName: "Rationale",
          width: 100,
          sortable: false,
          renderCell: renderCellExpand,
        },
      ];
  const [currentPage, setCurrentPage] = useState(0);

  const instantaneousCurrentPage = useRef(0);
  // Global context
  const [, setLoading] = useContext(LoadingContext);

  const downloadCSV = async () => {
    if (!user) {
      alert("Downloads are available to OxProx clients only. Please contact us on oxprox.org/contact if you are interested in learning more about our services.");
      return
    }
    const values = formikRef.current.values;
    const nonFalsyValues = Object.keys(values).filter((key) => {
      const cond1 = typeof values[key] === "string" && values[key] !== "";
      const cond2 = typeof values[key] === "object" && !!values[key].length;
      return cond1 || cond2;
    });
    const body = {};
    nonFalsyValues.forEach((key) => (body[key] = values[key]));
    if (body.keywords) body.keywords = body.keywords.split(" ");
    setLoadingMessage("Generating file...");
    setLoading(true);
    const res = await generateCsv(body);
    if (res?.size) {
      const csvData = new Blob([res], { type: "text/csv;charset=utf-8;" });
      FileSaver.saveAs(csvData, "proposals.csv");
      setLoading(false);
    } else {
      setLoading(false);
      alert("Something went wrong when trying to generate the csv file");
    }
  };

  useEffect(() => {
    if (pagination.page) {
      setCurrentPage(pagination.page - 1);
    }
  }, [pagination]);

  useEffect(() => {
    setTimeout(() => {
      const auxSelectedProposals = [];
      if (
        selectedProposalsPersistent.current[
          instantaneousCurrentPage.current
        ] !== undefined
      ) {
        for (const index of selectedProposalsPersistent.current[
          instantaneousCurrentPage.current
        ]) {
          auxSelectedProposals.push(index.index);
        }
        setSelectedProposals(auxSelectedProposals);
      } else {
        setSelectedProposals([]);
      }
    }, 0);
  }, [proposals, selectedProposalsPersistent, setSelectedProposals]);

  useEffect(() => {
    if (showModalEditProposal) setLoadingMessage("Updating proposal...");
    if (showModalMergeProposal) setLoadingMessage("Merging proposals...");
  }, [showModalEditProposal, showModalMergeProposal, setLoadingMessage]);

  const removeSpecial_Lowercase_extraSpaces_characters = (sentence) => {
    sentence = sentence.replace(/[^a-zA-Z0-9 ]/g, "");
    sentence = sentence.trim();
    sentence = sentence.toUpperCase();

    return sentence;
  };

  const validateProposalsToMergeConstraints = () => {
    if (proposalsToMerge.length < 2) {
      setErrorsInProposalsToMerge(["You must select at least two Proposals"]);
      return;
    }
    const errors = [];
    let proposalsHave_TheSameMeeting = true;
    let proposalsHave_TheSameDescription = true;
    const referenceWordToTest = removeSpecial_Lowercase_extraSpaces_characters(
      proposalsToMerge[0].description
    );
    for (const proposal of proposalsToMerge) {
      const wordToTest = removeSpecial_Lowercase_extraSpaces_characters(
        proposal.description
      );
      if (wordToTest !== referenceWordToTest)
        proposalsHave_TheSameDescription = false;

      if (proposal._id !== proposalsToMerge[0]._id)
        proposalsHave_TheSameMeeting = false;
    }

    if (!proposalsHave_TheSameDescription)
      errors.push(["The proposals must have the same Description"]);

    if (!proposalsHave_TheSameMeeting)
      errors.push([
        "The proposals must belong to the same Meeting, (Check that the dates are the same)",
      ]);

    setErrorsInProposalsToMerge(errors);
  };

  const updateSelectedData = () => {
    setSelectedProposals([]);
    setProposalsToMerge([]);
  };

  const handleMergeProposals = () => {
    validateProposalsToMergeConstraints();
    setShowModalMergeProposal(true);
  };

  useImperativeHandle(ref, () => ({
    handleMergeProposals,
    downloadCSV,
  }));

  const  CustomNoRowsOverlay = () => {
    return (
        <Box sx={{ padding: "12%" }}>
          <Typography component="p" variant="body1" sx={{textAlign:"center"}}>
            {formikRef.current?.dirty?
          <>
            No voting records matching the selected criteria were found.
            <br/>
            Try choosing other companies, industries or a broader meeting date range.
            </>
            :null}
            </Typography>
        </Box>
    )
  }

  return (
    <>
      <BaseModal
        showModal={showModalMergeProposal}
        setShowModal={setShowModalMergeProposal}
        title="Merge Proposals"
      >
        <BaseModal.Body>
          <ModalMergeProposals
            closeModal={() => {
              setShowModalMergeProposal(false);
              setErrorsInProposalsToMerge([]);
            }}
            formikRef={formikRef}
            searchProposals={searchProposals}
            page={currentPage}
            proposalsToMerge={proposalsToMerge.slice()}
            errorsInproposalsToMerge={errorsInProposalsToMerge}
            selectedProposals={selectedProposals.slice()}
            setSelectedProposals={setSelectedProposals}
            user={user}
            updateSelectedData={updateSelectedData}
            setLoading={setLoading}
          />
        </BaseModal.Body>
      </BaseModal>

      <BaseModal
        showModal={showModalEditProposal}
        setShowModal={setShowModalEditProposal}
        title="Edit Proposals"
      >
        <BaseModal.Body>
          <ModalEditProposals
            setShowModalEditProposal={setShowModalEditProposal}
            proposalToEdit={proposalToEdit}
            setProposalToEdit={setProposalToEdit}
            setLoading={setLoading}
            updateDataInCurrentProposals={(newData) => {
              setProposals(
                proposals.map((proposal, index) => {
                  if (
                    proposal.proposalIndex !== newData.index ||
                    proposal._id !== newData.meeting_id
                  )
                    return proposal;

                  proposal.description = newData.description;
                  proposal.item = newData.itemNumber
                  proposal.managementRecommendation = newData.management;
                  proposal.proponent = newData.proponent;
                  if (newData.subjects?.subject)
                    proposal.subjects = newData.subjects;
                  if (newData.subjects === "none") delete proposal.subjects;

                  return proposal;
                })
              );
            }}
          />
        </BaseModal.Body>
      </BaseModal>

      <Box sx={{ height: "70vh" }}>
        <DataGrid
          rows={proposals}
          columns={columns}
          components={{ NoRowsOverlay: CustomNoRowsOverlay }}
          pagination
          paginationMode="server"
          rowCount={pagination.totalDocs || 0}
          rowsPerPageOptions={[100]}
          pageSize={100}
          loading={resultsLoading}
          page={currentPage}
          onPageChange={async (newPage) => {
            instantaneousCurrentPage.current = newPage;

            const newPersistent = proposals.filter((item) => {
              return selectedProposals.includes(item.index);
            });
            selectedProposalsPersistent.current[currentPage] = newPersistent;
            let values;
            if (bodyQueryRef.current) {
              values = bodyQueryRef.current;
            } else {
              values = formikRef.current.values;
            }
            const nonFalsyValues = Object.keys(values).filter((key) => {
              const cond1 =
                typeof values[key] === "string" && values[key] !== "";
              const cond2 =
                typeof values[key] === "object" && !!values[key].length;
              return cond1 || cond2;
            });
            const body = {};
            nonFalsyValues.forEach((key) => (body[key] = values[key]));
            body.editMode = editorMode;
            body.page = newPage + 1;

            if (editorMode) {
              const { funds, ...rest } = body;
              await searchProposals(rest);
            } else if (isAdvancedSearch) await searchProposals(body);
            else {
              const {
                subjects,
                industries,
                meetingStartDate,
                meetingEndDate,
                companies,
                funds,
                editMode,
                page,
              } = body;
              await searchProposals({
                subjects,
                industries,
                meetingStartDate,
                meetingEndDate,
                companies,
                funds,
                editMode,
                page,
              });
            }
          }}
          getRowId={(row) => {
            return row.index;
            //return row.index ? row.index : row.index
            //return row._id
          }}
          checkboxSelection={editorMode}
          disableSelectionOnClick
          onSelectionModelChange={(newSelectionModel) => {
            setProposalsToMerge(
              newSelectionModel.map(
                (indexProposalSelected) =>
                  proposals[indexProposalSelected - currentPage * 100]
              )
            );
            setSelectedProposals(newSelectionModel);
          }}
          selectionModel={selectedProposals}
        />
      </Box>
    </>
  );
};

export default forwardRef(Results);
