import { Button } from 'react-bootstrap'
import { BaseModal } from "../Layout/BaseModal";
import { Box } from '@mui/material';
/**
 * Confirmation Popup Modal component to verify any user modification.
 * @param {Object} props
 * @param {Boolean} props.show - if the modal is shown or not
 * @param {Function} props.handleClose - function to close the modal
 * @param {Function} props.action - function to execute when the user confirms the action
 * @param {String} props.title - title of the modal
 * @param {String} props.body - body of the modal
 * @param {Boolean} props.footer - if the modal has a footer or not
 * @returns the rendered component
 * @author Raferu
 * @author valeriaxeleva
 */
export const ConfirmationModal = ({ show, handleClose, action, title, body, footer }) => {

    const clickConfirm = () => {
        action();
        handleClose();
    }

    return (
        <BaseModal showModal={show} setShowModal={handleClose} title={title}>
            <BaseModal.Body>
                <Box>{body}</Box>
            </BaseModal.Body>
            <BaseModal.Footer>
            {
                footer &&
                <Box>
                    <Button variant="secondary" onClick={()=>handleClose(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={clickConfirm}>
                        Confirm
                    </Button>
                </Box>
            }
            </BaseModal.Footer>
        </BaseModal>
    )
};