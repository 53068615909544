import React from 'react'
/**
 * Simple component for displaying disclaimer information about the application.
 * @see main req-2-1 IT 3
 * @author ValeriaG
 */
const Disclaimer = () => {
  return (
    <div className='container  pt-5'>
        <p className='font-weight-bold'>
            2022.07.12 – Temporary Disclaimer for upload partners
        </p>

        <p className='font-weight-bold'>
            Information (proxy voting records and company name) uploaded to the OxProx website is done on a
            voluntary basis and is provided for public information.  OxProx makes no representation as to the 
            veracity of the information and shall not be held liable if information is incorrect,
            misappropriated, or results in any liability or damage to the uploading institution. We strive to 
            keep the database and website secure but cannot be held responsible if data is compromised or ends
            up with malicious actors.
        </p>

        <p className='font-weight-bold'>
            We are in early stages of development (not a public website yet), and appreciate the goodwill shown
            by upload partners to help OxProx realize its vision of a free, easily accessible database of proxy
            voting records. We expect the appearance and functionality to change (improve!) as we continue
            development. We also expect to update this temporary disclaimer once a version is provided by our 
            lawyers.
        </p>

        <p className='font-weight-bold'>
            OxProx
        </p>
    </div>
  )
}

export default Disclaimer