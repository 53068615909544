import { HomeMainContent } from "./Layout/HomeMainContent";
import "./css/HomePages.scss";
import logo from "../assets/OxProx-Logo-White-and-Red.png";
import oxford from "../assets/OXFORD LOGOS.png";
import { ReactComponent as EarthIcon } from "../assets/earth.svg";
import { ReactComponent as MultipleIcon } from "../assets/multiple-users-silhouette.svg";
import { ReactComponent as NounBoardroomIcon } from "../assets/noun-boardroom.svg";
import { Box, Button, Grid, Typography } from "@mui/material";
import HomePage from "./Layout/HomePage";
import { styled } from "@mui/styles";
import ESGContent from "./Insights/ESGContent";
import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material/styles";

const FooterInformation = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.blue.deepDark,
  padding: "30px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
    padding: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "space-between",
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "18rem",
  height: "18rem",
  [theme.breakpoints.down("lg")]: {
    width: "14rem",
    height: "14rem",
  },
  [theme.breakpoints.down("md")]: {
    width: "14rem",
    height: "14rem",
  },
  [theme.breakpoints.down("sm")]: {
    width: "10rem",
    height: "10rem",
  },
  [theme.breakpoints.down("xs")]: {
    width: "10rem",
    height: "10rem",
  },
}));

const IconSVGSize = {
  width: {
    xs: "10rem",
    sm: "10rem",
    md: "11rem",
    lg: "12rem",
  },
  height: {
    xs: "10rem",
    sm: "10rem",
    md: "11rem",
    lg: "12rem",
  },
};
/**
 * <p>Main component for Insights page</p>
 * <p>Displays information about votes and proposals from the database separated by ESG subjects. </p>
 * <p>Each section has links to redirect to statistics or database searches related to each subject</p>
 * @param {Object} props
 * @returns rendered view of Insights component
 * @author milenexeleva
 */

const Insights = () => {
  const theme = useTheme();

  const ESGInfo = [
    {
      image: "patternNavy",
      title: "Environment",
      content: `Includes proposals related to themes such as Greenhouse 
            Gas Emissions, Air Quality, Energy Management, Water and 
            Wastewater Management, Waste and Hazardous Material 
            Management, and Other Ecological Impacts.`,
      icon: (
        <IconContainer bgcolor="none">
          {" "}
          <SvgIcon
            component={EarthIcon}
            viewBox="0 0 48.625 48.625"
            sx={{
              width: {
                xs: "10rem",
                sm: "10rem",
                md: "14rem",
                lg: "18rem",
              },
              height: {
                xs: "10rem",
                sm: "10rem",
                md: "14rem",
                lg: "18rem",
              },
              color: "gray.light",
            }}
          />{" "}
        </IconContainer>
      ),
    },
    {
      image: "patternBeige",
      title: "Social",
      content: `Includes proposals related to themes such as Labor Practices, 
      Human Rights, Diversity and Inclusion, Health and Safety, 
      Community Engagement, and Product Safety.`,
      icon: (
        <IconContainer bgcolor="primary.main">
          <SvgIcon
            component={MultipleIcon}
            viewBox="0 0 80.13 80.13"
            sx={{
              ...IconSVGSize,
              color: "gray.light",
            }}
          />
        </IconContainer>
      ),
    },
    {
      image: "patternRed",
      title: "Governance",
      content: `Includes the systems, processes, and structures that guide 
      corporate decision-making and accountability. It involves 
      Board Composition, Executive Compensation, Shareholder 
      Rights, Transparency, and Ethical Conduct.`,
      icon: (
        <IconContainer bgcolor="gray.light">
          <SvgIcon
            component={NounBoardroomIcon}
            viewBox="0 0 100 100"
            sx={{
              ...IconSVGSize,
              color: "red.dark",
            }}
          />
        </IconContainer>
      ),
    },
  ];

  return (
    <HomePage>
      <HomeMainContent title="Database">
        <HomeMainContent.Body>
          <Box
            sx={{
              height: "100%",
              color: "primary",
            }}
          >
            <Grid container className="insight-intro content-box-heroa">
              <Grid item xs={12} md={6}>
                <Box
                  className="insight-intro-div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent:"flex-start",
                    height: "100%",
                    padding: {
                      xs: "2rem 2rem",
                      sm: "3rem 4rem",
                      md: "3rem 5rem",
                      lg: "4rem 6rem",
                      xl: "4rem 7rem",
                    },
                  }}
                >
                  <Typography
                    id="article_titles"
                    variant="h1"
                    component="h2"
                    color={"primary.main"}
                    sx={{
                      fontFamily: "Poppins",
                      [theme.breakpoints.up("lg")]: {
                        fontSize: "3.375rem",
                      },
                      mb: "2rem"
                    }}
                  >
                    Graphs
                  </Typography>
                  <Typography
                    variant="h5"
                    component="p"
                    sx={{
                      fontWeight: 300,
                      mb:  "2.5rem"
                    }}
                    color={"primary.main"}
                  >
                    See a visual representation of how institutional investors
                    have voted on environmental, social, and governance (ESG)
                    proposals at an aggregate level over time.
                  </Typography>

                  <Button
                    href="/graphs"
                    color="secondary"
                    variant="contained"
                    className="btn-general"
                    sx={{
                      maxWidth: {sm:"70%", md: "50%"}
                    }}
                  >
                    View Graphs
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  className="insight-intro-div content-box patternBlue"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                    padding: {
                      xs: "2rem 2rem",
                      sm: "3rem 4rem",
                      md: "3rem 5rem",
                      lg: "4rem 6rem",
                      xl: "4rem 7rem",
                    },
                  }}
                >
                  <Typography
                    id="article_titles"
                    variant="h1"
                    component="h2"
                    color={"white.main"}
                    sx={{
                      fontFamily: "Poppins",
                      [theme.breakpoints.up("lg")]: {
                        fontSize: "3.375rem",
                      },
                      mb: "2rem"
                    }}
                  >
                    Voting Records
                  </Typography>
                  <Typography
                    variant="h5"
                    component="p"
                    sx={{
                      fontWeight: 300,
                      mb: "2.5rem"
                    }}
                  >
                    Search for investors' proxy voting records to see how they've
                    voted on proposals at a particular company, or within a
                    particular industry, or on particular ESG issues.
                  </Typography>
                  <Button
                    className="btn-general"
                    href="/voting-records"
                    color="secondary"
                    variant="contained"
                    sx={{
                      mb: {
                        xs: "1rem",
                        sm: "0",
                      },
                    maxWidth: {sm:"70%", md: "50%"},

                    }}
                  >
                    Search Votes
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box className="content-box patternRed">
              <Box
                className="img-shadow"
                sx={{
                  padding: {
                    xs: "2rem 2rem",
                    sm: "3rem 4rem",
                    md: "3rem 5rem",
                    lg: "4rem 6rem",
                    xl: "4rem 7rem",
                  },
                }}
              >
                <Typography
                  variant="h3"
                  component="p"
                  sx={{
                    fontWeight: 400,
                  }}
                  align="center"
                >
                  The OxProx database enables everyone to easily access,
                  analyze, and compare the proxy voting records of institutional
                  investors in one convenient location.
                </Typography>
              </Box>
            </Box>
            {ESGInfo?.map((Info, index) => {
              return (
                <ESGContent
                  index={index}
                  key={Info.title}
                  image={Info.image}
                  title={Info.title}
                  content={Info.content}
                  icon={Info.icon}
                />
              );
            })}
            <FooterInformation>
              <Box display="flex" alignItems="center">
                <Box
                  component="img"
                  alt="Oxprox Logo"
                  src={logo}
                  sx={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "100px",
                    display: "block",
                  }}
                />
                <Typography
                  sx={{
                    maxWidth: { xs: "120px", md: "100%" },
                    margin: { xs: "0 20px", md: "0 40px" },
                    padding: {
                      xs: "1rem",
                      sm: "2rem 2rem",
                      md: "40px 0 40px 40px",
                    },
                    borderLeft: `2px solid ${theme.palette.white.main}`,
                  }}
                  component="p"
                >
                  Amplifying Investor Stewardship
                </Typography>
              </Box>
              <Box
                component="img"
                alt="Oxford Logos"
                src={oxford}
                sx={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "200px",
                  display: "block",
                }}
              />
            </FooterInformation>
          </Box>
        </HomeMainContent.Body>
      </HomeMainContent>
    </HomePage>
  );
};

export default Insights;
