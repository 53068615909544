import React, { Children } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../css/modal.scss";
import { Typography } from "@mui/material";

/**
 * Base modal implemented with MUI compoentes
 * @param {Object} props
 * @param {string} props.title - Title or principal message of the modal. Can be empty.
 * @param {node} props.children - Children elements to be rendered on the modal.
 * @param {boolean} props.showModal - True if the modal will display, false i.o.c
 * @param {function} props.setShowModal - Function to change the value of showModal
 * @returns the rendered component
 * @author valeriaxeleva
 */

const Body = () => null;
const Footer = () => null;

export const BaseModal = ({ title, children, showModal, setShowModal }) => {
  children = Children.toArray(children);
  const body = children.find((el) => el.type === Body);
  const footer = children.find((el) => el.type === Footer);

  /**
   * Changes the state of showModal to false
   * @param {Object} event - Event object
   * @param {string} reason - Reason of the event
   * @author valeriaxeleva
   */
  const handleModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleModal}>
      <Fade in={showModal}>
        <Box className="edit_modal">
          <IconButton
            aria-label="close modal"
            onClick={handleModal}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
          <div style={{ height: "90%" }}>
          <Typography
                  id="modal_title"
                  variant="h4"
                  component="h3"
                >
                  {title}
                </Typography>
            <Box className="edit_modal-content">
              {body ? body.props.children : null}
            </Box>
          </div>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            {footer ? footer.props.children : null}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

BaseModal.Body = Body;
BaseModal.Footer = Footer;
