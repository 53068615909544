import React, { useState, useRef } from "react";
import countryList from "react-select-country-list";
import { Button } from "react-bootstrap";
import { Form as FormFormik } from "formik";
import { default as CompanyModal } from "../SearchModals/ModalCompanies";
import {
  FormikTextInput as TextField,
  FormikSelect as Select,
  FormikCheckBox as CheckBox,
} from "../utils/forms";
import { getCompanyIndexList } from "../../utils/utils";

/**
 * Component that renders the form to search companies
 * @param {Object} props
 * @param {function} props.resetForm - Formik's funtion to reset the form values
 * @param {boolean} props.dirty - Formik's attribute to check if the form is dirty
 * @param {isSubmitting} props.isSubmitting - Formik's attribute to check if the form is currently submitting
 * @param {Object} props.extraProps - Extra props the children components may need.
 * @param {function} props.setFieldValue - Formik's function to set the value of a field
 * @param {function} props.setFieldTouched - Formik's function to set the field "touched"
 * @returns the rendered component
 * @author valeriaxeleva
 */
const SearchForm = ({
  resetForm,
  dirty,
  isSubmitting,
  extraProps,
  setFieldValue,
  setFieldTouched,
}) => {
  const { searchCompanies } = extraProps;
  const countries = countryList().getData();
  const companyIndexes = getCompanyIndexList();
  const [showCompSearchModal, setShowCompSearchModal] = useState(false);

  //For Search Companies Modal
  const [createNewInstanceModal, setCreateNewInstanceModal] = useState(0);
  const selectedCompaniesFromModal = useRef("");
  const resetSelectedCompaniesinModal = useRef(null);

  /**
   * Retrieves the companies of the Modal company Selector <Modal/>
   * @param {Object} selectedCompanies
   * @author Medina192
   */
  const getCompaniesSelectedInTheModal = (selectedCompanies) => {
    const companiesNames = selectedCompanies.map((company) => company.name);
    const values = {
      names: companiesNames,
    };
    setCreateNewInstanceModal((c) => c + 1);
    searchCompanies(values);
  };

  let className = `sideForm`;

  const checkEmptyField = (e, setFieldValue, field) => {
    if (e.target.value === "default") {
      setFieldValue(field, "");
      setFieldTouched(field, false, false);
    }
  };

  return (
    <>
      <FormFormik className={className} id="search-company-form">
        <Button
          variant="link"
          type="button"
          size="sm"
          className="advanced_search_btn"
          onClick={() => setShowCompSearchModal(true)}
          disabled={showCompSearchModal}
        >
          Advanced Search
        </Button>

        <TextField name="name" type="text" label="Name" />
        <CheckBox name="exactMatch" label="Search name by exact match" />
        <TextField name="ISINs" type="text" label="ISIN" />

        <Select
          label="Index"
          name="index"
          onClick={(e) => checkEmptyField(e, setFieldValue, "index")}
        >
          <option value="default" key="default">
            --None
          </option>
          {companyIndexes.map((indexName) => (
            <option value={indexName} key={indexName}>
              {indexName}
            </option>
          ))}
        </Select>

        <Select
          label="Country"
          name="country"
          onClick={(e) => checkEmptyField(e, setFieldValue, "country")}
        >
          <option value="default" key="default">
            --None
          </option>
          {countries.map((c, index) => (
            <option value={c.label} key={c.label}>
              {c.label}
            </option>
          ))}
        </Select>

        <div className="form-buttons-box">
          <Button type="submit" disabled={!dirty || isSubmitting}>
            Apply filters
          </Button>
          <Button
            type="reset"
            variant="secondary"
            disabled={!dirty}
            onClick={resetForm}
          >
            Clear
          </Button>
        </div>
      </FormFormik>

      <CompanyModal
        key={createNewInstanceModal}
        setCreateNewInstanceModal={setCreateNewInstanceModal}
        show={showCompSearchModal}
        closeWhenAddCompany={true}
        catchCompanies={(selectedCompanies) => {
          if (selectedCompanies.length > 0) {
            getCompaniesSelectedInTheModal(selectedCompanies);
            selectedCompaniesFromModal.current = selectedCompanies;
          }
        }}
        dialogClassName="modal-80w"
        handleClose={() => setShowCompSearchModal(false)}
        resetSelectedCompaniesinModal={resetSelectedCompaniesinModal}
      />
    </>
  );
};

export default SearchForm;
