import React, { useState, useRef } from "react";
import countryList from "react-select-country-list";
import { Button } from "react-bootstrap";
import { Form as FormFormik, ErrorMessage } from "formik";
import { default as FundModalWithTabs } from "../SearchModals/ModalFund";
import {
  FormikTextInput,
  FormikSelect,
  FormikRadioButton,
} from "../utils/forms";
import { FormControl, RadioGroup, Typography } from "@mui/material";

/**
 * Component that renders the form to search funds
 * @param {Object} props
 * @param {function} props.resetForm - Formik's funtion to reset the form values
 * @param {boolean} props.dirty - Formik's attribute to check if the form is dirty
 * @param {isSubmitting} props.isSubmitting - Formik's attribute to check if the form is currently submitting
 * @param {Object} props.extraProps - Extra props the children components may need.
 * @param {function} props.setFieldValue - Formik's function to set the value of a field
 * @param {function} props.setFieldTouched - Formik's function to set the field "touched"
 * @returns the rendered component
 * @author milenexeleva
 */
const SearchForm = ({
  resetForm,
  dirty,
  isSubmitting,
  extraProps,
  setFieldValue,
  setFieldTouched,
}) => {
  const { searchFundsById } = extraProps;
  const countries = countryList().getData();
  const [showFundModalWithTabs, setShowFundModalWithTabs] = useState(false);

  //For Search Funds Modal
  const resetSelectedFundsinModal = useRef(null);

  let className = `sideForm`;

  const checkEmptyCountry = (e, setFieldValue, setFieldTouched) => {
    if (e.target.value === "default") {
      setFieldValue("country", "");
      setFieldTouched("country", false, false);
    }
  };

  return (
    <>
      <FormFormik className={className}>
        <Button
          variant="link"
          type="button"
          size="sm"
          className="advanced_search_btn"
          onClick={() => setShowFundModalWithTabs(true)}
          disabled={showFundModalWithTabs}
        >
          Advanced Search
        </Button>

        <FormikTextInput
          label={"Name"}
          name="name"
          autoComplete="section-fund organization"
          type="text"
        />
        <ErrorMessage name="fundname" />
        <FormikTextInput label={"Acronym"} name="acronym" type="text" />
        <ErrorMessage name="acronym" />
        <FormControl sx={{ width: "100%" }}>
          <Typography
            component="label"
            className="menuForm-label"
            sx={{
              fontWeight: 500,
            }}
          >
            Investor Type
          </Typography>
          <RadioGroup row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                margin:"5px 0px 5px 10px",
              }}
            >
              <FormikRadioButton
                label={"Asset Owner"}
                name={"type"}
                value="pension"
              />
              <FormikRadioButton
                label={"Asset Manager"}
                name={"type"}
                value="investment"
              />
            </div>
          </RadioGroup>
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <Typography
            component="label"
            className="menuForm-label"
            sx={{
              fontWeight: 500,
            }}
          >
            Hierarchy
          </Typography>
          <RadioGroup row>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                width: "100%",
                margin:"5px 0px 5px 10px",
              }}
            >
              <FormikRadioButton
                label={"Parent Fund"}
                name={"hierarchy"}
                value="manager"
                color={"white.main"}
              />
              <FormikRadioButton
                label={"Fund Family"}
                name={"hierarchy"}
                value="cik"
                color={"white.main"}
              />
              <FormikRadioButton
                label={"Fund"}
                name={"hierarchy"}
                value="plan"
                color={"white.main"}
              />
            </div>
          </RadioGroup>
        </FormControl>
        <FormikSelect
          label={"Country"}
          name="country"
          style={{ marginBottom: "5px" }}
          onClick={(e) => checkEmptyCountry(e, setFieldValue, setFieldTouched)}
        >
          <option value="default" key="default">
            --None
          </option>
          {countries.map((categorie, index) => (
            <option value={categorie.label} key={categorie.label}>
              {categorie.label}
            </option>
          ))}
        </FormikSelect>
        <div className="d-flex form-buttons-box">
          <Button
            type="submit"
            disabled={isSubmitting}
            className="mt-1 ml-1 flex-fill"
          >
            Search
          </Button>
          <Button
            type="reset"
            variant="secondary"
            className="mt-1 ml-1 flex-fill"
            disabled={!dirty}
            onClick={resetForm}
          >
            Clear
          </Button>
        </div>
      </FormFormik>
      <FundModalWithTabs
        show={showFundModalWithTabs}
        catchFunds={(selecteds) => {
          searchFundsById(selecteds);
        }}
        editView={true}
        handleClose={() => setShowFundModalWithTabs(false)}
        resetSelectedFundsModal={resetSelectedFundsinModal}
      />
    </>
  );
};

export default SearchForm;
