import { Formik } from "formik";

/**
 * Table to display fetched data using MUI components with server pagination, based on the Editor + Logs OxProx pattern.
 * @param {Object} props
 * @param {function} props.searchFunction - The function that will search the data if the user changes the page.
 * @param {boolean} props.showLogsTable - True if the visible view is the Logs one, false if is the Editor view. Note the columns, rows and searchFunction will change according of this value.
 * @param {function} props.searchForm - Component for the companies search form
 * @param {function} props.logsForm - Component for the logs search form
 * @param {Object} props.extraProps - Extra props that will be used in the children components
 * @param {Object} props.initValues - Initial values for the companies search form
 * @param {Object} props.yupEschema - Yup validation for the companies search form
 * @param {Object} props.initValuesLogs - Initial values for the logs search form
 * @param {Object[]} props.selectionModel - An array that will store the selectd rows in the table
 * @param {node} props.children - Children components to be rendered
 * @returns the rendered component
 * @author valeriaxeleva
 */
export const SideForm = ({
  searchFunction,
  showLogsTable,
  searchForm: SearchForm,
  logsForm: LogsForm,
  extraProps,
  yupEschema,
  initValues,
  isMobile,
  handleDrawerClose,
  initValuesLogs,
  children,
}) => {
  const handleSubmit = (vals, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);
    }, 400);
    if (isMobile) {
      handleDrawerClose();
    }
    searchFunction(vals);
  };

  return (
    <>
      {showLogsTable ? (
        <Formik initialValues={initValuesLogs} onSubmit={handleSubmit}>
          {({ resetForm, dirty }) => (
            <>
              <LogsForm {...{ resetForm, dirty }} />
            </>
          )}
        </Formik>
      ) : (
        <>
          <Formik
            initialValues={initValues}
            validationSchema={yupEschema}
            onSubmit={handleSubmit}
          >
            {({
              resetForm,
              dirty,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => (
              <>
                <SearchForm
                  {...{
                    extraProps,
                    resetForm,
                    dirty,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </>
            )}
          </Formik>
          {children}
        </>
      )}
    </>
  );
};

export default SideForm;
