import { useState, useRef, useEffect, React } from "react";
import "./css/Home.scss";
import UploadFilesForm from "./UploadFiles/UploadFilesForm.jsx";
import "./css/Layout.scss";
import { Button } from "@mui/material";
import "./css/modal.scss";
import { auth } from "../firebase";
import { default as Spinner } from "./utils/SpinnerFull";
import { getUploadLogs, getUserByEmail } from "../utils/reqs";
import * as Yup from "yup";
import { useAuthState } from "react-firebase-hooks/auth";
import "./css/DataTable.scss";
import PswdConfirmation from "./UploadFiles/PswdConfirmation";
import Page from "./Layout/Page";
import LogsForm from "./UploadFiles/LogsForm";
import Side from "./Layout/Side";
import { MainContent } from "./Layout/MainContent";
import UploadsTable from "./UploadFiles/UploadsTable";
import SideForm from "./Layout/SideForm";

/**
 * View component for file uploading. It shows an upload form that requires password confirmation
 * to proceed with the api function that uploads new data, referenced by submitUploadFilesForm.
 * In show logs table, it is possible to search for upload logs and display them on a data table.
 * Sidebar has a form with an input for each field.
 * @see main req[1]-[1]
 * @author milenexeleva
 */
const UploadFiles = () => {
  const [showTable, setShowTable] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [buttonMessage, setButtonMessage] = useState("Show Logs Table");
  const [user] = useAuthState(auth);
  const customClaims = user
    ? JSON.parse(user?.reloadUserInfo?.customAttributes)
    : null;
  const { user_type } = customClaims;
  const userRoles = user ? customClaims?.role : [];
  const username = user?.email;
  const isFund =
    customClaims?.user_type === "Pension" ||
    customClaims?.user_type === "Asset Manager";
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const submitUploadFilesForm = useRef(null);
  const [userDataPension, setUserDataPension] = useState({});
  const formikMainSearch = useRef();

  const handleShowTable = () => {
    setShowTable(!showTable);
  };

  const getLogs = async (values, page = 1) => {
    setLoading(true);
    formikMainSearch.current = values;
    const { uploads, totalDocs } = await getUploadLogs(page, values, 100);
    setLogs(uploads);
    setRowCount(totalDocs);
    setLoading(false);
  };

  const initValuesLogs = {
    manager: "",
    uploadedBy: !userRoles.includes("Admin") ? username : "",
    cleanedBy: "",
    created_at: "",
    created_at_between: "",
    filename: "",
    created_at_test: "",
  };

  useEffect(() => {
    if (user_type === "Pension") {
      setLoadingUser(true);
      getUserByEmail(user.email)
        .then((res) => {
          setUserDataPension(res.reference);
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => setLoadingUser(false));
    }
  }, [user, user_type]);

  useEffect(() => {
    showTable
      ? setButtonMessage("Show Upload Form")
      : setButtonMessage("Show Logs Table");
  }, [showTable]);

  const formsProps = {
    searchFunction: showTable ? getLogs : () => {},
    searchForm: () => null,
    logsForm: () => LogsForm({ isFund, userRoles }),
    yupEschema: Yup.object({}),
    showLogsTable: showTable,
    initValues: initValuesLogs,
    initValuesLogs,
  };

  return (
    <Page single={!showTable}>
      {!showTable && uploadLoading && <Spinner message="Uploading file..." />}
      {loadingUser && <Spinner message="Loading..." />}
      {showTable && (
        <Side>
          <SideForm {...formsProps} />
        </Side>
      )}
      <MainContent title={"Upload Files"} hasSideBar={showTable ? true : false} colorTitle={"red.dark"}>
        <MainContent.Actions>
          <Button onClick={handleShowTable}>{buttonMessage}</Button>
        </MainContent.Actions>
        <MainContent.Body>
          {showTable ? (
            <UploadsTable
              rows={logs}
              loading={loading}
              isFund={isFund}
              setRows={setLogs}
              rowsCount={rowCount}
              searchFunction={getLogs}
              formikMainSearch={formikMainSearch}
            />
          ) : (
            <UploadFilesForm
              submitUploadFilesForm={submitUploadFilesForm}
              setLoading={setUploadLoading}
              userDataPension={userDataPension}
              isFund={isFund}
              username={username}
              displayName={user?.displayName}
              setShowPasswordModal={setShowPasswordModal}
            />
          )}
          <PswdConfirmation
            user={user}
            showPasswordModal={showPasswordModal}
            setShowPasswordModal={setShowPasswordModal}
            submitUploadFilesForm={submitUploadFilesForm}
          />
        </MainContent.Body>
      </MainContent>
    </Page>
  );
};

export default UploadFiles;
