import { Link } from "react-router-dom";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * <p>Component for 404 page.</p>
 * @returns rendered view of the component
 * @author valeriaxeleva
 */
const NotFound = () => {
  return (
    <div className="mx-auto w-50 text-center" style={{ marginTop: "120px" }}>
      <h1 className="text-secondary robot">
        <FontAwesomeIcon icon={faRobot} />
      </h1>
      <h1 className="error404">404</h1>
      <h2>Oops! Page not found</h2>
      <Link to="/">Go to Home </Link>
    </div>
  );
};

export default NotFound;
