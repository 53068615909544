import { useState, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Button from "react-bootstrap/Button";
import { updateSubjects } from "../../utils/reqs";
import { SubjectUpdateConfirmation } from "./SubjectUpdateConfirmation";
import { default as Spinner } from "../utils/SpinnerFull";
import { useSASBLists } from "../../contexts/subjectsProvider";
/**
 * <p>Accordion component for editing proposals' subjects</p>
 * @param {Object} props
 * @param {Array} props.selectedProposals - selected proposals in the data grid
 * @param {Object} props.persistent - persistent object to store selected proposals
 * @param {function} props.setProposals - sets proposals of the table
 * @param {Array} props.proposals - proposals of the table
 * @returns rendered view of proposals' subjects accordion
 * @author rafaelg5
 */
export const ProposalSubjectsAccordion = ({
  selectedProposals,
  persistent,
  proposals,
  setProposals,
  user,
}) => {
  // Global context
  const [loading, setLoading] = useState(false);
  const { SASBProposalsList } = useSASBLists();

  // Values
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [showChildren, setShowChildren] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [updateType, setUpdateType] = useState(null);

  const allProposalsSelected = persistent.current.flat(1);

  const handleOnSelectSubject = useCallback((value) => {
    const [child, parent] = value.split("_");
    setSelectedSubject({ subject: child, parent });
  }, []);

  const updateProposalSubjects = useCallback(async () => {
    setLoading(true);
    setUpdateType(null);
    const updatedProposals = proposals.map((proposal, index) => {
      if (selectedProposals.includes(proposal.index)) {
        return {
          ...proposal,
          subjects: !selectedSubject ? {} : selectedSubject,
          oldSubject: proposal.subjects,
        };
      }
      return proposal;
    });

    const proposalsToSend = allProposalsSelected.map((item) => {
      return { ...item, subjects: !selectedSubject ? {} : selectedSubject };
    });
    const proposalsToSendCurrentPage = updatedProposals.filter(
      (item, index) => {
        return selectedProposals.includes(item.index);
      }
    );

    let joined = proposalsToSend.concat(proposalsToSendCurrentPage);

    const uniqueProposals = new Set(joined.map((e) => JSON.stringify(e)));

    const finalProposals = Array.from(uniqueProposals).map((e) =>
      JSON.parse(e)
    );

    await updateSubjects(finalProposals, user.email)
      .then(() => {
        setProposals(updatedProposals);
        persistent.current = [];
      })
      .finally(() => {
        setLoading(false);
      });
    //Disabling the exhaustive-deps rule because we don't want to update the function when most of the varialbes used here change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject, proposals, selectedProposals]);

  useEffect(() => {
    if (SASBProposalsList.length > 0)
      setShowChildren(SASBProposalsList.map(() => false));
  }, [SASBProposalsList]);

  useEffect(() => {
    if (!updateType) setShowModal(false);
    else setShowModal(true);
  }, [updateType]);

  const handleConfirmation = (type) => {
    if (type === "delete") setSelectedSubject(null);
    setUpdateType(type);
  };

  return (
    <>
      {loading && <Spinner message={"Updating proposals"} />}

      <SubjectUpdateConfirmation
        {...{
          selectedProposals,
          selectedSubject,
          setUpdateType,
          updateProposalSubjects,
          allProposalsSelected,
          showModal,
          setShowModal,
        }}
      />

      <Accordion className="home-menu-dropdown py-2 mt-4" expanded disabled>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="p-0 d-flex"
        >
          <FontAwesomeIcon icon={faEdit} size="sm" className="mr-1 mt-1" />
          <b>Assign subject to proposals</b>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <div className="fondos assign-subjects">
            <div className="dropdown-container">
              {SASBProposalsList.map((parentSubject, index) => (
                <div key={parentSubject.name} className="d-flex">
                  <div className="subjects_advanced w-100">
                    <div
                      className="sub-drop sub1"
                      onClick={() =>
                        setShowChildren(
                          showChildren.map((active, curr) =>
                            curr === index ? !showChildren[index] : active
                          )
                        )
                      }
                    >
                      <div className="d-flex align-items-center">
                        <label className="small-font sub-label">
                          {parentSubject.name}
                        </label>
                      </div>
                      <span>
                        <FontAwesomeIcon icon={faAngleDown} />
                      </span>
                    </div>
                    {showChildren[index] && (
                      <div className="show_industries">
                        <ul className="sub_funds my-2">
                          {parentSubject.subs.map((childSubject, index) => (
                            <li key={`${childSubject.replace(" ", "-")}`}>
                              <input
                                type="radio"
                                id={`${index}_${childSubject}`}
                                className="names_funds"
                                name="selectedSubject"
                                checked={
                                  selectedSubject?.subject === childSubject
                                }
                                value={`${childSubject}_${parentSubject.name}`}
                                onChange={(event) =>
                                  handleOnSelectSubject(event.target.value)
                                }
                              />
                              <label
                                className="small-font"
                                htmlFor={`${index}_${childSubject}`}
                              >
                                {childSubject}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Button
            block
            size="sm"
            variant="success"
            disabled={!selectedSubject}
            className="mt-2"
            onClick={() => handleConfirmation("update")}
          >
            Add changes
          </Button>
          <Button
            block
            size="sm"
            variant="danger"
            disabled={selectedProposals.length === 0}
            className="mt-2"
            onClick={() => handleConfirmation("delete")}
          >
            Remove subjects
          </Button>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ProposalSubjectsAccordion;
