import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";
import {
  getVoteProportionCsv,
  getVoteProportionAll,
} from "../../utils/reqs.js";

import { a11yProps } from "../../utils/utils.js";
import FileSaver from "file-saver";
import SpinnerFull from "../utils/SpinnerFull";
import { DataGrid } from "@mui/x-data-grid";
import renderCellExpand from "../utils/renderCellExpand";
import { formatDate } from "../../utils/utils";
import { CustomTooltip } from "./CustomTooltip";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../utils/TabPanel";
import { FormControlLabel, Switch } from "@mui/material";
import InfoSnackbar from "../utils/InfoSnackbar.jsx";

/**
 * Component that generates the statistics graphs of the results of the split votes, and also display that votes on a <table className=""></table>
 * @param {Object} props
 * @param {Object[]} props.funds - Array containing the information of the funds (names and ids)
 * @param {Object[]} props.showKeyword - True if the form should display the keywords input, false for displaying the company modal instead.
 * @param {Object} props.esgcheck - The object containing the ESG stats, used just to reset these views every time the esg data is updated.
 * @returns the rendered component
 * @author valeriaxeleva
 */
const VotesView = ({
  esgcheck,
  csvVotesParams,
  totalVotesFund,
  setTotalVotesFund,
  loadingChart,
  setLoadingChart,
  voteProportionAllLoading,
  setVoteProportionAllLoading,
  reAsignIds,
  proposalsVoteAll,
  setProposalsVoteAll,
  user
}) => {
  const [activeFund, setActiveFund] = useState(0);
  const [showAgainst, setShowAgainst] = useState(false);
  const [openInfoSnackbar, setOpenInfoSnackbar] = useState(false);

  useEffect(() => {
    //reset graphs and tables when esg statistics are changed.
    setTotalVotesFund([]);
    setProposalsVoteAll([]);
  }, [esgcheck]);


  //TO DO adapt to new API to support multiple funds
  const getVotesCsv = () => {
    setLoadingChart(true);
    getVoteProportionCsv(csvVotesParams)
      .then((res) => {
        const csvData = new Blob([res], { type: "text/csv; charset=utf-8;" });
        let nameCsvFile = "investor_";
        if (csvVotesParams.startDate && csvVotesParams.endDate)
          nameCsvFile +=
            csvVotesParams.startDate + "-" + csvVotesParams.endDate;
        else if (csvVotesParams.startDate)
          nameCsvFile += csvVotesParams.startDate + "-currently";
        else if (csvVotesParams.endDate)
          nameCsvFile += "until-" + csvVotesParams.endDate;
        else nameCsvFile += "All";

        nameCsvFile += "_votes.csv";
        FileSaver.saveAs(csvData, nameCsvFile);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingChart(false);
      });
  };

  const columns = [
    {
      field: "Company",
      headerName: "Company",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: renderCellExpand,
    },
    {
      field: "Industry",
      headerName: "Industry",
      width: 100,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "Proposal",
      headerName: "Proposal",
      flex: 2,
      minWidth: 250,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "Date",
      headerName: "Date",
      sortable: false,
      width: 100,
      renderCell: ({ value, colDef }) =>
        renderCellExpand({ value: formatDate(value), colDef }),
    },
    {
      field: "ManagementRecommendation",
      headerName: "Management Recommendation",
      sortable: false,
      width: 100,
      renderCell: renderCellExpand,
    },
    {
      field: "Proponent",
      headerName: "Proponent",
      width: 120,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "Investor",
      headerName: "Investor",
      width: 200,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "VoteSummary",
      headerName: "Vote Cast",
      width: 120,
      sortable: false,
      renderCell: renderCellExpand,
    },
  ];

  const handleFundTable = (event, newValue) => {
    setActiveFund(newValue);
  };

  return (
    <>
      {loadingChart && <SpinnerFull message="Generating graphs" />}

      <FormControlLabel
        value={showAgainst}
        onChange={() => setShowAgainst(!showAgainst)}
        control={<Switch color="primary" />}
        label="Show against/other votes"
        labelPlacement="start"
      />
      <Grid
        container
        m={0}
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        sx={{ display: { xs: "block", md: "flex" } }}
      >
        {totalVotesFund.map((stats, i) => {
          return (
            <Grid item xs={6} sx={{ width: "100%", maxWidth: "100%" }} key={i}>
              {stats.statistics.length > 0 ? (
                <section>
                  <h4 style={{ textalign: "center" }}>{stats?.name}</h4>
                  <ResponsiveContainer width={"100%"} height={300}>
                    <LineChart
                      data={stats?.statistics}
                      margin={{ top: 20, right: 20, left: -40, bottom: 5 }}
                    >
                      <Line
                        strokeWidth={3}
                        type="monotone"
                        dataKey="forPercentage"
                        stroke="#1C652F"
                      />
                      {showAgainst && (
                        <>
                          <Line
                            strokeWidth={3}
                            type="monotone"
                            dataKey="againstPercentage"
                            stroke="#952F2E"
                            strokeDasharray="15 8"
                          />
                          <Line
                            strokeWidth={3}
                            type="monotone"
                            dataKey="otherPercentage"
                            stroke="#68257E"
                            strokeDasharray="3 3"
                          />
                        </>
                      )}
                      <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                      <Legend wrapperStyle={{ width: "100%", left: 5 }} />
                      <XAxis dataKey="year" />
                      <YAxis
                        width={120}
                        tickSize={8}
                        label={{
                          value: `Percentage votes results`,
                          angle: -90,
                          position: "center",
                          fontSize: 15,
                        }}
                        tickFormatter={(tick) => {
                          return `${tick}%`;
                        }}
                        ticks={[0, 20, 40, 60, 80, 100]}
                      />
                      <Tooltip content={<CustomTooltip />} />
                    </LineChart>
                  </ResponsiveContainer>
                </section>
              ) : (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p>{stats?.message}</p>
                </Box>
              )}
            </Grid>
          );
        })}
      </Grid>
      {proposalsVoteAll.filter((stats) => stats.proposals.length > 0).length >
        0 && (
        <Box
          mt={5}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: {
              xs: "column-reverse",
              sm: "row",
            },
            gap: {
              xs: "20px",
              sm: 0,
            },
          }}
        >
          <Tabs
            value={activeFund}
            onChange={handleFundTable}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              maxWidth: {
                xs: "100%",
                sm: "90%",
              },
            }}
          >
            {proposalsVoteAll
              .filter((stats) => stats.proposals.length > 0)
              .map((stats, i) => {
                return (
                  <Tab
                    label={stats.proposals[0]?.Investor}
                    {...a11yProps(i, "tab-header")}
                    sx={{
                      width: {
                        xs: "20%",
                        sm: "25%",
                      },
                    }}
                  />
                );
              })}
          </Tabs>
          {/*<Button
            style={{
              border: "none",
              whiteSpace: "nowrap",
              height: "max-content",
              boxShadow: "0px 5px 25px rgba(0, 0, 0, 0.25)",
              borderRadius: "50px",
              backgroundColor: "#002147",
            }}
            onClick={user ? getVotesCsv : () => setOpenInfoSnackbar(true)}
          >
            Download CSV
          </Button>
          <InfoSnackbar
          position={"topCenter"}
            openInfoSnackbar={openInfoSnackbar}
            setOpenInfoSnackbar={setOpenInfoSnackbar}
            snackbarText={
              "Downloads are available to OxProx clients only. Please contact us if you are interested in learning more about our services."
            }
          />*/}
        </Box>
      )}
      <Box sx={{ width: "100%", height: "80vh" }}>
        {proposalsVoteAll
          .filter((stats) => stats.proposals.length > 0)
          .map((votes, i) => {
            return (
              <TabPanel value={activeFund} index={i} key={i}>
                <Box sx={{ width: "100%", height: "70vh" }}>
                  <DataGrid
                    rows={votes.proposals}
                    columns={columns}
                    columnBuffer={columns.length}
                    rowBuffer={100 / 2}
                    pagination
                    paginationMode="server"
                    rowCount={votes.paginationData.totalDocs}
                    rowsPerPageOptions={[100]}
                    loading={voteProportionAllLoading}
                    page={votes.paginationData.currentPage}
                    onPageChange={async (newPage) => {
                      const values = { ...csvVotesParams };
                      values.page = newPage + 1;
                      values.fund = votes.id;
                      setVoteProportionAllLoading(true);
                      getVoteProportionAll(values)
                        .then((res) => {
                          const { proposals, message, ...paginationData } = res;

                          if (!proposals) return;

                          const aux_votes = proposalsVoteAll;
                          const new_proposals = reAsignIds(proposals);
                          aux_votes[i] = {
                            proposals: new_proposals,
                            message,
                            paginationData,
                          };
                          setProposalsVoteAll(aux_votes);
                        })
                        .catch((error) => {
                          console.log("error", error);
                          const { response } = error;
                          alert(
                            !response?.data
                              ? error.message
                              : response.data.message
                          );
                        })
                        .finally(() => {
                          setVoteProportionAllLoading(false);
                        });
                    }}
                  />
                </Box>
              </TabPanel>
            );
          })}
      </Box>
    </>
  );
};

export default VotesView;
