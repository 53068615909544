import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Button } from "react-bootstrap";
import api from "../../api";
import { DataGrid } from "@mui/x-data-grid";

import renderCellExpand from "../utils/renderCellExpand";
import { EditionMode } from "./EditionMode";
import { ConfirmationModal } from "./ConfirmationModal";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

/**
 * Contents of the Admin Panel page.
 * @param {Object} props
 * @param {Object} ref - a reference to the component
 * @returns the rendered component
 * @author Raferu
 * @author valeriaxeleva
 */
const UserAdministration = (props, ref) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showEdition, setShowEdition] = useState(false);
  const [operation, setOperation] = useState({});
  const [refreshUsers, setRefreshUsers] = useState(false);

  const handleShow = () => setShow(true);
  const handleCloseEdition = () => setShowEdition(false);
  const handleShowEdition = () => setShowEdition(true);

  const getAllUsers = () =>
    api
      .getUsers()
      .then((result) => setUsers(result.data.users))
      .catch((err) => alert(!err.response ? err.message : err.response.message))
      .finally(setLoading(false));

  const createUser = (body) =>
    api
      .createUser(body)
      .then((res) => {
        alert(res.data.message);
        getAllUsers();
      })
      .catch((err) =>
        alert(!err.response ? err.message : err.response.message)
      );

  const updateUser = (id, body) => {
    setLoading(true);
    api
      .updateUser(id, body)
      .then(() => getAllUsers())
      .catch((err) => alert(!err.response ? err.message : err.response.message))
      .finally(() => setLoading(false));
  };

  const blockUser = (id, body) => updateUser(id, body);

  const resetPassword = (email) => {
    const auth = getAuth();
    setLoading(true);
    sendPasswordResetEmail(auth, email)
      .then((e) => {
        alert("Email sent!");
      })
      .catch((error) => {
        const errorMessage = error.message;
        alert(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getAllUsers();
  }, [refreshUsers]);

  useEffect(() => {
    const { op } = operation;
    if (!op) {
      handleCloseEdition();
    } else if (op === "block" || op === "reset") {
      handleShow();
    } else if (op === "update" || op === "add") {
      handleShowEdition();
    }
  }, [operation]);

  const handleUpdateUser = (params) => {
    setOperation({ op: "update", user: params.row });
  };

  const usersColumns = [
    {
      field: "email",
      headerName: "Email",
      hideable: false,
      flex: 3,
      renderCell: ({ value, colDef }) =>
        renderCellExpand({ value: value, colDef, extra: true }),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      renderCell: renderCellExpand,
    },
    {
      field: "isBlocked",
      headerName: "Blocked",
      sortable: false,
      valueSetter: (row) => {
        return row.isBlocked ? "Yes" : "No";
      },
      valueGetter: (row) => {
        return row.isBlocked ? "Yes" : "No";
      },
      flex: 1,
    },
    {
      field: "roles",
      headerName: "Roles",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "user_type",
      headerName: "Type",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "update",
      headerName: "Update",
      sortable: false,
      hideable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          style={{ fontSize: 16 }}
          className="shadow-none"
          size="lg"
          variant="link"
          disabled={params.row.isBlocked}
          onClick={() => handleUpdateUser(params)}
        >
          Update
        </Button>
      ),
      flex: 1,
    },
    {
      field: "reset_password",
      headerName: "Password",
      sortable: false,
      hideable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          style={{ fontSize: 16 }}
          className="shadow-none p-0"
          size="lg"
          variant="link"
          disabled={params.row.isBlocked}
          onClick={() => setOperation({ op: "reset", user: params.row })}
        >
          Reset Password
        </Button>
      ),
      flex: 1,
    },
    {
      field: "block",
      headerName: "Block",
      sortable: false,
      hideable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          style={{ fontSize: 16, color: "red" }}
          className="shadow-none"
          size="lg"
          variant="link"
          onClick={() => setOperation({ op: "block", user: params.row })}
        >
          {!params.row.isBlocked ? "Block" : "Unblock"}
        </Button>
      ),
      flex: 1,
    },
  ];

  function setCreateUser() {
    setOperation({ op: "add", user: null });
  }

  useImperativeHandle(ref, () => ({
    setCreateUser,
  }));

  return (
    <>
      <ConfirmationModal
        show={show}
        handleClose={setShow}
        title={
          operation.op === "reset"
            ? "Reset Password"
            : `${operation.user?.isBlocked ? "Unblock" : "Block"} User`
        }
        body={
          operation.op === "reset"
            ? `An email with an URL to reset the password will be sent to ${operation.user?.email}`
            : `Are you sure you want to ${
                operation.user?.isBlocked ? "unblock" : "block"
              } the user with email: ${operation.user?.email}?`
        }
        footer={true}
        action={
          operation.op === "reset"
            ? () => resetPassword(operation.user?.email)
            : () =>
                blockUser(operation.user?._id, {
                  isBlocked: !operation.user?.isBlocked,
                })
        }
      />

      <EditionMode
        show={showEdition}
        setRefreshUsers={setRefreshUsers}
        handleClose={setShowEdition}
        title={`${operation.op === "add" ? "Add" : "Update"} User`}
        action={operation.op === "add" ? createUser : updateUser}
        user={operation.user}
      />

      <DataGrid
        className="mt-2"
        style={{ fontSize: 16 }}
        pageSize={15}
        getRowId={(row) => row._id}
        hideFooterSelectedRowCount
        rows={users}
        columns={usersColumns}
        loading={loading}
        pagination
        paginationMode="client"
        rowsPerPageOptions={[15]}
      />
    </>
  );
};

export default forwardRef(UserAdministration);
