import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button } from "@mui/material";
import * as Yup from "yup";
import {
  getCompaniesLogs,
  searchCompanies as searchCompaniesReq,
} from "../utils/reqs";
import CompaniesTable from "./Companies/CompaniesTable";
import { default as SearchForm } from "./Companies/SearchForm";
import { default as LogsForm } from "./Companies/LogsForm";
import { MainContent } from "./Layout/MainContent";
import { Side } from "./Layout/Side";
import { Page } from "./Layout/Page";
import { SideForm } from "./Layout/SideForm";

const EditCompany = () => {
  const [loadingTable, setLoadingTable] = useState(false);
  const [buttonMessage, setButtonMessage] = useState("Switch to Logs View");
  const [showLogsTable, setShowLogsTable] = useState(false);
  const [rowsCompanies, setRowsCompanies] = useState([]);
  const [rowCountCompanies, setRowCountCompanies] = useState(0);
  const [rowsLogs, setRowsLogs] = useState([]);
  const [rowCountLogs, setRowCountLogs] = useState(0);
  const formikMainSearch = useRef();
  const resultsRef = useRef(null);

  const initValues = {
    name: "",
    isin: "",
    country: "",
    index: "",
  };

  const initValuesLogs = {
    operation: "",
    modifiedBy: "",
    modifiedStart: "",
    modifiedEnd: "",
    name: "",
    industry: "",
    sector: "",
    isPrev: false,
  };

  const yupEschema = Yup.object({
    name: Yup.string().max(100, "Must be 100 characters or less"),
    isin: Yup.string()
      .min(12, "Must be exactly 12 digits")
      .max(12, "Must be exactly 12 digits"),
    country: Yup.string().max(50, "Must be 50 characters or less"),
    index: Yup.string().max(50, "Must be 50 characters or less"),
  });

  const handleLoadingTable = useCallback((val) => {
    setLoadingTable(val);
  }, []);

  /**
   * Edit the basic information of a company, validates the fields and calls the api.
   * @param {Object} values - object with the data of the company
   * @param {string} values._id - id of the company
   * @param {string} [values.name] - New Name of the company
   * @param {string} [values.industry] - new industry of the company
   * @param {string} [values.sector] - new sector of the company
   * @param {string} [values.country] - new country of the company
   * @param {string[]} [values.aliases] - array with the aliases of the company
   * @author Medina192
   * @author ValeriaG
   */
  const searchCompanies = async (values, page = 1) => {
    if (!values.names) {
      formikMainSearch.current = values;
      if (values.country === "default" || values.country === "")
        delete values.country;
      if (!values.isin) delete values.isin;
      if (!values.index) delete values.index;
    }

    handleLoadingTable(true);
    searchCompaniesReq(page, values, 100)
      .then((res) => {
        const { companies, totalDocs } = res.data;
        setRowsCompanies(companies);
        setRowCountCompanies(totalDocs);
      })
      .catch((error) => {
        const { response } = error;
        alert(!response?.data ? error.message : response.data.message);
      })
      .finally((_) => handleLoadingTable(false));
  };

  const getLogs = useCallback(
    async (params, page = 1) => {
      handleLoadingTable(true);
      getCompaniesLogs(page, params, 100)
        .then((res) => {
          const { logs, totalDocs } = res;
          setRowsLogs(logs);
          setRowCountLogs(totalDocs);
        })
        .catch((error) => {
          const { response } = error;
          alert(!response?.data ? error.message : response.data.message);
        })
        .finally((_) => handleLoadingTable(false));
    },
    [handleLoadingTable]
  );

  const handleshowLogsTable = () => {
    setShowLogsTable(!showLogsTable);
  };

  useEffect(() => {
    if (window.screen.width < 768) {
      showLogsTable
        ? setButtonMessage("Go back")
        : setButtonMessage("Logs View");
    } else {
      showLogsTable
        ? setButtonMessage("Go back to company editor")
        : setButtonMessage("Switch to Logs View ");
    }
  }, [showLogsTable]);

  const extraProps = {
    searchCompanies,
  };

  const formsProps = {
    extraProps,
    searchFunction: showLogsTable ? getLogs : searchCompanies,
    searchForm: SearchForm,
    logsForm: LogsForm,
    yupEschema,
    showLogsTable,
    initValues,
    initValuesLogs,
  };

  function handleMerge() {
    resultsRef.current.openMergeModal();
  }

  return (
    <Page>
      <Side>
        <SideForm {...formsProps}></SideForm>
      </Side>
      <MainContent
        title={showLogsTable ? "Company Editor Logs" : "Company Editor"}
        colorTitle={"red.dark"}
      >
        <MainContent.Actions>
          {!showLogsTable && (
            <Button onClick={handleMerge}>Merge Companies</Button>
          )}
          <Button onClick={handleshowLogsTable}>{buttonMessage}</Button>
        </MainContent.Actions>
        <MainContent.Body>
          <CompaniesTable
            ref={resultsRef}
            {...{
              rows: showLogsTable ? rowsLogs : rowsCompanies,
              setRows: showLogsTable ? setRowsLogs : setRowsCompanies,
              rowsCount: showLogsTable ? rowCountLogs : rowCountCompanies,
              setRowsCount: showLogsTable
                ? setRowCountLogs
                : setRowCountCompanies,
              showLogsTable,
              loadingTable,
              searchFunction: showLogsTable ? getLogs : searchCompanies,
              formikMainSearch,
            }}
          />
        </MainContent.Body>
      </MainContent>
    </Page>
  );
};

export default EditCompany;
