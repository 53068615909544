import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';

/**
 * <p>Accordion for dropdowns in side menu</p>
 * @param {Object} props
 * @param {string} props.title - Title of the accordion  
 * @param {node} props.children - Children elements
 * @param {boolean} props.hide - true if the accordion will display, false i.o.c 
 * @returns the rendered view
 * @author rafaelg5
 */
export const MenuDropdown = ({ title, children, hide }) => {
    return (
        <Accordion className='home-menu-dropdown' sx={{ display: hide ? 'none' : 'block' }}>
            <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faAngleDown} color='black' />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="p-0 d-flex"
                style={{ height: 25 }}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails style={{ borderTop: '1px solid #999999' }} className="px-0 pt-2">
                <div>{children}</div>
            </AccordionDetails>
        </Accordion>
    );
}

export default MenuDropdown;