import { memo } from "react";
/**
 * <p>Custom tooltip box with numeric information of the search results that builds the ESG graph.</p>
 * @param {Object} payload - information of the current row/bar/section
 * @param {string} label - label to identify the current row/bar/section
 * @param {boolean} active - true if the mouse is hover the graph
 * @param {string} subjectActive - current active subject
 * @returns rendered tooltip with ESG information of the graph
 * @author valeriaxeleva
 */
const CustomTooltipESG = ({ payload, label, active, subjectActive }) => {
  function calculateESGVotes(payload, subject) {
    let percentage = !payload ? 0 : payload.value;
    let totalVotes = 0;
    let totalFor = 0;
    let id = !payload
      ? 0
      : payload.dataKey.substring(payload.dataKey.lastIndexOf("_") + 1);

    switch (subject) {
      case "E":
        totalVotes = !payload ? 0 : payload.payload[`E_totals_${id}`];
        totalFor = !payload ? 0 : payload.payload[`forTotalE_${id}`];
        break;
      case "S":
        totalVotes = !payload ? 0 : payload.payload[`S_totals_${id}`];
        totalFor = !payload ? 0 : payload.payload[`forTotalS_${id}`];
        break;
      case "G":
        totalVotes = !payload ? 0 : payload.payload[`G_totals_${id}`];
        totalFor = !payload ? 0 : payload.payload[`forTotalG_${id}`];
        break;
      case "ESG":
        totalVotes = !payload ? 0 : payload.payload[`ESG_totals_${id}`];
        totalFor = !payload ? 0 : payload.payload[`forTotalESG_${id}`];
        break;
      default:
        alert("error generating data");
        break;
    }

    return {
      name: !payload ? 0 : payload.name,
      color: !payload ? 0 : payload.stroke,
      total: totalFor,
      perc: percentage,
      totalVotes: totalVotes,
    };
  }

  if (active) {
    let datas = payload?.reduce((accumulator, current) => {
      return [...accumulator, calculateESGVotes(current, subjectActive)];
    }, []);

    return (
      <div className="custom-tooltip">
        <strong>{label}</strong>
        {datas?.map((item) => {
          return (
            <>
              <p
                className="label"
                style={{ color: item.color, fontWeight: "bold" }}
              >
                {item.name}
              </p>
              <p className="label">{`${subjectActive} percentage : ${item.perc}%`}</p>
              <p className="label">
                {item.total} in favor of {item.totalVotes} total
              </p>
            </>
          );
        })}
      </div>
    );
  }

  return null;
};

export default memo(CustomTooltipESG);
