import renderCellExpand from "../utils/renderCellExpand";
import { formatDate } from "../../utils/utils";
import { Box } from "@mui/material";
import { EditTable } from "../Layout/EditTable";
import { useState } from "react";
import { StatusBar } from "../Layout/GeneralElements";

/**
 * Table to display fetched data using MUI components with server pagination, based on the Editor + Logs OxProx pattern.
 * @param {Object} props
 * @param {Object[]} props.rows - The rows of the data to be displayed
 * @param {number} props.rowsCount - The total number of rows.
 * @param {function} props.setRows - Function to change the state of the rows hook.
 * @param {boolean} props.loading - True if the data is still fetching, false i.o.c.
 * @param {function} props.searchFunction - The function that will search the data if the user changes the page.
 * @param {Object} props.formikMainSearch - The body query that will be passed to the searchFunction
 * @param {boolean} props.isFund - Attribute to change text according to the type of user
 * @returns the rendered component
 * @author milenexeleva
 */
const UploadsTable = ({
  rows,
  rowsCount,
  setRows,
  loading,
  formikMainSearch,
  searchFunction,
  isFund,
}) => {
  const [selectionModel, setSelectionModel] = useState([]); // it will save the id selected when the user selects a checkbox

  const columns = [
    {
      field: "filename",
      headerName: "File Name",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "manager",
      headerName: "Manager",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "cleanedBy",
      headerName: isFund ? "Responsible" : "Cleaned by",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
      renderCell: ({ value, colDef }) =>
        renderCellExpand({ value: formatDate(value), colDef }),
    },
    {
      field: "uploadedBy",
      headerName: "Uploaded By",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "progress",
      headerName: "Progress",
      flex: 1,
      renderCell: StatusBar,
    },
    {
      field: "error",
      headerName: "Error",
      width: 150,
      renderCell: ErrorInfo,
    },
  ];

  const tableProps = {
    rows,
    columns: columns,
    showLogsTable: true,
    setRows,
    rowsCount,
    loadingTable: loading,
    searchFunction,
    formikMainSearch,
    selectionModel,
    setSelectionModel,
  };

  return <EditTable {...tableProps} />;
};

const ErrorInfo = (params) => {
  let errors = params.row.error;

  let errorDivLink = (
    <div
      style={{ color: "#007bff" }}
      className="error-td-upload"
      onClick={() => alert("Error log: " + params.row.logs)}
    >
      View errors
    </div>
  );

  const errorDivText = (
    <div style={{ color: "#00000" }} className="error-td-upload">
      <b>Errors</b>
    </div>
  );

  if (process.env.REACT_APP_ENVIRONMENT === "production")
    return errors ? errorDivText : <div>No errors</div>;
  else return errors ? errorDivLink : <div>No errors</div>;
};

export default UploadsTable;
