import { Box } from "@mui/material";

export const StatusBar = (params) => {
    let progress = params.row.progress;
    if (!params.row.progress) progress = 0;
    return (
      <div className="d-flex">
        <span className="mr-1">{progress} %</span>
        <Box
          sx={{
            width: progress,
            height: 10,
            bgcolor: "primary.dark",
            "&:hover": {
              backgroundColor: "primary.main",
              opacity: [0.9, 0.8, 0.7],
            },
            alignSelf: "center",
          }}
        />
      </div>
    );
  };