import React, { useMemo, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import * as yup from "yup";
import { Row, Container, Button as ButtonBoo } from "react-bootstrap";
import { uploadFiles } from "../../utils/reqs";
import Paper from "@mui/material/Paper";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../css/Forms.scss";
import {
  FormikSelect,
  FormikTextInput as TextField,
  FormikSelect as Select,
} from "../utils/forms";
import countryList from "react-select-country-list";
import { Box, Typography } from "@mui/material";

/**
 * Component that renders the upload form
 * @param {Object} props
 * @param {function} props.setLoading - Function to change the state of the loading hook.
 * @param {function} props.submitUploadFilesForm - Ref to the function that submits the form to upload a new file
 * @param {function} props.setShowPasswordModal - Function to change the state of the show modal hook.
 * @param {Object} props.userDataPension - Pension data of the current authenticated user
 * @param {boolean} props.isFund - Attribute that indicates the user type
 * @param {string} props.username - Name of the user
 * @param {string} props.displayName - Display name in case the user is a fund
 * @returns the rendered component
 * @author milenexeleva
 */
const UploadFilesForm = ({
  setLoading,
  submitUploadFilesForm,
  setShowPasswordModal,
  userDataPension,
  isFund,
  username,
  displayName,
}) => {
  const countries = useMemo(() => countryList().getData(), []);
  const valuesFormik = useRef({});

  const isCSV = (filename) => {
    if (!filename) return false;
    const extension = filename.slice(-4);
    const isCSV = extension === ".csv";
    return isCSV;
  };
  const formikRef = useRef();

  submitUploadFilesForm.current = () => {
    const { fund, cleaner, file, fundCountry, managerType } =
      valuesFormik.current;
    const data = {
      uploadedBy: username,
      manager: fund,
      cleanedBy: cleaner,
      file,
      fundCountry,
      managerType,
    };
    processLineByLine(data);
  };

  const setFields = async (file, setFieldValue) => {
    if (file) {
      let name = file.name.substring(0, file.name.length - 4);
      let csv_data = name.split("_");
      if (!isFund) {
        await setFieldValue("fund", csv_data[0]);
      }
      await setFieldValue("cleaner", csv_data.length >= 4 ? csv_data[4] : "");
    } else {
      await setFieldValue("cleaner", "");
    }
    await setFieldValue("file", !file ? null : file);
  };

  const csvValid = async (valid, data = {}) => {
    if (valid) {
      const { uploadedBy, cleanedBy, manager, file, fundCountry, managerType } =
        data;
      let dataForm = new FormData();
      dataForm.append("uploadedBy", uploadedBy);
      dataForm.append("cleanedBy", cleanedBy);
      dataForm.append("manager", manager.split("_")[0]);
      dataForm.append("file", file);
      dataForm.append("managerCountry", fundCountry);
      dataForm.append("managerType", managerType);
      setLoading(true);
      await uploadFiles(dataForm);
      setLoading(false);
      formikRef.current?.resetForm({ cleaner: "", file: null });
    } else alert("Invalid file format, please check your file.");
  };

  const processLineByLine = async (data) => {
    const { file } = data;
    let str = "";
    if (file) {
      let reader = new FileReader();

      reader.onload = function () {
        str = reader.result;
        const headers_full = str.slice(0, str.indexOf("\n")).split("\n");
        const headers = headers_full[0].split("|");
        const rows = str.slice(str.indexOf("\n") + 1).split("\n");
        const main_rows = rows[1] + rows[2] + rows[3];
        let count = main_rows.split("|").length - 1;
        if (count < 20) {
          console.log("Incorrect separator for csv, please use '|' instead");
          csvValid(false);
          return;
        }
        let headers2 =
          "cik|cikName|seriesId|seriesName|issuer|ticker|accNo|" +
          "fillingType|meeting_id|meeting_date|meeting_type|cusip|item|proposal|" +
          "rationale|proposed_by|mgmt_recommendation|voted?|vote_cast|" +
          "for_against_mgmt|source\n";
        let headers2_array = headers2.split("|");
        const validHeaders =
          headers2_array.length === headers.length &&
          headers2_array.every((val, index) => {
            const value1 = val.replace(/\n|\r/g, "");
            const value2 = headers[index].replace(/\n|\r/g, "");
            return value1 === value2;
          });
        if (!validHeaders) {
          console.log("Invalid headers");
          csvValid(false);
          return;
        }

        if (
          rows[1].split("|").length === 21 &&
          rows[2].split("|").length === 21
        ) {
          csvValid(true, data);
        } else {
          console.log("Column size mismatch");
          csvValid(false);
          return;
        }
      };
      reader.readAsBinaryString(file);
    }
    setShowPasswordModal(false);
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={{
        cleaner: "",
        fund: isFund ? displayName : "",
        file: null,
        fundCountry: userDataPension?.country ? userDataPension?.country : "",
        managerType: userDataPension?.type
          ? userDataPension?.type
          : "investment",
        readDisclaimer: isFund ? false : true,
      }}
      validationSchema={yup.object({
        file: yup
          .mixed()
          .required("Required field")
          .test(
            "is-csv",
            "Invalid extension",
            (value) => value?.name && isCSV(value.name)
          )
          .test(
            "file-size",
            "File size excedeed",
            (value) => value?.size && value.size <= 40000000
          ),
        cleaner: yup
          .string()
          .required("Required field")
          .max(50, "Must be 50 characters or less"),
        fund: yup.string().required("Required field"),
        fundCountry: yup.string().required("Required field"),
        type: yup.string(),
        readDisclaimer: yup.bool().oneOf([true], "Field must be checked"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
        }, 400);
        valuesFormik.current = values;
        setShowPasswordModal(true);
      }}
    >
      {({
        setFieldValue,
        setFieldTouched,
        dirty,
        isSubmitting,
        errors,
        touched,
      }) => (
        <Form id="formUpload">
          <Container>
            <Paper className="my-5 pt-3 px-3 pb-2">
              <div className="d-flex align-items-baseline">
                <Typography
                  className="form-title mr-2"
                  sx={{ fontSize: { xs: "20px", md: "26px" } }}
                >
                  Upload your formatted file to the database
                </Typography>
                <Tooltip
                  title="The file must be named with this format: Vanguard_2020_4_10_IanRobertson.csv"
                  placement="right"
                >
                  <IconButton size="small">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </IconButton>
                </Tooltip>
              </div>
              <Row>
                <Box
                  sx={{
                    color: "#002147",
                    fontSize: { xs: "0.8rem", md: "1rem" },
                    padding: "0 20px",
                  }}
                >
                  <label htmlFor="file">Select your file to upload</label>
                  <input
                    name="file"
                    type="file"
                    accept=".csv"
                    id="file"
                    style={{ display: "flex" }}
                    onChange={async (e) => {
                      const selectedFile = e.target.files[0];
                      setFieldTouched("file");
                      setFieldTouched("cleaner");
                      if (userDataPension?.type !== "pension") {
                        setFieldTouched("fund");
                      }
                      await setFields(selectedFile, setFieldValue);
                    }}
                  />
                  <ErrorMessage
                    name="file"
                    component="div"
                    className="mt-1 input-error-message"
                  />
                </Box>
              </Row>

              {!isFund && (
                <Row className="mt-3 ml-1 justify-content-start">
                  <Select label="Fund type" name="managerType">
                    <option value="investment" key="investment">
                      Asset Manager
                    </option>
                    <option value="pension" key="pension">
                      Asset Owner
                    </option>
                  </Select>
                </Row>
              )}

              <Box
                className="mt-3"
                sx={{
                  display: "flex",
                  width: { xs: "80%", md: "100%" },
                  flexDirection: { xs: "column", md: "row" },
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <Box xs="3" className="align-self-end">
                  <TextField
                    name="fund"
                    label="Manager name"
                    disabled={isFund}
                  />
                </Box>
                <Box xs="3" className="align-self-end">
                  <FormikSelect
                    label="Manager country"
                    name="fundCountry"
                    disabled={isFund}
                  >
                    <option value="">Select a country...</option>
                    {countries.map((country) => {
                      const { label } = country;
                      return (
                        <option key={label} value={label}>
                          {label}
                        </option>
                      );
                    })}
                  </FormikSelect>
                </Box>
                <Box xs="3" className="align-self-end">
                  <TextField
                    name="cleaner"
                    label={
                      isFund ? "Person responsible of the upload" : "Cleaned by"
                    }
                  />
                </Box>
                <Box
                  xs="2"
                  className="align-self-end form-buttons-box"
                  style={{ marginBottom: 10, whiteSpace: "nowrap" }}
                >
                  <ButtonBoo type="submit" disabled={isSubmitting || !dirty}>
                    Upload file
                  </ButtonBoo>
                </Box>
              </Box>
              {isFund && (
                <>
                  <div className="mt-3 ml-1 d-flex justify-content-start align-items-center">
                    <Field
                      type="checkbox"
                      name="readDisclaimer"
                      id="readDisclaimer"
                    />
                    <label htmlFor="readDisclaimer" className="ml-2 my-0">
                      I have read the
                    </label>
                    <Link to="/upload-disclaimer">
                      <p className="my-0 ml-1">disclaimer</p>
                    </Link>
                  </div>
                  <p className="ml-1 validation-error-msg">
                    {errors.readDisclaimer}
                  </p>
                  <p className="mt-5" style={{ fontSize: "12px" }}>
                    By using this service, you confirm that you are authorized
                    by your organisation to upload these proxy votes, and the
                    votes pertain to your investments only. In the event that
                    you would like to upload voting data from a fund other than
                    your own, kindly send an email to chipiwa.mukono@oxprox.org.
                  </p>
                </>
              )}
            </Paper>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default UploadFilesForm;
