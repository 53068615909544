import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

/**
 * Table to display fetched data using MUI components with server pagination, based on the Editor + Logs OxProx pattern.
 * @param {Object} props
 * @param {Object[]} props.columns - The columns (headers) of the table
 * @param {Object[]} props.rows - The rows of the data to be displayed
 * @param {Object[]} props.selectionModel - An array that will store the selectd rows in the table
 * @param {function} props.setSelectionModel- Function to update the state of the data of selectionModel
 * @param {number} props.rowsCount - The total number of rows.
 * @param {boolean} props.showLogsTable - True if the visible view is the Logs one, false if is the Editor view. Note the columns, rows and searchFunction will change according of this value.
 * @param {boolean} props.loadingTable - True if the data is still fetching, false i.o.c.
 * @param {function} props.searchFunction - The function that will search the data if the user changes the page.
 * @param {Object} props.formikMainSearch - The body query that will be passed to the searchFunction
 * @returns the rendered component
 * @author valeriaxeleva
 */
export const EditTable = ({
  columns,
  rows,
  selectionModel,
  setSelectionModel,
  rowsCount,
  showLogsTable,
  loadingTable,
  searchFunction,
  formikMainSearch,
}) => {
  /**
   * Manages the pagination for the search results
   * @author valeriaxeleva
   */
  const handlePageChange = (newPage) => {
    searchFunction(formikMainSearch.current, newPage + 1);
  };

  console.log('%csrc/components/Layout/EditTable.jsx:38 rowsCount', 'color: #007acc;', rowsCount);
  return (
    <Box sx={{ width: "100%", height: "70vh", overflow: "scroll" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        rowCount={rowsCount}
        paginationMode="server"
        onPageChange={(p) => handlePageChange(p)}
        loading={loadingTable}
        getRowId={(row) => row._id}
        checkboxSelection={showLogsTable ? false : true}
        disableSelectionOnClick={showLogsTable ? false : true}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        pageSize={100}
        rowsPerPageOptions={[100]}
      />
    </Box>
  );
};
