import { useRef } from "react";
import { Box, Button } from "@mui/material";
import Page from "./Layout/Page";
import { MainContent } from "./Layout/MainContent";
import UserAdministration from "./AdminPanel/UserAdministration";

/**
 * Principal component for the view of the Administration Panel,
 * @see main req-1-3-admin
 * @author Raferu
 */
const AdminPanel = (props) => {
  // When admin panel has other options
  //const [option, setOption] = useState(0);

  const resultsRef = useRef(null);

  function handleCreateUser() {
    resultsRef.current.setCreateUser();
  }

  return (
    <Page single>
      <MainContent title={"Administrator Panel"} single>
        <MainContent.Actions>
          <Button onClick={handleCreateUser}>Create User</Button>
        </MainContent.Actions>
        <MainContent.Body>
          <Box sx={{ height: "70vh" }}>
            <UserAdministration ref={resultsRef} />
          </Box>
        </MainContent.Body>
      </MainContent>
    </Page>
  );
};

export default AdminPanel;
