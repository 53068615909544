import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import "./css/Home.scss";
import "./css/Layout.scss";
import "./css/modal.scss";
import { Button } from "@mui/material";
import { default as Spinner } from "./utils/SpinnerFull";
import { getProposals, getProposalLogs, getESGInfo } from "../utils/reqs";
import LoadingContext from "../contexts/LoadingContext";
import { useAuthState } from "react-firebase-hooks/auth";
import SideMenu from "./Proposals/SideMenu";
import SideFormLogs from "./Proposals/SideFormLogs";
import Results from "./Proposals/Results";
import ResultsLogs from "./Proposals/ResultsLogs";
import { MainContent } from "./Layout/MainContent";
import { Side } from "./Layout/Side";
import { Page } from "./Layout/Page";
import { auth } from "../firebase";
import { useLocation, useHistory } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import SubscribeForm from "./utils/SubscribeForm"

/**
 * <p>Component for database page and proposal editor page.</p>
 * <p>Displays proposals from the database in a table filtered by multiple fields controlled by the sidebar.</p>
 * <p>Information of the search can be downloaded as an Excel file with the "Download Search" button.</p>
 * @param {Object} props
 * @param {boolean} props.editorMode - true if the view is /edit false if the view is home page
 * @param {boolean} props.logsMode - true if /edit view needs to display the table logs
 * @param {function} props.setProposalLogs - sets the logsMode
 * @returns rendered view of Home component
 * @author rafaelg5
 * @author valeriaxeleva
 */
const ProposalsView = ({ editorMode, logsMode, setProposalLogs }) => {
  const [user] = useAuthState(auth);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const searchTerm = searchParams.get("search");
  const history = useHistory();

  // Global context
  const [loading, setLoading] = useState(false);
  const loadingMemo = useMemo(() => [loading, setLoading], [loading]);

  // Data
  const [proposals, setProposals] = useState([]);
  const [pagination, setPagination] = useState({});

  const [logs, setLogs] = useState([]);
  const [paginationLogs, setPaginationLogs] = useState({});

  // Values
  const [openSubscribe,setOpenSubscribe] = useState(false)
  const [selectedProposals, setSelectedProposals] = useState([]);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [isAssignSubjects, setIsAssignSubjects] = useState(false);
  const [logsButtonMessage, setLogsButtonMessage] = useState("Show logs");
  const editorTitle = logsMode ? "Edition Logs" : "Proposals Editor";

  // Refs
  const formikRef = useRef();
  const formikLogsRef = useRef();
  const resultsRef = useRef(null);
  const selectedProposalsPersistent = useRef([]);
  const bodyQueryRef = useRef(null);

  const searchProposals = useCallback(async (body) => {
    setResultsLoading(true);
    const data = await getProposals(body);
    if (data.error) alert(data.error);
    else {
      const { proposals, ...pagination } = data;
      setProposals(proposals);
      setPagination(pagination);
    }
    setTimeout(() => {
      setResultsLoading(false);
    }, 0);
  }, []);

  const searchLogs = useCallback(async (body) => {
    setResultsLoading(true);
    getProposalLogs(body)
      .then((res) => {
        const { message, logs, ...pagination } = res;
        setLogs(logs);
        setPaginationLogs(pagination);
      })
      .catch((error) => {
        const { response } = error;
        alert(!response?.data ? error.message : response.data.message);
      })
      .finally((_) => setResultsLoading(false));
  }, []);

  useEffect(() => {
    const bodyFromQuery = {
      subjects: [],
      meetingStartDate: `1990-01-01`,
      meetingEndDate: `${new Date().getFullYear() + 1}-01-01`,
      compoundIndexArray: [],
      editMode: false,
    };

    const execRequest = async () => {
      const subjectInfo = await getESGInfo(searchTerm[searchTerm.length - 1]);
      if (searchTerm.includes("company")) {
        bodyFromQuery.compoundIndexArray = [...subjectInfo.topCompany];
      } else if (searchTerm.includes("proposals")) {
        bodyFromQuery.compoundIndexArray = [...subjectInfo.mostVoted];
      } else {
        bodyFromQuery.compoundIndexArray = [...subjectInfo.exploreDatabase];
      }
      if (
        bodyFromQuery.compoundIndexArray.length > 0 &&
        proposals.length === 0
      ) {
        setIsAdvancedSearch(true);
        const values = bodyFromQuery;
        bodyQueryRef.current = values;
        searchProposals(bodyFromQuery);
      }
    };
    if (searchTerm) {
      const allowedTerms = [
        "E",
        "S",
        "G",
        "company-E",
        "company-S",
        "company-G",
        "proposals-E",
        "proposals-S",
        "proposals-G",
      ];
      if (allowedTerms.includes(searchTerm)) {
        execRequest();
      } else if (searchTerm !== "") {
        history.push("/notfound");
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    if(!editorMode){
    const lastOpened = localStorage.getItem('modalLastOpened');
    const now = new Date().getTime();

    if (lastOpened) {
      const timePassed = now - lastOpened;
      const oneDay = 24 * 60 * 60 * 1000; 

      if (timePassed > oneDay) {
        setOpenSubscribe(true);
        localStorage.setItem('modalLastOpened', now);
      }
    } else {
      setOpenSubscribe(true);
      localStorage.setItem('modalLastOpened', now);
    }
    }
    setProposals([]);
    setPagination({});
  }, [editorMode]);

  useEffect(() => {
    if (window.screen.width < 768) {
      logsMode
        ? setLogsButtonMessage("Go back")
        : setLogsButtonMessage("Logs View");
    } else {
      logsMode
        ? setLogsButtonMessage("Go back to proposal editor")
        : setLogsButtonMessage("Switch to Logs View ");
    }
  }, [logsMode]);

  const changeLogsMode = () => {
    setProposalLogs(!logsMode);
  };

  function handleMergeProposals() {
    resultsRef.current.handleMergeProposals();
  }

  function handleDownloadCSV() {
    resultsRef.current.downloadCSV();
  }

  return (
    <LoadingContext.Provider value={loadingMemo}>
      <Page>
        <Side>
          {!logsMode ? (
            <SideMenu
              {...{
                key: editorMode ? 1 : 2,
                editorMode,
                proposals,
                setProposals,
                selectedProposals,
                searchProposals,
                formikRef,
                isAdvancedSearch,
                setIsAdvancedSearch,
                isAssignSubjects,
                setIsAssignSubjects,
                selectedProposalsPersistent,
                user,
              }}
            />
          ) : (
            <SideFormLogs
              {...{
                logs,
                setLogs,
                searchLogs,
                isAssignSubjects,
                setIsAssignSubjects,
                selectedProposalsPersistent,
                formikLogsRef,
              }}
            />
          )}
        </Side>
        {loading && <Spinner message={loadingMessage} />}
        <MainContent
          title={editorMode ? editorTitle : "Voting Records"}
          colorTitle={"red.dark"}
        >
          <MainContent.Actions>
            {!editorMode ? (
              <>
                <Button
                  onClick={handleDownloadCSV}
                  disabled={proposals?.length === 0}
                  sx={{
                    mr: 1
                  }}
                >
                  Download search
                </Button>
                <Button
                  component="a"
                  href="https://oxprox.org/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Report Error
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleMergeProposals}
                  disabled={
                    proposals.length === 0 || selectedProposals.length <= 1
                  }
                >
                  Merge Proposals
                </Button>
                <Button onClick={changeLogsMode}>{logsButtonMessage}</Button>
              </>
            )}
          </MainContent.Actions>
          <MainContent.Body>
            {!logsMode ? (
              <>
                <Box sx={{ mb: 2 }}>
                  <Paper
                    variant="elevation"
                    sx={{ p: 2, bgcolor: "gray.extraLight" }}
                  >
                    This tool enables you to see how your investment fund has
                    voted on environmental, social, and governance (ESG)
                    proposals at a particular company, or within a particular
                    industry. Use the filters to perform your search.
                  </Paper>
                </Box>
                <Results
                  ref={resultsRef}
                  {...{
                    user,
                    proposals,
                    setProposals,
                    pagination,
                    selectedProposals,
                    setSelectedProposals,
                    editorMode,
                    resultsLoading,
                    formikRef,
                    bodyQueryRef,
                    isAdvancedSearch,
                    searchProposals,
                    setLoadingMessage,
                    selectedProposalsPersistent,
                  }}
                />
              </>
            ) : (
              <>
                <Box sx={{ mb: 2 }}>
                  <Paper
                    variant="elevation"
                    sx={{ p: 2, bgcolor: "gray.extraLight" }}
                  >
                    This tool enables you to see how your investment fund has
                    voted on environmental, social, and governance (ESG)
                    proposals at a particular company, or within a particular
                    industry. Use the filters to perform your search.
                  </Paper>
                </Box>
                <ResultsLogs
                  {...{
                    user,
                    logs,
                    setLogs,
                    setPaginationLogs,
                    paginationLogs,
                    searchLogs,
                    resultsLoading,
                    setResultsLoading,
                    formikLogsRef,
                  }}
                />
              </>
            )}
            <SubscribeForm {...{openSubscribe,setOpenSubscribe}} />
          </MainContent.Body>
        </MainContent>
      </Page>
    </LoadingContext.Provider>
  );
};

export default ProposalsView;
