import { useField } from "formik";
import { Form } from "react-bootstrap";
import "../css/Forms.scss";
import { Typography } from "@mui/material";

const FormikTextInput = ({ label, customStyle = null, color, ...props }) => {
  const [field, meta] = useField(props);
  let inputClass = "";
  if (meta.touched && !props.noValidate) {
    inputClass = meta.error ? "error-input" : "valid-input";
  }

  return (
    <div className="menuForm-input">
      {label && (
        <Typography component="label" color={color} 
        sx={{
          marginLeft: "5px",
          fontWeight: "500",
        }} className={`menuForm-label ${customStyle?.labelStyle}`}>
          {label}
        </Typography>
      )}
      <Form.Control
        {...field}
        {...props}
        className={`${inputClass} blue-input`}
      />
      {meta.touched && meta.error ? (
        <div className="validation-error-msg">{meta.error}</div>
      ) : null}
    </div>
  );
};

const FormikTextArea = ({ label, customStyle = null, ...props }) => {
  const [field, meta] = useField(props);
  let inputClass = "";
  if (meta.touched && !props.noValidate) {
    inputClass = meta.error ? "error-input" : "valid-input";
  }

  return (
    <div className="menuForm-input">
      {label && (
        <label className={`menuForm-label ${customStyle?.labelStyle}`}>
          {label}
        </label>
      )}
      <Form.Control
        {...field}
        {...props}
        as="textarea"
        className={inputClass}
      />
      {meta.touched && meta.error ? (
        <div className="validation-error-msg">{meta.error}</div>
      ) : null}
    </div>
  );
};

const FormikCheckboxGroup = ({ children, ...props }) => {
  const [field] = useField({ ...props, type: "checkbox" });
  return (
    <>
      <label>
        <input type="checkbox" {...field} {...props} />
        {children}
      </label>
    </>
  );
};

const FormikRadioButton = ({ label, color, ...props }) => {
  const [field] = useField({ ...props, type: "radio" });
  return (
    <Typography component="label" color={color}>
      <input {...field} className="mr-1 leading-tight" type="radio" />
      {label && (
        <Typography component="span" sx={{ fontSize: "15px", fontWeight: "300" }}>
          {label}
        </Typography>
      )}
    </Typography>
  );
};

const FormikCheckBox = ({ label, color, ...props }) => {
  const [field] = useField({ ...props, type: "checkbox" });
  return (
    <Typography component="label" color={color} >
      <input {...field} className="mr-1 leading-tight" type="checkbox" />
      {label && (
        <span style={{ fontSize: "15px" }} className="text-sm">
          {label}
        </span>
      )}
    </Typography>
  );
};

const FormikSelect = ({ label, customStyle = null, ...props }) => {
  const [field, meta] = useField(props);
  let inputClass = "";
  if (meta.touched && !props.noValidate) {
    inputClass = meta.error ? "error-input" : "valid-input";
  }

  return (
    <div className="menuForm-input">
      {label && (
        <label
          className={`menuForm-label ${customStyle?.inputSelectStyle}`}
          htmlFor={props.id || props.name}
        >
          {label}
        </label>
      )}
      <Form.Control
        {...field}
        {...props}
        as="select"
        className={`${inputClass} ${customStyle?.inputSelectStyle} blue-input`}
      />
      {meta.touched && meta.error ? (
        <div className="validation-error-msg">{meta.error}</div>
      ) : null}
    </div>
  );
};

export {
  FormikTextInput,
  FormikCheckboxGroup,
  FormikSelect,
  FormikRadioButton,
  FormikCheckBox,
  FormikTextArea,
};
