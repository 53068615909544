import Button from "react-bootstrap/Button";
import { Formik, Form as FormFormik } from "formik";
import {
  FormikTextInput as TextField,
  FormikRadioButton,
} from "../utils/forms";
import { Box, Typography } from "@mui/material";
/**
 * <p>Side menu component for proposal editor.</p>
 * <p>Displays a form to search proposal logs.</p>
 * @param {Object} props
 * @param {function} props.searchLogs - searches for paginated proposals
 * @param {Object} props.formikLogsRef - reference to form in side menu for logs
 * @returns rendered view of SideFormLogs component
 * @author valeriaxeleva
 */
const SideFormLogs = ({ searchLogs, formikLogsRef, isMobile, handleDrawerClose }) => {
  const formikRef = formikLogsRef;

  return (
    <div className={"h-100"}>
      <Formik
        initialValues={{
          modifiedBy: "",
          modifiedStart: "",
          modifiedEnd: "",
          subjects: [],
          managementRecommendation: "",
          proponent: "",
          isPrev: false,
        }}
        innerRef={formikRef}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const nonFalsyValues = Object.keys(values).filter((key) => {
            const cond1 = typeof values[key] === "string" && values[key] !== "";
            const cond2 =
              typeof values[key] === "object" && !!values[key].length;
            return cond1 || cond2;
          });

          const body = {};
          nonFalsyValues.forEach((key) => (body[key] = values[key]));
          if (body.keywords) body.keywords = body.keywords.split(" ");
          if(isMobile) {
            handleDrawerClose() 
          }
          const { ...rest } = body;
          await searchLogs(rest);
          setSubmitting(false);
        }}
      >
        {({
          values,
          setFieldValue,
          resetForm,
          dirty,
          errors,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
        }) => (
          <FormFormik className="h-100 mt-4 sideForm">
            <div className="form_xl">
              {/*Operation*/}
              <Box className="d-flex justify-content-between mt-4" sx={{marginBottom: "15px"}}>
                <Typography component={"label"} className='menuForm-label' sx={{fontWeight: "500", marginLeft: "5px"}} >Operation:</Typography>
                <FormikRadioButton
                  label={"Edit"}
                  name={"operation"}
                  value="edit"
                />
                <FormikRadioButton
                  label={"Merge"}
                  name={"operation"}
                  value="merge"
                />
              </Box>

              {/*Modified By*/}
              <TextField
                className="w-100"
                name="modifiedBy"
                type="text"
                label="Modified By"
              />

              {/*Modified From*/}
              <TextField
                id="input-modified-from"
                className="w-100"
                name="modifiedDateStart"
                type="date"
                label="Modified from"
              />

              {/*Modified To*/}
              <TextField
                id="input-to"
                className="w-100"
                name="modifiedDateEnd"
                type="date"
                label="to"
              />

              {/* Subjects 
                                <MenuDropdown title="Subject" >
                                    <SubjectsDropdown type="proposal" formikName="subjects" {...{ values, setFieldValue }} />
                                </MenuDropdown>
                                */}

              {/* Management Recommendation */}
              <Typography component={"label"}  className="mt-1 menuForm-label" sx={{fontWeight: "500"}}>Mgmt Recommendation:</Typography>
              <Box className="d-flex justify-content-between" sx={{margin:"5px 0px 5px 10px"}}>
              {/* margin: 5px 0px 5px 10px; */}
                <FormikRadioButton
                  label={"For"}
                  id="mr-for"
                  name={"managementRecommendation"}
                  value="For"
                />
                <FormikRadioButton
                  label={"Against"}
                  id="mr-against"
                  name={"managementRecommendation"}
                  value="Against"
                />
              </Box>

              {/* Proponent */}
              <Typography component={"label"}  className="mt-1 menuForm-label" sx={{fontWeight: "500"}}>Proponent:</Typography>
              <Box className="d-flex justify-content-between" sx={{margin:"5px 0px 5px 10px"}}>
                <FormikRadioButton
                  label={"Management"}
                  id="proponent-mgmt"
                  name={"proponent"}
                  value="management"
                />
                <FormikRadioButton
                  label={"Shareholder"}
                  id="proponent-shrholdr"
                  name={"proponent"}
                  value="shareholder"
                />
              </Box>

              <Typography for="isPrev" component={"label"}  className="mt-1 menuForm-label" sx={{fontWeight: "500"}}>
                Search information:{" "}
              </Typography>
              <Box className="d-flex justify-content-between" sx={{margin:"5px 0px 5px 0px"}}>
                <FormikRadioButton
                  label={"Before Changes"}
                  name={"isPrev"}
                  value="true"
                />
                <FormikRadioButton
                  label={"After Changes"}
                  name={"isPrev"}
                  value="false"
                />
              </Box>
            </div>
            <div className="mb-4">
              <div className="d-flex form-buttons-box">
                <Button
                  className="mt-1 flex-fill"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                  disabled={!dirty || isSubmitting}
                >
                  Apply filters
                </Button>
                <Button
                  className="mt-1 ml-1 flex-fill"
                  onClick={() => {
                    resetForm();
                  }}
                  type="reset"
                  variant="secondary"
                  disabled={!dirty}
                >
                  Clear
                </Button>
              </div>
            </div>
          </FormFormik>
        )}
      </Formik>
    </div>
  );
};

export default SideFormLogs;
