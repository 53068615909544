import React from "react";
import { Button } from "react-bootstrap";
import { Form as FormFormik } from "formik";
import {
  FormikTextInput as TextField,
  FormikRadioButton,
} from "../utils/forms";

/**
 * Component of form to search logs
 * @param {Object} props
 * @param {function} props.resetForm - Formik's funtion to reset the form values
 * @param {boolean} props.dirty - Formik's attribute to check if the form is dirty
 * @returns the rendered component
 * @author milenexeleva
 */
const LogsForm = ({ resetForm, dirty }) => {
  const resetDatesFundLogsForm = () => {
    document.getElementById("input-modified-from-fundLogs").value = "";
    document.getElementById("input-to-fundLogs").value = "";
  };

  let className = `sideForm`;

  return (
    <FormFormik id="fundlogsCompanyForm" className={className}>
      <div className="form_xl">
        <div className="d-flex justify-content-between mt-4">
          <label>Operation: </label>
          <FormikRadioButton label={"Edit"} name={"operation"} value="edit" />
          <FormikRadioButton label={"Merge"} name={"operation"} value="merge" />
        </div>

        <TextField
          className="w-100"
          name="modifiedBy"
          type="text"
          label="Modified By"
        />

        <TextField
          id="input-modified-from-fundLogs"
          className="w-100"
          name="modifiedDateStart"
          type="date"
          label="Modified from"
        />

        <TextField
          id="input-to-fundLogs"
          className="w-100"
          name="modifiedDateEnd"
          type="date"
          label="to"
        />

        <TextField
          className="w-100"
          name="name"
          type="text"
          label="Company's name"
        />
        <label for="isPrev">Search information: </label>
        <div className="d-flex justify-content-between">
          <FormikRadioButton
            label={"Before Changes"}
            name={"isPrev"}
            value="true"
          />
          <FormikRadioButton
            label={"After Changes"}
            name={"isPrev"}
            value="false"
          />
        </div>
      </div>
      <div className="d-flex form-buttons-box">
        <Button type="submit" className="mt-1 ml-1 flex-fill">
          Apply filters
        </Button>
        <Button
          disabled={!dirty}
          variant="secondary"
          type="button"
          className="mt-1 ml-1 flex-fill"
          onClick={(_) => {
            resetDatesFundLogsForm();
            resetForm();
          }}
        >
          Clear
        </Button>
      </div>
    </FormFormik>
  );
};

export default LogsForm;
