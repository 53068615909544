import countryList from "react-select-country-list";
import {
  FormikTextInput as TextField,
  FormikSelect as Select,
} from "../utils/forms";
import { Button, Typography } from "@mui/material";

/**
 *  <p>Component containing the inputs for fund search in modals</p>
 * @param {Object} props
 * @param {Boolean} props.isSubmitting - indicates if onSubmit function is executing
 * @param {string} props.keyWord - component key name given by the set of Tabs
 * @param {Array} props.hierarchies - list of names of hierarchies to which a fund can belong
 * @param {Array} props.types - list of types to which a fund can belong
 * @param {Boolean} props.dirty - indicates if form was filled or not
 * @param {Function} props.resetForm - function to reset variables from the forms
 * @param {Function} props.setNotFoundFundsMessage - function to determine whether or not to display a not found message
 * @param {Object} props.currentTypeSearched - useRef variable to store the fund type currently selected
 * @param {Boolean} props.statsView - indicates if modal was opened in StatsView page
 * @returns rendered view of the component
 * @author milenexeleva
 */
const FundSearchFields = ({
  isSubmitting,
  keyWord,
  hierarchies,
  types,
  dirty,
  resetForm,
  setNotFoundFundsMessage,
  currentTypeSearched,
  statsView,
}) => {
  const countries = countryList().getData();
  const modalHierarchicalMediumundFormStyles = {
    labelStyle: "d-none",
    inputSelectStyle: "modal-company-width-medium",
  };
  const fundFormWithNameStyles = {
    labelStyle: "d-none",
  };

  return (
    <div className="companyFilters_form">
      <Typography component="p" variant="body1">Filter by:</Typography>

      {keyWord === "searchName" ? (
        <TextField
          id="name-input"
          name="name"
          type="text"
          placeholder="Name (required)"
          customStyle={fundFormWithNameStyles}
        />
      ) : null}

      {!statsView && (
        <Select
          disabled={isSubmitting || keyWord === "none"}
          customStyle={modalHierarchicalMediumundFormStyles}
          name="hierarchy"
        >
          <option value="default" key="default">
            All hierarchy levels
          </option>
          {hierarchies.map((category, index) => {
            return (
              <option value={category} key={category}>
                {category}
              </option>
            );
          })}
        </Select>
      )}

      <Select
        disabled={isSubmitting || keyWord === "none"}
        customStyle={modalHierarchicalMediumundFormStyles}
        name="type"
      >
        <option value="default" key="default">
          --Type
        </option>
        {types.map((category, index) => {
          return (
            <option value={category} key={category}>
              {category}
            </option>
          );
        })}
      </Select>

      <Select
        disabled={keyWord === "none"}
        customStyle={modalHierarchicalMediumundFormStyles}
        name="country"
      >
        <option value="default" key="default">
          --Country
        </option>
        {countries.map((category, index) => (
          <option value={category.label} key={category.label}>
            {category.label}
          </option>
        ))}
      </Select>
      <div className="d-flex ml-4 form-buttons-box">
        <Button
          className="flex-fill "
          type="submit"
          onClick={() => {
            setTimeout(() => {}, 300);
          }}
          disabled={!dirty || isSubmitting || keyWord === "none"}
        >
          Search
        </Button>
        <Button
          variant="light"
          className="flex-fill ml-2 "
          onClick={() => {
            resetForm();
            setNotFoundFundsMessage(false);
            currentTypeSearched.current = "manager";
          }}
          disabled={!dirty || isSubmitting || keyWord === "none"}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

export default FundSearchFields;
