import axios from "axios";
import { auth } from "../firebase";

const url = process.env.REACT_APP_API_URL;
const url_uploads = process.env.REACT_APP_UPLOADS_URL;
const url_merges = process.env.REACT_APP_MERGE_URL;
const api = axios.create({ baseURL: url });
const api_uploads = axios.create({ baseURL: url_uploads });
const api_merges = axios.create({ baseURL: url_merges });

api.interceptors.request.use(
  async (config) => {
    try {
      const token = await auth.currentUser?.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
      return Promise.reject(error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api_uploads.interceptors.request.use(
  async (config) => {
    try {
      const token = await auth.currentUser?.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.accept = "multipart/form-data";
    } catch (error) {
      return Promise.reject(error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api_merges.interceptors.request.use(
  async (config) => {
    try {
      const token = await auth.currentUser?.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.accept = "multipart/form-data";
    } catch (error) {
      return Promise.reject(error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/*PROPOSALS*/
const findProposals = (data) => api.post("/proposal/", { ...data });
const updateProposal = (data) =>
  api.patch(`/proposal/update/${data.meeting_id}/proposal/${data.index}`, {
    ...data,
  });
const mergeProposal = (data) =>
api_merges.post(`/proposal/merge/${data.meeting_id}`, { ...data });
const generateCsv = (data) =>
  api.post("/proposal/csv", data, { responseType: "blob" });
const updateProposalSubjects = (data) =>
  api.patch("/proposal/update-subjects", data);
const searchProposalLogs = (filters = {}, limit = 100) =>
  api.get("/log/proposal", { params: { ...filters, limit } });

/*COMPANIES*/
const getCompanies = (limit = 0, page = 1, filters = {}) =>
  api.get("/company", { params: { limit, page, ...filters } });
const mergeCompanies = (data) => api_merges.post("/company/merge", data);
const countByFund = (fundName) =>
  api.get(`/company/count-by-fund?fundName=${fundName}`);
const updateCompany = (data) => api.post("/company/update-saveLogs", data);
const searchCompanyLogs = (filters = {}, limit = 0, page = 1) =>
  api.get("/log/company", { params: { ...filters, limit, page } });

/*FUNDS/INVESTORS*/
const getGroupedFunds = (filters) =>
  api.get("/fund/grouped-funds", { params: filters });
const getFundbyId = (id) => api.get(`/fund/${id}`);
const getFundsbyId = (data) =>
  api.get(`/fund/find-by-id-list`, { params: data });
const mergeFunds = (data) => api_merges.post("/fund/merge", data);
const getFundsBy = (filters = {}, limit = 0, page = 1, sort = "") =>
  api.get("/fund", { params: { ...filters, limit, page, sort } });
const updateFund = (data) => api.post("/fund/update", data);
const getFoundLogs = (data) => api.get("/log/funds", { params: data });

/*STATISTICS*/
const getVoteProportion = (data) =>
  api.get("/statistics/vote-proportion", { params: data });
const getVoteProportionCsv = (data) =>
  api.get(
    "/statistics/vote-proportion-csv",
    { params: data },
    { responseType: "blob" }
  );
const getVoteProportionAll = (data) =>
  api.get("/statistics/vote-proportion-all", { params: data });
const getESGFundStats = (data) =>
  api.get("/statistics/getFundStats", { params: data });

/*UPLOAD FILES*/
const getUploadLogs = (filters = {}, limit = 0, page = 1) =>
  api.get("/upload", { params: { ...filters, limit, page } });
const uploadFiles = (data) => api_uploads.post("/upload/upload-file", data);

/*INSIGHTS*/
const getESGInfo = (subject) => api.get(`/insights/${subject}`);
const sendEmail = (body) => api.post("/insights/send-email", body)

/*USER ADMINISTRATION & ADMIN PANEL*/
const getUsers = () => api.get("/user");
const createUser = (body) => api.post("/user/create", body);
const updateUser = (id, body) => api.patch(`/user/${id}`, body);
const getUserByEmail = (data) => api.get(`/user/reference`, { params: data });

/*OTHER UTILITIES*/
const getSASBlists = (type) => api.get(`/subject/sasb-subjects?type=${type}`);

const apis = {
  getCompanies,
  findProposals,
  updateProposal,
  mergeProposal,
  generateCsv,
  getGroupedFunds,
  getFundsBy,
  updateFund,
  mergeCompanies,
  countByFund,
  updateProposalSubjects,
  uploadFiles,
  getUploadLogs,
  getSASBlists,
  updateCompany,
  searchCompanyLogs,
  getUsers,
  createUser,
  updateUser,
  getUserByEmail,
  getFoundLogs,
  getFundbyId,
  mergeFunds,
  getFundsbyId,
  getVoteProportion,
  getESGFundStats,
  getVoteProportionCsv,
  getVoteProportionAll,
  searchProposalLogs,
  getESGInfo,
  sendEmail,
};

export default apis;
