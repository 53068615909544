import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Typography } from "@mui/material";

const InfoTooltip = ({ tooltipText, color }) => {
  return (
    <Tooltip title={tooltipText}>
      <Typography  component="span" color={color}>
        <InfoOutlinedIcon
          sx={{
            fontSize: 15,
          }}
          style={{ verticalAlign: "top" }}
        />
        </Typography>
    </Tooltip>
  );
};

export default InfoTooltip;
