import React, { useState, useEffect } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import CustomTooltipESG from "./CustomTooltipESG";
import {filterStatsByFormDates} from "../../utils/utils"
import { Typography, useMediaQuery } from "@mui/material";
import InfoTooltip from "../InfoTooltip";

/**
 * Component containing the graph of the ESG statistics
 * @param {Object} props
 * @param {Object[]} props.ESGstats - Array containing the ESG statistics of a group of funds
 * @param {Object[]} props.graphLines - Array containing the information of the funds
 * @returns the rendered component
 * @author valeriaxeleva
 */
const ESGgraph = ({
  ESGstats,
  graphLines,
  currentSubject,
  setCurrentSubject,
  formOptions
}) => {
  const [dataChart, setDataChart] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("");
  const [websites, setWebsites] = useState("");
  const [showGraph, setShowGraph] = useState(false);
  const isMobile = useMediaQuery("(max-width:912px)");

  const ESGcolors = ["#092040", "#3c75b7", "#a1cbf2", "#400815", "#a52836", "#a59d97"];

  const ESGtitles = {
    E: "Environmental (E)",
    S: "Social (S)",
    G: "Governance (G)",
    ESG: "All (ESG)",
  };

  const ESGinfo = {
    ESG: <InfoTooltip color={"primary.main"} tooltipText={"Use these buttons to filter your search by Environmental (E), Social (S), or Governance (G) topics, or All (ESG).  \nNote: All proposals that are not categorized as E or S are categorized as G."}/>
  }

  const ESGLineType = ["1 0", "15 8", "10 4", "3 3", "1 0"];

  useEffect(() => {
    ESGstats = filterStatsByFormDates(formOptions,ESGstats)
    const options = { year: "numeric", month: "long", day: "numeric" };
    if (ESGstats.OG?.length === 0) {
      setShowGraph(false);
    } else setShowGraph(true);

    setDataChart(ESGstats.OG);
    let dates = graphLines.map(
        (line, i) =>
          `${
            line.name
          } was last updated on ${line.lastUpdated.toLocaleDateString(
            "en-US",
            options
          )}`
      )
      .join(" , ");
    setLastUpdated(dates);
    let websites = graphLines.map(
      (line, i) => 
      `${line.name} website: ${line.website}`)
      .join(" , ");
    setWebsites(websites);
  }, [ESGstats, graphLines]);

  useEffect(() => {
    if (formOptions.checkedSH && formOptions.checkedFM) {
      setDataChart(ESGstats.FMSH);
    } else if (formOptions.checkedSH && !formOptions.checkedFM) {
      setDataChart(ESGstats.SH);
    } else if (formOptions.checkedFM && !formOptions.checkedSH) {
      setDataChart(ESGstats.FM);
    } else if (!formOptions.checkedFM && !formOptions.checkedSH) {
      setDataChart(ESGstats.OG);
    }
  }, [formOptions, ESGstats]);

  function changeESG(event) {
    setCurrentSubject(event.target.value);
  }

  return (
    <>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={currentSubject}
          onChange={changeESG}
        >
          {Object.keys(ESGtitles).map((item) => {
            return (
              <FormControlLabel
                value={item}
                key={ESGtitles[item]}
                disabled={ESGstats.OG === undefined}
                control={<Radio size="small" />}
                label={
                  <Typography component="p" variant="p" sx={{fontWeight: "600"}} color="primary.main">
                    {ESGtitles[item]} 
                    {ESGinfo[item]}
                  </Typography>
                }
                sx={{ margin: { xs: "0 16px", md: "0 16px 8px -11px" } }}
              />
            );
          })}
        </RadioGroup>
        <FormGroup
          sx={{
            position: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
        </FormGroup>
      </FormControl>
      <div></div>

      {showGraph ? (
        <>
          <ResponsiveContainer
            width={isMobile ? "96%" : "66%"}
            height={isMobile ? 300 : 400}
          >
            <LineChart
              margin={{
                top: 20,
                right: 10,
                left: -50,
                bottom: 0,
              }}
              data={dataChart}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis
                width={130}
                label={{
                  value: `Votes in favor for ${currentSubject} (%)`,
                  angle: -90,
                  position: "center",
                  fontSize: 18,
                }}
                tickFormatter={(tick) => {
                  return `${tick}%`;
                }}
                ticks={[0, 20, 40, 60, 80, 100]}
                tickSize={8}
              />
              {ESGstats.OG !== undefined && (
                <Tooltip
                  content={<CustomTooltipESG subjectActive={currentSubject} />}
                />
              )}
              <Legend
                wrapperStyle={{
                  width: "100%",
                  left: 5,
                }}
              />
              {graphLines.map((line, i) => (
                <Line
                  key={i}
                  strokeWidth={3}
                  dataKey={currentSubject + "_percentage_" + line.key}
                  stroke={ESGcolors[i]}
                  strokeDasharray={ESGLineType[i]}
                  legendType="plainline"
                  name={line.name}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
          <p className="mt-5">{lastUpdated}</p>
          <p className="mb-5">{websites}</p>
        </>
      ) : (
        <p>No data available.</p>
      )}
    </>
  );
};

export default ESGgraph;
