import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {<Box>{children}</Box>}
    </Box>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

export default TabPanel;