import * as Yup from "yup";
import { FormikTextInput as TextField } from "../utils/forms";
import { Formik, Form as FormFormik } from "formik";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Button, Alert } from "react-bootstrap";
import { useState } from "react";
import {BaseModal} from "../Layout/BaseModal";

/**
 * Modal to user password before entering a new file to the database.
 * @param {Object} props
 * @param {Object} props.user - Data of the current authenticated user
 * @param {boolean} props.showPasswordModal - Allows to show or hide the modal
 * @param {function} props.setShowPasswordModal - Function to change the state of the show modal hook.
 * @param {function} props.submitUploadFilesForm - Ref to the function that submits the form to upload a new file
 * @returns the rendered component
 * @author milenexeleva
 */
const PswdConfirmation = ({
    user,
    showPasswordModal,
    setShowPasswordModal,
    submitUploadFilesForm,
}) => {
    const [showErrorSignInFirebase, setShowErrorSignInFirebase] = useState(false);

    const signInFirebase = (values) => {
        //https://firebase.google.com/docs/auth/web/auth-state-persistence
        setShowErrorSignInFirebase(false);
        signInWithEmailAndPassword(auth, user.email, values.password)
            .then((res) => {
                submitUploadFilesForm.current();
                setShowErrorSignInFirebase(false);
            })
            .catch((error) => {
                console.log("error", error);
                setShowErrorSignInFirebase(true);
            });
    };

    return (
        <BaseModal
            showModal={showPasswordModal}
            setShowModal={setShowPasswordModal}
            title="Upload files"
        >
            <BaseModal.Body>
                {showErrorSignInFirebase && (
                    <Alert variant="danger">Error signing in</Alert>
                )}
                <p>Enter your password to continue</p>
                <Formik
                    initialValues={{
                        password: "",
                    }}
                    enableReinitialize
                    validationSchema={Yup.object({
                        password: Yup.string().required("Required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 400);
                        signInFirebase(values);
                    }}
                >
                    {({ dirty }) => (
                        <FormFormik id="passwordForm">
                            <TextField className="w-100" name="password" type="password" />
                            <div className="d-flex mt-4 justify-content-between">
                                <Button type="submit" disabled={!dirty} style={{ width: "48%" }}>
                                    Continue
                                </Button>
                                <Button
                                    variant="danger"
                                    type="button"
                                    style={{ width: "48%" }}
                                    onClick={(_) => {
                                        setShowErrorSignInFirebase(false);
                                        setShowPasswordModal(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </FormFormik>
                    )}
                </Formik>
            </BaseModal.Body>

        </BaseModal>
    );
};

export default PswdConfirmation;
