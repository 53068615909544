import { Button } from 'react-bootstrap'
import { Form as FormFormik } from 'formik';
import {
    FormikTextInput as TextField,
    FormikRadioButton
} from "../utils/forms";
import { Box, Typography } from '@mui/material';

/**
 * Component of form to search logs
 * @param {Object} props
 * @param {function} props.resetForm - Formik's funtion to reset the form values
 * @param {boolean} props.dirty - Formik's attribute to check if the form is dirty
 * @returns the rendered component
 * @author valeriaxeleva
 */
const LogsForm = ({ resetForm, dirty }) => {

    const resetDatesLogsForm = () => {
        document.getElementById('input-to').value = ''
        document.getElementById('input-modified-from').value = ''
    }
    let className = `sideForm`;

    return (
        <FormFormik id="logsCompanyForm" className={className}>
            <div className="form_xl">
                <Box className="d-flex justify-content-between" sx={{marginBottom: "15px"}}>
                    <Typography component={"label"} className='menuForm-label' sx={{fontWeight: "500", marginLeft: "5px"}} >Operation:</Typography>
                    <FormikRadioButton label={"Edit"} name={"operation"} value="edit" />
                    <FormikRadioButton label={"Merge"} name={"operation"} value="merge" />
                </Box>

                <TextField 
                    name="modifiedBy" type="text"
                    label="Modified By"
                />
                
                <TextField id="input-modified-from" 
                    name="modifiedDateStart" type="date"
                    label="Modified from"
                />

                <TextField id="input-to" 
                    name="modifiedDateEnd" type="date"
                    label="to"
                />

                <TextField 
                    name="name" type="text"
                    label="Company's name"
                />

                <TextField 
                    name="industry" type="text"
                    label="Industry"
                />

                <TextField 
                    name="sector" type="text"
                    label="Sector"
                />

                <Typography component={"label"} for="isPrev" sx={{ fontWeight: "500"}}>Search information: </Typography>
                <Box className="d-flex justify-content-between" sx={{margin:"5px 0px 5px 0px"}}>
                    <FormikRadioButton label={"Before Changes"} name={"isPrev"} value="true" />
                    <FormikRadioButton label={"After Changes"} name={"isPrev"} value="false" />
                </Box>

            </div>
            <div className='form-buttons-box'>
                <Button  type="submit">
                    Search Logs
                </Button>
                <Button disabled={!dirty} variant="secondary" type="button" onClick={(_) => {
                    resetDatesLogsForm();
                    resetForm();
                }}>
                    Clear
                </Button>
            </div>
        </FormFormik>
    )
}

export default LogsForm;