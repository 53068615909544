import { FormikTextInput as TextField } from "../utils/forms";
import { Form as FormFormik } from "formik";
import { Button } from "react-bootstrap";

/**
 * Component of form to search upload logs
 * @param {Object} props
 * @param {function} props.resetForm - Formik's funtion to reset the form values
 * @param {boolean} props.isFund - attribute to change text according to the type of user
 * @param {Array} props.userRoles - attribute to change fields according to the user roles
 * @returns the rendered component
 * @author milenexeleva
 */
const LogsForm = ({ resetForm, isFund, userRoles }) => {
  return (
    <FormFormik id="logsForm" className="mt-4">
      {!isFund && (
        <TextField
          className="w-100"
          name="manager"
          type="text"
          label="Manager"
          color="white.main"
        />
      )}
      <TextField
        className="w-100"
        name="cleanedBy"
        type="text"
        label={isFund ? "Person responsible of the upload" : "Cleaner"}
        color="white.main"
      />
      {userRoles.includes("Admin") && (
        <TextField
          className="w-100"
          name="uploadedBy"
          type="text"
          label="User"
          color="white.main"
        />
      )}
      <TextField
        className="w-100"
        name="filename"
        type="text"
        label="Filename"
        color="white.main"
      />

      <TextField
        className="w-100"
        name="created_at"
        type="date"
        label="Uploaded from"
        color="white.main"
      />

      <TextField
        className="w-100"
        name="created_at_between"
        type="date"
        label="To"
        color="white.main"
      />

      <div className="form-buttons-box" style={{ paddingTop: "20px" }}>
        <Button type="submit" className="flex-fill mr-1">
          Search Logs
        </Button>
        <Button
          variant="secondary"
          className="flex-fill"
          type="button"
          onClick={(_) => {
            resetForm();
          }}
        >
          Clear
        </Button>
      </div>
    </FormFormik>
  );
};

export default LogsForm;
