/**
 * <p>Custom tooltip box with numeric information of the search results that builds the statistics graphs.</p>
 * @param {Object} payload - information of the current row/bar/section
 * @param {string} label - label to identify the current row/bar/section
 * @param {boolean} active - true if the mouse is hover the graph
 * @returns rendered tooltip with information of the graph
 * @author valeriaxeleva
 */
export const CustomTooltip = ({ payload, label, active }) => {
  function getIntroOfPage(label) {
    if (label === "forPercentage") {
      return "For";
    }
    if (label === "againstPercentage") {
      return "Against";
    }
    if (label === "otherPercentage") {
      return "Other";
    }
  }
  function getTotalVotes(payload) {
    return payload.forTotal + payload.againstTotal + payload.otherTotal;
  }

  if (active) {
    return (
      <div className="custom-tooltip">
        <strong>{label}</strong>
        {payload.length>0?
        <>
        {payload.map((vote, index) => (
          <p key={index} className="label" style={{ color: vote.color }}>
            {`${getIntroOfPage(vote.dataKey)} : ${vote.value}%`}
          </p>
        ))}
        <p className="label">
          {payload[0].payload.forTotal} in favor of{" "}
          {getTotalVotes(payload[0].payload)} total
        </p>
        </>:null
        }
      </div>
    );
  }

  return null;
};
