import "../css/modal.scss";
import { Tab, Tabs, Button } from "react-bootstrap";
import { useState, useEffect, useRef, useCallback } from "react";
import { searchCompanies } from "../../utils/reqs";
import { BaseModal } from "../Layout/BaseModal";
import Spinner from "../utils/Spinner";
import PaginationComponent from "./PaginationComponent";
import TabResultList from "./TabResultList";
import { useSASBLists } from "../../contexts/subjectsProvider";

/**
 *  <p>Component to perform the advanced search of companies, presented in a modal.</p>
 * @param {Object} props
 * @param {Function} props.handleClose - function to close the modal
 * @param {Boolean} props.show - boolean to show or hide the modal
 * @param {Function} props.catchCompanies - function to catch the companies selected
 * @param {Boolean} props.closeWhenAddCompany - boolean to close the modal when the user add companies to the query
 * @param {Boolean} props.radioButtons - boolean to show the radio buttons to select the companies
 * @param {Function} props.resetSelectedCompaniesinModal - function to reset the selected companies in the modal
 * @returns rendered view of the component
 * @author valeriaxeleva
 */
const ModalCompanies = ({
  handleClose,
  show,
  catchCompanies,
  closeWhenAddCompany = false,
  radioButtons = false,
  resetSelectedCompaniesinModal,
  isPublicSearch = false,
}) => {
  const textButtonAddCompanies = closeWhenAddCompany
    ? "Search Companies"
    : "Add Companies to query";

  const [keyWord, setKey] = useState("none");
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [selecteds, setSelecteds] = useState([]);
  const [isFiltersClean, setIsFiltersClean] = useState(true);
  const formikRef = useRef();
  const formikFilterRef = useRef();
  const letters = [
    "#",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const currentValues = useRef({
    industry: "",
    country: "",
    index: "",
    name: "",
    ISINs: "",
  });
  const persistentValues = useRef({
    industry: "",
    country: "",
    index: "",
    name: "",
    ISINs: "",
  });

  const auxResetForm = useRef({
    isThereAFormikSelected: false,
    resetFunction: true,
  });
  const [companiesSearchedByName, setCompaniesSearchedByName] = useState([]);
  const [companiesSearchedByIsin, setCompaniesSearchedByIsin] = useState([]);
  const [notFoundCompaniesMessage, setNotFoundCompaniesMessage] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [industries, setIndustries] = useState([]);
  const [industriesLoading, setIndustriesLoading] = useState(true);
  const { SASBCompaniesList } = useSASBLists();
  
  let companiesLoading = loading ? <Spinner /> : <div></div>;

  useEffect(() => {
    let auxArrayIndustries = [];
    for (const sector of SASBCompaniesList) {
      auxArrayIndustries.push("--Sector: " + sector.name);
      auxArrayIndustries = auxArrayIndustries.concat(sector.subs.sort());
    }
    setIndustries(auxArrayIndustries);
    setIndustriesLoading(false);
  }, [SASBCompaniesList]);

  const activeClearFilters = (v) => {
    const properties = ["name", "country", "industry", "index"];

    const shouldSetFiltersClean = properties.some((prop) => {
      const value = v.current[prop];
      return value !== "default" && value.length > 0;
    });
  
    setIsFiltersClean(!shouldSetFiltersClean);
  };

  const clearValues = () => {
    const initValues = {
      name: "",
      country: "",
      industry: "",
      index: "",
      ISINs: "",
    };
    if (!isFiltersClean) {
      currentValues.current = initValues;
      persistentValues.current = initValues;
      formikFilterRef.current.initialValues = initValues;
      formikRef.current = initValues;
      setIsFiltersClean(true);
    }
    if (keyWord === "searchName") {
      setCompanies([]);
      setPaginationData({});
      setCompaniesSearchedByName([]);
    } else {
      handleSubmit(initValues)
    }
  };

  function handleSelect(keyWord) {
    if (notFoundCompaniesMessage) setNotFoundCompaniesMessage(false);

    resetFormWithAuxRef(); // this line reset the name search formik when i press any other letter
    currentValues.current = persistentValues.current;
    let filters = {};
    activeClearFilters(currentValues)

    setCompaniesSearchedByName([]);
    setCompaniesSearchedByIsin([]);

    if (keyWord === "searchName" || keyWord === "searchIsin") {
      setCompanies([]);
      setPaginationData({});
      setKey(keyWord);
    } else {
      setLoading(true);
      filters.companyStarting = keyWord;
      if (isPublicSearch) {
        filters.index = "S&P 500"
      }
      for (let field in currentValues.current) {
        if (
          currentValues.current[field] !== "default" &&
          currentValues.current[field].length > 0
        ) {
          filters[field] = currentValues.current[field];
        }
      }
      searchCompanies(1, filters, 100)
        .then((res) => {
          setCompanies(res.data.companies);
          setKey(keyWord);
          setPaginationData(res.data);
          if (res.data.companies.length === 0) setNotFoundCompaniesMessage(true)
          
        })
        .catch((error) => {
          const { response } = error;
          alert(!response?.data ? error.message : response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  async function getPaginatedCompanies(page) {
    let filters = {};
    if (isPublicSearch) {
      filters.index = "S&P 500"
    }
    currentValues.current = persistentValues.current; // the reference to the formik for some strange reason always loses all the values, by this reason we need to save in other reference that is not a reference to the formik
    for (let field in currentValues.current) {
      if (
        currentValues.current[field] !== "default" &&
        currentValues.current[field].length > 0
      ) {
        filters[field] = currentValues.current[field];
      }
    }

    filters.companyStarting = keyWord;
    if (keyWord === "searchName") {
      filters.name = currentValues.current.name;
      delete filters.companyStarting;
    }

    setLoading(true);
    searchCompanies(page, filters, 100)
      .then((res) => {
        setPaginationData(res.data);
        if (keyWord === "searchName")
          setCompaniesSearchedByName(res.data.companies);
        else setCompanies(res.data.companies);
      })
      .catch((error) => {
        const { response } = error;
        alert(!response?.data ? error.message : response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const checkSelecteds = useCallback(() => {
    let boxes = document.getElementsByClassName("names_companies_modal");
    for (let i = 0; i < boxes.length; i++) {
      selecteds.forEach((company) => {
        if (
          company.page === paginationData?.page &&
          company.keyWord === keyWord &&
          company.index === i
        ) {
          boxes[i].checked = true;
        }
      });
    }
  }, [paginationData, keyWord, selecteds]);

  useEffect(() => {
    checkSelecteds();
  }, [checkSelecteds]);

  function resetCompanyForm() {
    if (document.getElementById("formCompany"))
      document.getElementById("formCompany").reset();
    if (document.getElementById("namesForm"))
      document.getElementById("namesForm").reset();
    if (document.getElementById("namesIsin"))
      document.getElementById("namesIsin").reset();
    let boxes = document.getElementsByClassName("names_companies_modal");
    for (let box of boxes) {
      box.checked = false;
    }
  }

  function sumaSelected(target, value, checked, _id) {
    if (radioButtons)
      setSelectedCompany({
        _id,
        page: paginationData?.page,
        name: value,
        keyWord: keyWord,
      });
    else if (checked) {
      setSelecteds([
        ...selecteds,
        { _id, page: paginationData?.page, name: value, keyWord: keyWord },
      ]);
    } else {
      setSelecteds([...selecteds.filter((item) => item._id !== _id)]);
    }
  }

  useEffect(() => {
    const { country, industry, index } = persistentValues.current;
    if (country !== "" || industry !== "" || index !== "") {
      formikFilterRef.current = {
        initialValues: { name: "", country, industry, index, ISINs: "" },
      };
    }
  }, [show]);

  const isinWithoutDashes = valuesForm => {
    const modifiedObject = { ...valuesForm };
    if (modifiedObject.ISINs) modifiedObject.ISINs = modifiedObject.ISINs.replace(/-/g, '');
    return modifiedObject;
    };

  const handleSubmit = (values) => {
    if (notFoundCompaniesMessage) setNotFoundCompaniesMessage(false);
    const { country, industry, index } = values;
    currentValues.current = { name: "", country, industry, index, ISINs: "" };
    persistentValues.current = { name: "", country, industry, index, ISINs: ""};
    formikFilterRef.current.initialValues = {
      name: "",
      country,
      industry,
      index,
      ISINs: "",
    };

    if (values.country === "default" || values.country === "")
      values.country = "";
    if (values.industry === "default" || values.industry === "")
      values.industry = "";

    values.companyStarting = keyWord;
    setLoading(true);
    if (keyWord === "searchName") {
      delete values.companyStarting;

      searchCompanies(1, values, 100)
        .then((res) => {
          setPaginationData(res.data);
          setCompaniesSearchedByName(res.data.companies);

          if (res.data.companies.length === 0)
            setNotFoundCompaniesMessage(true);
          setKey(() => keyWord);
        })
        .catch((error) => {
          const { response } = error;
          alert(!response?.data ? error.message : response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (keyWord === "searchIsin") {
      const { ISINs } = values;      
      searchCompanies(1, isinWithoutDashes({ISINs}), 100)
        .then((res) => {
          setPaginationData(res.data);
          setCompaniesSearchedByIsin(res.data.companies);
          if (res.data.companies.length === 0) {
            setNotFoundCompaniesMessage(true);
          }
          setKey(() => keyWord);
        })
        .catch((error) => {
          const { response } = error;
          alert(!response?.data ? error.message : response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      searchCompanies(1, values, 100)
        .then((res) => {
          setPaginationData(res.data);
          setCompanies(res.data.companies);

          if (res.data.companies.length === 0)
            setNotFoundCompaniesMessage(true);
          setKey(() => keyWord);
        })
        .catch((error) => {
          const { response } = error;
          alert(!response?.data ? error.message : response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const resetFormWithAuxRef = () => {
    if (auxResetForm.current.isThereAFormikSelected)
      auxResetForm.current.resetFunction();
  };

  resetSelectedCompaniesinModal.current = () => {
    setSelectedCompany("");
    setSelectedCompany({});
    setSelecteds([]);
    catchCompanies([]);
    formikRef?.current?.resetForm();
    resetCompanyForm();
  };

  return (
    <BaseModal
      showModal={show}
      setShowModal={handleClose}
      title={"List of Companies"}
    >
      <BaseModal.Body>
        {companiesLoading}
        <section className="modal-main-companies">
          <Tabs
            transition={false}
            activeKey={keyWord}
            defaultActiveKey={keyWord}
            id="uncontrolled-tab-example"
            onSelect={handleSelect}
          >
            {letters.map((letter) => (
              <Tab key={letter} eventKey={letter} title={letter}>
                <TabResultList
                  {...{
                    idName: letter,
                    industries,
                    handleSubmit,
                    industriesLoading,
                    companies,
                    radioButtons,
                    notFoundCompaniesMessage,
                    selectedCompany,
                    sumaSelected,
                    auxResetForm,
                    setNotFoundCompaniesMessage,
                    keyWord,
                    selecteds,
                    innerRef: formikFilterRef,
                    isFiltersClean,
                    setIsFiltersClean,
                    clearValues,
                    isPublicSearch
                  }}
                />
              </Tab>
            ))}

            <Tab eventKey="searchName" title="Search by name">
              <TabResultList
                {...{
                  idName: "namesForm",
                  industries,
                  handleSubmit,
                  industriesLoading,
                  companies: companiesSearchedByName,
                  radioButtons,
                  notFoundCompaniesMessage,
                  selectedCompany,
                  sumaSelected,
                  auxResetForm,
                  setNotFoundCompaniesMessage,
                  keyWord,
                  selecteds,
                  innerRef: formikFilterRef,
                  isFiltersClean,
                  setIsFiltersClean,
                  clearValues,
                  isPublicSearch
                }}
              />
            </Tab>
            <Tab eventKey="searchIsin" title="Search by ISIN">
                <TabResultList
                {...{
                  idName: "namesIsin",
                  industries,
                  handleSubmit,
                  industriesLoading,
                  companies: companiesSearchedByIsin,
                  radioButtons,
                  notFoundCompaniesMessage,
                  selectedCompany,
                  sumaSelected,
                  auxResetForm,
                  setNotFoundCompaniesMessage,
                  keyWord,
                  selecteds,
                  innerRef: formikFilterRef,
                  isFiltersClean,
                  setIsFiltersClean,
                  clearValues,
                  isPublicSearch
                }}
              />
            </Tab>
          </Tabs>
          {(companies.length > 0 || companiesSearchedByName.length > 0) && (
            <PaginationComponent
              {...{ paginationData, getPaginatedCompanies }}
            />
          )}
        </section>
      </BaseModal.Body>
      <BaseModal.Footer>
        <div className="form-buttons-box">
          <Button
            variant="primary"
            disabled={
              keyWord === "none" ||
              (radioButtons ? !selectedCompany : selecteds.length === 0)
            }
            className="flex-fill"
            onClick={() => {
              if (radioButtons) catchCompanies([selectedCompany]);
              else catchCompanies(selecteds);
              if (closeWhenAddCompany) {
                setPaginationData({});
                setKey("none");
                resetCompanyForm();
                handleClose();
              }
            }}
          >
            {textButtonAddCompanies}
          </Button>
          <Button
            variant="light"
            className="flex-fill"
            disabled={
              keyWord === "none" ||
              (radioButtons ? !selectedCompany : selecteds.length === 0)
            }
            onClick={() => {
              setSelectedCompany("");
              setSelecteds([]);
              catchCompanies([]);
              formikRef?.current?.resetForm();
              resetCompanyForm();
            }}
          >
            Deselect all companies
          </Button>
        </div>
      </BaseModal.Footer>
    </BaseModal>
  );
};

export default ModalCompanies;
