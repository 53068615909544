import React from "react";
import { Link } from "react-router-dom";

import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditLogin = () => {
  return (
    <div className="mx-auto w-50 text-center" style={{ marginTop: "120px" }}>
      <h1 className="text-secondary robot">
        <FontAwesomeIcon icon={faRobot} />
      </h1>
      <h1 className="error404">403</h1>
      <h2>Oops! Access denied</h2>
      <h2 className="text-secondary">Please log in to use this feature </h2>
      <Link to="/logUsr">Go to Login</Link>
      {/* <Link to="/login">Go to Login</Link> */}
      <Link style={{ marginLeft: "30px" }} to="/">
        Go to Home
      </Link>
    </div>
  );
};

export default EditLogin;
