import { Button } from "@mui/material";
import { useState } from "react";
import { Pagination, Form } from "react-bootstrap";

/**
 *  <p>Pagination options to be rendered with modal search results</p>
 * @param {Object} props
 * @param {Object} props.paginationData - information regarding the search page returned from backend
 * @param {Function} props.getPaginatedCompanies - get companies names according to filters and current page
 * @returns rendered view of the component
 * @author milenexeleva
 */
const PaginationComponent = ({ paginationData, getPaginatedCompanies }) => {
  const [goPage, setGoPage] = useState(0);

  return (
    <Pagination>
      <Pagination.First
        disabled={paginationData?.page === 1}
        onClick={(_) => getPaginatedCompanies(1)}
      />
      <Pagination.Prev
        disabled={!paginationData?.hasPrevPage}
        onClick={(_) => getPaginatedCompanies(paginationData?.prevPage)}
      />
      <Pagination.Item active>{paginationData?.page}</Pagination.Item>
      {paginationData?.totalPages !== paginationData?.nextPage &&
        paginationData?.hasNextPage && (
          <Pagination.Item
            onClick={(_) => getPaginatedCompanies(paginationData?.nextPage)}
          >
            {paginationData?.nextPage}
          </Pagination.Item>
        )}
      {paginationData?.nextPage !== null &&
        paginationData?.totalPages !== 2 && <Pagination.Ellipsis disabled />}
      {paginationData?.totalPages !== paginationData?.page && (
        <Pagination.Item
          onClick={(_) => getPaginatedCompanies(paginationData?.totalPages)}
        >
          {paginationData?.totalPages}
        </Pagination.Item>
      )}
      {paginationData?.hasNextPage && (
        <Pagination.Next
          onClick={(_) => {
            getPaginatedCompanies(paginationData?.nextPage);
          }}
        />
      )}
      {!paginationData?.hasNextPage && <Pagination.Next disabled />}
      {paginationData?.totalPages !== paginationData?.page && (
        <Pagination.Last
          onClick={(_) => getPaginatedCompanies(paginationData?.totalPages)}
        />
      )}
      {paginationData?.totalPages === paginationData?.page && (
        <Pagination.Last disabled />
      )}
      <Button
        size="sm"
        className="ml-3"
        type="button"
        sx={{ textTransform: "none" }}
        onClick={(_) => getPaginatedCompanies(goPage)}
      >
        Go to page
      </Button>
      <Form.Check
        type="number"
        id="gotopage"
        max={paginationData?.totalPages}
        min="1"
        onChange={(e) => setGoPage(e.target.value)}
      />
    </Pagination>
  );
};

export default PaginationComponent;
